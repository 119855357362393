/* eslint-disable no-underscore-dangle, react/no-danger, import/no-extraneous-dependencies */
import type { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { ApolloProvider } from "@apollo/client";
import { GTMProvider } from "@elgorditosalsero/react-gtm-hook";
import type { ISnippetsParams } from "@elgorditosalsero/react-gtm-hook/dist/models/GoogleTagManager";
import loadLocaleData, { decodeIntlMessagesCaching, encodeIntlMessages } from "components/i18n/loadLocaleData";
import IntlWrapper from "components/layouts/IntlWrapper";
import AmplitudeTrackPageViews from "components/metrics/AmplitudeTrackPageViews";
import FundamenteiAppHead from "components/seo/FundamenteiAppHead";
import FundamenteiMetadata from "components/seo/FundamenteiMetadata";
import IntercomProvider from "components/support/IntercomProvider";
import aeonik from "components/utils/aeonik";
import { Provider as JotaiProvider } from "jotai";
import trackEvent from "lib/analytics/trackEvent";
import DatadogInit from "lib/datadog/DatadogInit";
import { FeatureFlagsProvider } from "lib/flagging/FeatureFlags";
import withEnigmaDecoder from "lib/internal/enigma/next";
import isBrowser from "lib/utils/isBrowser";
import type { AppContext } from "next/app";
import App from "next/app";
import { Router } from "next/router";
import { parseCookies } from "nookies";
import type { ParsedUrlQuery } from "querystring";
import { Toaster } from "react-hot-toast";
import { AuthProvider } from "../components/auth/Auth";
import { getInitialSearchStateFromQuery } from "../components/screener/searchState";
import { SearchStateProvider } from "../lib/algolia/SearchStateContext";
import { AmplitudeProvider } from "../lib/amplitude/AmplitudeProvider";
import withServerResponse from "../lib/http/withServerResponse";
import withApollo from "../lib/withApollo";
import "../styles/globals.css";

// NOTE: amplitude-js doesn't have SSR support. GH issue here: https://github.com/amplitude/Amplitude-JavaScript/issues/110
const amplitude = isBrowser() ? require("amplitude-js") : null;

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    fbq?: (...args: any) => void;
    gtag?: (...args: any) => void;
  }
}

export interface AppInitialProps {
  apollo: ApolloClient<NormalizedCacheObject>;
  userAgent: string;
  cookies: {
    [key: string]: string;
  };
  query: ParsedUrlQuery;
  pathname: string;
  locale: {
    id: string;
    messages: string;
  };
}

class FundamenteiApp extends App<AppInitialProps> {
  public componentDidMount() {
    // https://developer.mozilla.org/en-US/docs/Web/API/History/scrollRestoration
    // eslint-disable-next-line no-restricted-globals
    if (typeof history !== "undefined") {
      // NOTE: Ideally it should be `auto` if the Sticky Navbar is fixed to support it
      // eslint-disable-next-line no-restricted-globals
      history.scrollRestoration = "manual";
    }

    trackEvent("Viewed page");
    Router.events.on("routeChangeComplete", () => {
      trackEvent("Viewed page");
      if (process.env.FUNDAMENTEI_ENV !== "production") {
        return;
      }

      // Facebook Pixel
      if (window.fbq && typeof window.fbq === "function") {
        window.fbq("track", "PageView");
      }
    });
  }

  public static async getInitialProps({ Component, ctx }: AppContext) {
    let pageProps = {};
    const isBrazil = ctx.req?.headers["cloudfront-viewer-country"] === "BR";
    const defaultLocale = isBrazil ? "pt-BR" : "en-US";

    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx);
    }

    const messages = ctx.locale === "pt-BR" ? {} : await loadLocaleData(ctx.locale || defaultLocale);
    const localeId = ctx.locale || defaultLocale;

    return {
      locale: {
        id: localeId,
        messages: encodeIntlMessages(messages),
      },
      pageProps,
      cookies: parseCookies(ctx),
      query: ctx.query,
      pathname: ctx.pathname,
      defaultLocale,
      isBrazil,
    };
  }

  public render() {
    const { Component, pageProps, apollo, cookies, query, locale } = this.props;

    return (
      <JotaiProvider>
        <ApolloProvider client={apollo}>
          <FeatureFlagsProvider>
            <AuthProvider cookies={cookies}>
              <IntlWrapper
                locale={{
                  ...locale,
                  messages: decodeIntlMessagesCaching(locale.id, locale.messages),
                }}
              >
                <IntercomProvider>
                  <AmplitudeProvider
                    amplitudeInstance={getAmplitudeInstance()}
                    apiKey={process.env.AMPLITUDE_API_KEY as string}
                  >
                    <>
                      <AmplitudeTrackPageViews />
                      <DatadogInit />

                      <>
                        <FundamenteiAppHead />
                        <FundamenteiMetadata />

                        <style jsx={true} global={true}>
                          {`
                            html {
                              font-family: ${aeonik.style.fontFamily};
                            }
                          `}
                        </style>

                        <SearchStateProvider initialSearchState={getInitialSearchStateFromQuery(query)}>
                          <GTMProvider state={getGTMState()}>
                            <Component {...pageProps} />

                            <Toaster
                              containerClassName="mt-4 !top-navbar-mobile-offset lg:!top-navbar-desktop-offset"
                              toastOptions={{
                                loading: {
                                  className: "shadow-xl",
                                },
                                success: {
                                  className: "shadow-xl dark:bg-green-900 dark:text-green-300",
                                },
                                error: {
                                  className: "shadow-xl dark:bg-red-900 dark:text-red-300",
                                },
                              }}
                            />
                          </GTMProvider>
                        </SearchStateProvider>
                      </>
                    </>
                  </AmplitudeProvider>
                </IntercomProvider>
              </IntlWrapper>
            </AuthProvider>
          </FeatureFlagsProvider>
        </ApolloProvider>
      </JotaiProvider>
    );
  }
}

function getGTMState() {
  let gtmState: ISnippetsParams = {
    // fundamentei.com Web container
    id: "GTM-TKT26QB",
  };

  const isDevelopment = process.env.NODE_ENV === "development";
  if (isDevelopment) {
    gtmState = {
      ...gtmState,
      environment: {
        gtm_auth: "siko-tvnEiRaAkAsYBhkJg",
        gtm_preview: "env-8",
      },
    };
  }

  return gtmState;
}

function getAmplitudeInstance() {
  if (!amplitude) {
    return null;
  }

  if (amplitude.default && typeof amplitude.default.getInstance === "function") {
    return amplitude.default.getInstance();
  }

  if (typeof amplitude.getInstance === "function") {
    return amplitude.getInstance();
  }

  return null;
}

export default withServerResponse(withEnigmaDecoder(withApollo(FundamenteiApp) as any) as any);
