import { getGlobalScope } from "lib/analytics/getGlobalScope";

export const getQueryParams = (): Record<string, string | undefined> => {
  const globalScope = getGlobalScope();
  /* istanbul ignore if */
  if (!globalScope?.location?.search) {
    return {};
  }
  const pairs = globalScope.location.search.substring(1).split("&").filter(Boolean);
  const params = pairs.reduce<Record<string, string | undefined>>((accumulator, current) => {
    const query = current.split("=", 2);
    const key = tryDecodeURIComponent(query[0]);
    const value = tryDecodeURIComponent(query[1]);
    if (!value) {
      return accumulator;
    }
    accumulator[key] = value;
    return accumulator;
  }, {});
  return params;
};

export const tryDecodeURIComponent = (value = "") => {
  try {
    return decodeURIComponent(value);
  } catch {
    return "";
  }
};
