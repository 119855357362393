import { useQuery } from "@apollo/client";
import { atom, useAtomValue, useSetAtom } from "jotai";
import { useHydrateAtoms } from "jotai/utils";
import type { DefinedFeatureFlags } from "lib/flagging/DefinedFeatureFlags";
import FeatureFlagsQuery from "lib/queries/FeatureFlagsQuery";
import type {
  IFeatureFlagsQuery,
  IFeatureFlagsQueryVariables,
} from "lib/queries/__generated__/FeatureFlagsQuery.generated";
import { useEffect, type ReactElement } from "react";

let evaluatedFlags: DefinedFeatureFlags & Record<string, unknown> = {};
const evaluatedFlagsAtom = atom<DefinedFeatureFlags & Record<string, unknown>>({});

interface FeatureFlagsProviderProps {
  children: ReactElement;
}
export function FeatureFlagsProvider({ children }: FeatureFlagsProviderProps) {
  const setEvaluatedFlagsAtom = useSetAtom(evaluatedFlagsAtom);

  const { data, refetch } = useQuery<IFeatureFlagsQuery, IFeatureFlagsQueryVariables>(FeatureFlagsQuery, {
    skip: false,
    onCompleted(data) {
      setEvaluatedFlagsAtom(data.featureFlags || {});
      evaluatedFlags = data.featureFlags || {};
    },
  });

  useEffect(() => {
    const controller = new AbortController();
    document.addEventListener("fundamentei:authenticated", () => refetch(), {
      signal: controller.signal,
    });
    document.addEventListener("fundamentei:signedOut", () => refetch(), {
      signal: controller.signal,
    });
    return () => {
      controller.abort();
    };
  });

  useHydrateAtoms([[evaluatedFlagsAtom, data?.featureFlags || {}]]);
  evaluatedFlags = data?.featureFlags || {};

  return children;
}

export function useAllFeatureFlags(): DefinedFeatureFlags {
  const flags = useAtomValue(evaluatedFlagsAtom);
  return Object.freeze(flags);
}

export function useBooleanFlag(flag: keyof DefinedFeatureFlags): boolean {
  const flags = useAtomValue(evaluatedFlagsAtom);
  return flags[flag] === true;
}

export function useFlagValue<Flag extends keyof DefinedFeatureFlags>(flag: Flag): DefinedFeatureFlags[Flag] | null {
  const flags = useAtomValue(evaluatedFlagsAtom);
  return typeof flags[flag] === "undefined" ? null : flags[flag];
}

export function getAllFeatureFlags(): DefinedFeatureFlags {
  return Object.freeze(structuredClone(evaluatedFlags));
}
export function getBooleanFlag(flag: keyof DefinedFeatureFlags): boolean {
  return evaluatedFlags[flag] === true;
}
export function getFlagValue(flag: keyof DefinedFeatureFlags): DefinedFeatureFlags[keyof DefinedFeatureFlags] | null {
  return typeof evaluatedFlags[flag] === "undefined" ? null : evaluatedFlags[flag];
}
