/* eslint-disable no-restricted-syntax */
import getAnalyticsData from "lib/analytics/getAnalyticsData";
import { getBooleanFlag } from "lib/flagging/FeatureFlags";
import { parseCookies } from "nookies";

export default async function trackEvent(eventId: string, eventData: Record<string, unknown> | null = null) {
  try {
    const ignoreDoNotTrackOnBeacons = getBooleanFlag("experimental/ignoreDoNotTrackOnBeacons");
    if (!process.env.API_URL) {
      return;
    }
    // We respect the user's do not track setting
    if (!ignoreDoNotTrackOnBeacons && navigator.doNotTrack && process.env.FUNDAMENTEI_ENV === "production") {
      return;
    }
    const cookies = parseCookies();
    const analyticsData = await getAnalyticsData();
    if (analyticsData === null) {
      return;
    }

    const encoder = new TextEncoder();
    const data = encoder.encode(JSON.stringify(analyticsData));
    const compressedData = new CompressionStream("gzip");
    const writer = compressedData.writable.getWriter();
    writer.write(data);
    writer.close();
    const compressedResponse = await new Response(compressedData.readable).arrayBuffer();
    const payload = btoa(String.fromCharCode.apply(null, Array.from<number>(new Uint8Array(compressedResponse))));

    navigator.sendBeacon(
      `${process.env.API_URL}/beacon`,
      JSON.stringify({
        // timestamp
        t: new Date().getTime(),
        a: cookies.token || null,
        // headers
        h: payload,
        // event
        e: eventId || null,
        // event data
        d: eventData || null,
      }),
    );
  } catch {
    // @ts-ignore
  }
}
