import type { LogsEvent } from "@datadog/browser-logs";
import { datadogLogs } from "@datadog/browser-logs";
import useSelfie from "lib/hooks/useSelfie";
import { useEffect } from "react";

export function initDataDog() {
  if (process.env.DATADOG_CLIENT_SITE && process.env.DATADOG_CLIENT_TOKEN) {
    datadogLogs.init({
      clientToken: process.env.DATADOG_CLIENT_TOKEN,
      site: process.env.DATADOG_CLIENT_SITE,
      forwardErrorsToLogs: true,
      sessionSampleRate: 100,
      env: process.env.FUNDAMENTEI_ENV,
      forwardConsoleLogs: ["warn", "error"],
      silentMultipleInit: true,
      // https://docs.datadoghq.com/real_user_monitoring/guide/upload-javascript-source-maps/?tab=webpackjs
      service: "frontend",
      version: process.env.GIT_SHA_LONG,
      beforeSend: canSendLogEvent,
    });
  }
}

export default function DatadogInit() {
  const selfie = useSelfie();

  useEffect(() => {
    if (selfie) {
      datadogLogs.setUser({
        id: selfie._id,
        email: selfie.email,
        name: selfie.fullName,

        // Flags
        isSiteAdmin: selfie.isSiteAdmin,
        isPremium: selfie.isPremium,
        isBlack: selfie.isBlack,
      });
    } else {
      datadogLogs.clearUser();
    }
  }, [selfie?._id]);

  useEffect(() => {
    initDataDog();
  }, []);

  return null;
}

function canSendLogEvent(event: LogsEvent) {
  // Don't send empty messages back to DD
  if (event.message.trim() === "") {
    return false;
  }
  const skipAnyOf = [
    "ResizeObserver",
    "Abort fetching component for route",
    "Route did not complete loading",
    "Failed to lookup route",
    "Failed to load script",
    "Failed to fetch",
    "Network Error",
    "FedCM get() rejects",
    "api.amplitude.com",
    "algolia.net/1/indexes",
    "As of Tailwind CSS",
    "Update your configuration file to silence this warning",
    "XHR error GET https://customer-yupa1phl08zxqxkf.cloudflarestream.com",
  ];
  return !skipAnyOf.some((message) => {
    return event.error?.stack?.includes(message) || event.message.includes(message);
  });
}
