/* eslint-disable react/jsx-no-constructed-context-values */
import type { ApolloLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { Query } from "@apollo/client/react/components";
import { jwtDecode } from "jwt-decode";
import noop from "lodash/noop";
import type { NextPageContext } from "next";
import { destroyCookie, parseCookies, setCookie } from "nookies";
import type { ReactElement } from "react";
import { createContext, useState } from "react";

import nextTick from "lib/utils/nextTick";
import stripUndefined from "lib/utils/stripUndefined";

import { userSettingsAtom } from "components/settings/atoms";
import { useSetAtom } from "jotai";
import dispatchCustomEvent from "lib/utils/dispatchCustomEvent";
import getQueryStringValue from "lib/utils/getQueryStringValue";
import isBrowser from "lib/utils/isBrowser";
import mapKeys from "lodash/mapKeys";
import pick from "lodash/pick";
import { useAmplitude } from "../../lib/amplitude/Amplitude";
import Selfie from "../../lib/queries/SelfieQuery";
import type { ISelfieQuery } from "../../lib/queries/__generated__/SelfieQuery.generated";

export interface IAuthContext {
  loading: boolean;
  user: ISelfieQuery["selfie"] | null;
  token: string | null;
  login(token: string): void;
  logout(): void;
  refetch(): void;
}

export const AuthContext = createContext<IAuthContext>({
  loading: false,
  user: null,
  token: null,
  login: noop,
  logout: noop,
  refetch: noop,
});

interface IAuthState {
  user: ISelfieQuery["selfie"] | null;
  token: string | null;
  login(token: string): void;
  logout(): void;
}

interface ICookies {
  token?: string;
}

interface IAuthConsumerProps {
  children(state: IAuthState): ReactElement | null;
}

const COOKIE_OPTIONS = {
  // 21 days
  maxAge: 21 * 24 * 60 * 60,
  path: "/",
};

function getToken(ctx: NextPageContext | undefined | null): string | null {
  const cookies = parseCookies(ctx);

  if (cookies && cookies.token) {
    const decoded: {
      exp: number;
      nbf: number;
    } = jwtDecode(cookies.token);

    const now = Date.now().valueOf() / 1000;

    const isExpired =
      (typeof decoded.exp !== "undefined" && decoded.exp < now) ||
      (typeof decoded.nbf !== "undefined" && decoded.nbf > now);

    if (!isExpired) {
      return cookies.token;
    }

    // unwantedly it adds a side-effect into this function...however, it ensures that we won't have an expired token
    // hanging around
    logout(ctx);
  }

  return null;
}

function login(token: string): void {
  setCookie(null, "token", token, COOKIE_OPTIONS);
}

function logout(ctx: NextPageContext | undefined | null): void {
  destroyCookie(ctx, "token", COOKIE_OPTIONS);
}

export function getRequestHeaders(ctx: NextPageContext | undefined | null) {
  const token = getToken(ctx);
  const viewAs = getQueryStringValue(ctx?.query || {}, "x-fndm-view-as", null);
  const xPlanOverride = viewAs !== null && ["black", "premium", "free"].includes(viewAs) ? viewAs.toUpperCase() : null;

  if (token) {
    return stripUndefined({
      Authorization: `Bearer ${token}`,
      "X-Fundamentei-Plan-Override": process.env.FUNDAMENTEI_ENV === "production" ? null : xPlanOverride,
    });
  }

  return {};
}

export const injectAdditionalHeaders = (ctx: NextPageContext | undefined | null): ApolloLink => {
  const isSSR = !isBrowser();
  return setContext(async (_, { headers: previouslySetGraphQLHeaders }) => {
    await nextTick();

    // If we're not in SSR mode, keep whatever headers we've set previously
    if (!isSSR) {
      return {
        headers: previouslySetGraphQLHeaders,
      };
    }

    const incomingRequestHeaders = ctx?.req?.headers || {};

    const hopHeaders = [
      // IP-related headers
      "x-forwarded-for",
      "x-forwarded-proto",
      "x-forwarded-port",

      // x-* headers
      "x-request-id",

      // sec-* headers
      "sec-ch-ua",
      "sec-ch-ua-mobile",
      "sec-ch-ua-platform",
      "sec-fetch-dest",
      "sec-fetch-mode",
      "sec-fetch-site",
      "sec-fetch-user",

      "user-agent",
      "accept-language",
      "referer",
    ];

    const cloudFrontHeaders = pick(
      incomingRequestHeaders,
      Object.keys(incomingRequestHeaders).filter((header) => header.startsWith("cloudfront-")),
    );

    return {
      headers: {
        ...previouslySetGraphQLHeaders,
        ...pick(incomingRequestHeaders, hopHeaders),

        // NOTE: VERY IMPORTANT: Cloudfront won't allow us to override `cloudfront-viewer-*` headers. Hence, we have to
        // collect everything that came in with the original request and forward it to the GraphQL server via prefixing.
        // So it will become `x-forwarded-cloudfront-viewer-*`
        ...mapKeys(cloudFrontHeaders, (_value, key) => `x-forwarded-${key}`),

        "x-fndm-is-ssr": isSSR,
        // This one is used to determine the origin of the request. It's a non-standard header name, but it will work since
        // when doing SSR, the fetch request won't set the Origin header, so we need to forward it from the incoming request
        "x-forwarded-origin": incomingRequestHeaders.origin || incomingRequestHeaders.host,
      },
    };
  });
};

export const setAuthorization = (ctx: NextPageContext | undefined | null): ApolloLink => {
  return setContext(async (_, { headers }) => {
    await nextTick();

    return {
      headers: {
        ...headers,
        ...getRequestHeaders(ctx),
      },
    };
  });
};

interface IAuthProviderProps {
  cookies: ICookies;
  children: ReactElement | null;
}

export function AuthProvider({ cookies = {}, children }: IAuthProviderProps) {
  const amplitude = useAmplitude();
  const [hasToken, setHasToken] = useState<boolean>(!!cookies.token);
  const loadSettings = useSetAtom(userSettingsAtom);

  return (
    <Query<ISelfieQuery>
      query={Selfie}
      skip={!hasToken}
      onCompleted={(data) => {
        // if there's no token set ignore this completely
        if (!hasToken) {
          return;
        }

        if (data && data.selfie) {
          const { selfie } = data;

          dispatchCustomEvent("fundamentei:signedIn");

          loadSettings(selfie.settingsV2);
          amplitude.setUserId(selfie._id);

          amplitude.setUserProperties({
            _id: selfie._id,
            email: selfie.email,
            firstName: selfie.firstName,
            lastName: selfie.lastName,
            fullName: selfie.fullName,
            // eslint-disable-next-line no-nested-ternary
            plan: selfie.isBlack ? "BLACK" : selfie.isPremium ? "PREMIUM" : "FREE",
          });
        }
      }}
    >
      {({ loading, data, refetch, updateQuery }) => {
        let user = null;

        if (hasToken) {
          user = data === undefined ? null : data.selfie;
        }

        if (loading && !user) {
          return null;
        }

        return (
          <AuthContext.Provider
            value={{
              user,
              loading,
              token: cookies.token || null,
              refetch: () => refetch(),
              login: (token: string): void => {
                login(token);
                setHasToken(true);
                refetch();

                dispatchCustomEvent("fundamentei:authenticated", {
                  token,
                });
              },
              logout: (): void => {
                amplitude.logEvent("Signed out");

                // https://help.amplitude.com/hc/en-us/articles/115002889587-JavaScript-SDK-Reference#methods
                amplitude.setUserId(null);
                amplitude.regenerateDeviceId();
                amplitude.clearUserProperties();
                amplitude.resetSessionId();

                logout(null);
                setHasToken(false);

                dispatchCustomEvent("fundamentei:signedOut");

                updateQuery(() => {
                  return {
                    __typename: "Query",
                    selfie: null,
                  };
                });
              },
            }}
          >
            {children}
          </AuthContext.Provider>
        );
      }}
    </Query>
  );
}

export default function Auth({ children }: IAuthConsumerProps): ReactElement | null {
  return <AuthContext.Consumer>{children}</AuthContext.Consumer>;
}
