/* eslint-disable no-console */
import { decode, encode } from "@msgpack/msgpack";
import { arrayBufferToBase64, base64ToArrayBuffer } from "lib/utils/arrayBuffer";

export default async function loadLocaleData(locale: string): Promise<Record<string, any>> {
  switch (locale) {
    case "en-US":
      return import("../../lang/compiled/en-US.json");
    case "pt-BR":
    default:
      return import("../../lang/compiled/pt-BR.json");
  }
}

export function encodeIntlMessages(messages: Record<string, any>) {
  return arrayBufferToBase64(encode(messages));
}

const localeMessagesCache = new Map<string, Record<string, any>>();
export function decodeIntlMessagesCaching(locale: string, messages: string) {
  if (localeMessagesCache.has(locale)) {
    return localeMessagesCache.get(locale) as Record<string, any>;
  }
  try {
    const decoded = (decode(base64ToArrayBuffer(messages)) as Record<string, any>) || {};
    localeMessagesCache.set(locale, decoded);
    return decoded;
  } catch {
    console.log("Failed to decode locale messages for `%s`", messages);
    return {};
  }
}
