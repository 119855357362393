import { getQueryParams } from "lib/analytics/getQueryParams";
import isBrowser from "lib/utils/isBrowser";
import stripUndefined from "lib/utils/stripUndefined";
import get from "lodash/get";

export default async function getAnalyticsData() {
  try {
    return await getAllAnalyticsData();
  } catch {
    return null;
  }
}

async function getAllAnalyticsData() {
  if (!isBrowser()) {
    return null;
  }

  const visitorId = localStorage.getItem("visitorId") || null;

  let timeZone: string | null = null;
  if (Intl && Intl.DateTimeFormat && typeof Intl.DateTimeFormat.prototype.resolvedOptions === "function") {
    const resolvedOptions = Intl.DateTimeFormat().resolvedOptions();
    timeZone = resolvedOptions.timeZone;
  }

  return stripUndefined({
    visitorId,
    page: stripUndefined({
      referrer: document.referrer,
      referringDomain: document.referrer ? new URL(document.referrer).hostname : null,
      title: document.title,
      url: window.location.href,
      location: window.location.href,
      hash: window.location.hash,
      searchParams: getQueryParams(),
    }),
    campaign: {
      utm: getUtmParams(),
      clickIds: getClickIds(),
    },
    initialCampaign: getInitialCampaign(),
    webdriver: navigator.webdriver,
    // The number of logical processors available to run threads on the user's computer
    hardwareConcurrency: navigator.hardwareConcurrency,
    // The user agent string
    userAgent: navigator.userAgent,
    // The user's preferred language
    language: navigator.language,
    // The user's preferred languages
    languages: navigator.languages,
    // The user's platform
    userAgentData:
      (await navigator.userAgentData
        ?.getHighEntropyValues([
          "architecture",
          "platform",
          "bitness",
          "mobile",
          "formFactor",
          "model",
          "platformVersion",
          "fullVersionList",
        ])
        .then(stripUndefined)) || Promise.resolve(null),
    pdfViewerEnabled: get(navigator, "pdfViewerEnabled", null),
    // The user's browser's Do Not Track setting
    doNotTrack: navigator.doNotTrack,
    // The user's device memory in gigabytes
    deviceMemory: get(navigator, "deviceMemory", null),
    // The user's screen resolution
    devicePixelRatio: window.devicePixelRatio,
    // The amount of horizontal space (in pixels) available to the window
    availableWidth: window.screen.availWidth,
    // The height (in pixels) of the space available for Web content on the screen
    availableHeight: window.screen.availHeight,
    // The width of the screen in pixels
    screenWidth: window.screen.width,
    // The height of the screen in pixels
    screenHeight: window.screen.height,
    orientation: window.screen.orientation.type,
    timeZone,
    timeZoneOffset: getTimeZoneOffset(),
    scroll: {
      width: document.documentElement.scrollWidth,
      height: document.documentElement.scrollHeight,
      scrolledSoFar: window.scrollY,
      scrollDepth: Math.min(1, (window.innerHeight + window.scrollY) / document.body.offsetHeight),
    },
  });
}

let initialCampaign: Record<string, Record<string, string | null>> | null = null;
function getInitialCampaign() {
  if (initialCampaign) {
    return initialCampaign;
  }
  initialCampaign = {
    utm: getUtmParams(),
    clickIds: getClickIds(),
  };
  return initialCampaign;
}

function getTimeZoneOffset() {
  const offset = new Date().getTimezoneOffset();
  const absoluteOffset = Math.abs(offset);

  return [
    offset < 0 ? "+" : "-",
    // eslint-disable-next-line prefer-template
    ("00" + Math.floor(absoluteOffset / 60)).slice(-2),
    ":",
    // eslint-disable-next-line prefer-template
    ("00" + (absoluteOffset % 60)).slice(-2),
  ].join("");
}

export const UTM_CAMPAIGN = "utm_campaign";
export const UTM_CONTENT = "utm_content";
export const UTM_ID = "utm_id";
export const UTM_MEDIUM = "utm_medium";
export const UTM_SOURCE = "utm_source";
export const UTM_TERM = "utm_term";

export const DCLID = "dclid";
export const FBCLID = "fbclid";
export const GBRAID = "gbraid";
export const GCLID = "gclid";
export const KO_CLICK_ID = "ko_click_id";
export const LI_FAT_ID = "li_fat_id";
export const MSCLKID = "msclkid";
export const RDT_CID = "rtd_cid";
export const TTCLID = "ttclid";
export const TWCLID = "twclid";
export const WBRAID = "wbraid";

function getUtmParams() {
  const params = getQueryParams();

  const utmCampaign = params[UTM_CAMPAIGN];
  const utmContent = params[UTM_CONTENT];
  const utmId = params[UTM_ID];
  const utmMedium = params[UTM_MEDIUM];
  const utmSource = params[UTM_SOURCE];
  const utmTerm = params[UTM_TERM];

  return stripUndefined({
    utmCampaign,
    utmContent,
    utmId,
    utmMedium,
    utmSource,
    utmTerm,
  });
}

function getClickIds() {
  const params = getQueryParams();
  return stripUndefined({
    [DCLID]: params[DCLID],
    [FBCLID]: params[FBCLID],
    [GBRAID]: params[GBRAID],
    [GCLID]: params[GCLID],
    [KO_CLICK_ID]: params[KO_CLICK_ID],
    [LI_FAT_ID]: params[LI_FAT_ID],
    [MSCLKID]: params[MSCLKID],
    [RDT_CID]: params[RDT_CID],
    [TTCLID]: params[TTCLID],
    [TWCLID]: params[TWCLID],
    [WBRAID]: params[WBRAID],
  });
}
