export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  AccountNumber: { input: any; output: any };
  BigInt: { input: any; output: any };
  Byte: { input: any; output: any };
  CountryCode: { input: any; output: any };
  Cuid: { input: any; output: any };
  Currency: { input: any; output: any };
  Cursor: { input: any; output: any };
  DID: { input: any; output: any };
  Date: { input: any; output: any };
  DateTime: { input: any; output: any };
  DateTimeISO: { input: any; output: any };
  DeweyDecimal: { input: any; output: any };
  Duration: { input: any; output: any };
  EmailAddress: { input: any; output: any };
  FinancialsTemplateID: { input: any; output: any };
  GUID: { input: any; output: any };
  HSL: { input: any; output: any };
  HSLA: { input: any; output: any };
  HexColorCode: { input: any; output: any };
  Hexadecimal: { input: any; output: any };
  IBAN: { input: any; output: any };
  IP: { input: any; output: any };
  IPCPatent: { input: any; output: any };
  IPv4: { input: any; output: any };
  IPv6: { input: any; output: any };
  ISBN: { input: any; output: any };
  ISO6391LanguageCode: { input: any; output: any };
  ISO8601Duration: { input: any; output: any };
  IndicatorID: { input: any; output: any };
  JSON: { input: any; output: any };
  JSONObject: { input: any; output: any };
  JWT: { input: any; output: any };
  LCCSubclass: { input: any; output: any };
  Latitude: { input: any; output: any };
  LocalDate: { input: any; output: any };
  LocalDateTime: { input: any; output: any };
  LocalEndTime: { input: any; output: any };
  LocalTime: { input: any; output: any };
  Locale: { input: any; output: any };
  Long: { input: any; output: any };
  Longitude: { input: any; output: any };
  MAC: { input: any; output: any };
  NegativeFloat: { input: any; output: any };
  NegativeInt: { input: any; output: any };
  NonEmptyString: { input: any; output: any };
  NonNegativeFloat: { input: any; output: any };
  NonNegativeInt: { input: any; output: any };
  NonPositiveFloat: { input: any; output: any };
  NonPositiveInt: { input: any; output: any };
  ObjectID: { input: any; output: any };
  PhoneNumber: { input: any; output: any };
  Port: { input: any; output: any };
  PositiveFloat: { input: any; output: any };
  PositiveInt: { input: any; output: any };
  PostalCode: { input: any; output: any };
  RGB: { input: any; output: any };
  RGBA: { input: any; output: any };
  RoutingNumber: { input: any; output: any };
  SESSN: { input: any; output: any };
  SafeInt: { input: any; output: any };
  SemVer: { input: any; output: any };
  TickerSymbol: { input: any; output: any };
  Time: { input: any; output: any };
  TimeZone: { input: any; output: any };
  Timestamp: { input: any; output: any };
  URL: { input: any; output: any };
  USCurrency: { input: any; output: any };
  UUID: { input: any; output: any };
  UnsignedFloat: { input: any; output: any };
  UnsignedInt: { input: any; output: any };
  Upload: { input: any; output: any };
  UtcOffset: { input: any; output: any };
  Void: { input: any; output: any };
};

export type IAddPositionToPortfolioInput = {
  goal: Scalars["Float"]["input"];
  numberOfShares: Scalars["Float"]["input"];
  securityCountry: ISecurityCountry;
  tickerSymbol: Scalars["TickerSymbol"]["input"];
};

export type IAddReactionInput = {
  content: IReactionContent;
  subjectId: Scalars["ObjectID"]["input"];
  subjectType: IReactionSubject;
};

export type IAddStarInput = {
  starrableId: Scalars["ID"]["input"];
};

export type IAgendaBreakdown = {
  __typename: "AgendaBreakdown";
  inTheLastMonth: Scalars["Int"]["output"];
  inTheMonth: Scalars["Int"]["output"];
  inTheNextMonth: Scalars["Int"]["output"];
  inTheNextNinetyDays: Scalars["Int"]["output"];
  inTheWeek: Scalars["Int"]["output"];
  inTheYear: Scalars["Int"]["output"];
  today: Scalars["Int"]["output"];
};

export type IAgendaEvent = {
  _id: Scalars["ObjectID"]["output"];
  eventDate: Scalars["Date"]["output"];
  eventType: IAgendaEventType;
  issuer: Maybe<IIssuer>;
};

export enum IAgendaEventType {
  AnnualReport = "ANNUAL_REPORT",
  FirstQuarterEarningsConferenceCall = "FIRST_QUARTER_EARNINGS_CONFERENCE_CALL",
  FirstQuarterEarningsResults = "FIRST_QUARTER_EARNINGS_RESULTS",
  FourthQuarterEarningsConferenceCall = "FOURTH_QUARTER_EARNINGS_CONFERENCE_CALL",
  FourthQuarterEarningsResults = "FOURTH_QUARTER_EARNINGS_RESULTS",
  GeneralMeeting = "GENERAL_MEETING",
  GuidanceCall = "GUIDANCE_CALL",
  PaymentOfCashDividends = "PAYMENT_OF_CASH_DIVIDENDS",
  PaymentOfSpecialCashDividends = "PAYMENT_OF_SPECIAL_CASH_DIVIDENDS",
  PaymentOfStockDividends = "PAYMENT_OF_STOCK_DIVIDENDS",
  RightsIssue = "RIGHTS_ISSUE",
  SecondQuarterEarningsConferenceCall = "SECOND_QUARTER_EARNINGS_CONFERENCE_CALL",
  SecondQuarterEarningsResults = "SECOND_QUARTER_EARNINGS_RESULTS",
  SpinOff = "SPIN_OFF",
  StockSplit = "STOCK_SPLIT",
  ThirdQuarterEarningsConferenceCall = "THIRD_QUARTER_EARNINGS_CONFERENCE_CALL",
  ThirdQuarterEarningsResults = "THIRD_QUARTER_EARNINGS_RESULTS",
}

export type IAmericanCompanyIssuer = IIssuerLogos &
  IRankedIssuer & {
    __typename: "AmericanCompanyIssuer";
    _id: Scalars["ObjectID"]["output"];
    address: Maybe<IAmericanCompanyIssuerAddress>;
    analysisVideo: Maybe<IYouTubeVideo>;
    businessDescription: Scalars["String"]["output"];
    chiefExecutiveOfficer: Maybe<IMorningstarPerson>;
    /** A list of all available covers for this Issuer */
    covers: Array<ICover>;
    edgarHref: Scalars["String"]["output"];
    /** The most recent number of employees reported by the company */
    employeeNumbers: Maybe<IEmployeeNumbers>;
    engagementStats: IIssuerEngagementStats;
    exchange: Scalars["String"]["output"];
    executivesAndDirectors: Array<IMorningstarPerson>;
    financialsTemplate: IAmericanCompanyIssuerFinancialsTemplate;
    foundedYear: Maybe<Scalars["String"]["output"]>;
    /** Company's ratings at https://glassdoor.com */
    glassdoorRatings: Maybe<IGlassdoorRatings>;
    /** The historical number of employees reported by the company */
    historicalEmployeeNumbers: Array<IEmployeeNumbers>;
    investorRelationsHref: Maybe<Scalars["String"]["output"]>;
    ipoDate: Maybe<Scalars["String"]["output"]>;
    /** Employer Identification Number */
    irs: Maybe<Scalars["String"]["output"]>;
    isAmericanDepositaryReceipt: Scalars["Boolean"]["output"];
    isInPortfolio: Scalars["Boolean"]["output"];
    isREIT: Scalars["Boolean"]["output"];
    listingExchange: ISecuritiesExchange;
    marketCapitalization: Maybe<IMarketCap>;
    morningstarHref: Scalars["String"]["output"];
    name: Scalars["String"]["output"];
    nareitClassification: Maybe<INareitClassification>;
    nasdaqHref: Scalars["String"]["output"];
    /** Is the next upcoming Calendar Event for this Issuer */
    nextUpcomingCalendarEvent: Maybe<IAgendaEvent>;
    niceLogo: Maybe<IIssuerLogo>;
    /**
     * Total amount of employees reported by the company, both full-time and part-time
     * @deprecated Use employeeNumbers.totalOfEmployees instead
     */
    numberOfEmployees: Scalars["Int"]["output"];
    /** Is the position in the overall Site ranking */
    overallRankingPlacement: Maybe<IRankingPlacement>;
    /** Is the Rating in the overall Site ranking */
    overallRankingRating: Maybe<Scalars["Float"]["output"]>;
    /** Is the Rating Summary in the overall Site ranking */
    overallRankingRatingSummary: Maybe<IRatingSummary>;
    payRatioHistory: Array<IPayRatioItem>;
    placeOfIncorporation: Maybe<Scalars["String"]["output"]>;
    primaryTickerSymbol: Scalars["TickerSymbol"]["output"];
    /** Is the rating that the User has given (if any) */
    rating: Maybe<IViewerRating>;
    sayOnPayHistory: Array<ISayOnPayItem>;
    sector: Scalars["String"]["output"];
    securities: Array<IAmericanCompanyIssuerSecurity>;
    shares: Maybe<ISharesHistory>;
    shortBusinessDescription: Scalars["String"]["output"];
    standardAndPoorsIndicator: Maybe<Scalars["String"]["output"]>;
    standardLogo: Maybe<IIssuerLogo>;
    tickerSymbolPrefix: Scalars["String"]["output"];
    tickerSymbols: Array<Scalars["String"]["output"]>;
    viewerDidBookmark: Scalars["Boolean"]["output"];
    websiteHref: Maybe<Scalars["String"]["output"]>;
    youtubeVideos: Array<IYouTubeVideo>;
  };

export type IAmericanCompanyIssuerFoundedYearArgs = {
  humanReadable?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type IAmericanCompanyIssuerIpoDateArgs = {
  humanReadable?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type IAmericanCompanyIssuerMorningstarHrefArgs = {
  tickerSymbol: Scalars["TickerSymbol"]["input"];
};

export type IAmericanCompanyIssuerNasdaqHrefArgs = {
  tickerSymbol: Scalars["TickerSymbol"]["input"];
};

export type IAmericanCompanyIssuerOverallRankingPlacementArgs = {
  evaluationPeriod?: InputMaybe<IRatingEvaluationPeriod>;
};

export type IAmericanCompanyIssuerOverallRankingRatingSummaryArgs = {
  evaluationPeriod?: InputMaybe<IRatingEvaluationPeriod>;
};

export type IAmericanCompanyIssuerSharesArgs = {
  tickerSymbol: Scalars["TickerSymbol"]["input"];
};

export type IAmericanCompanyIssuerAddress = {
  __typename: "AmericanCompanyIssuerAddress";
  addressLine1: Scalars["String"]["output"];
  addressLine2: Scalars["String"]["output"];
  addressLine3: Scalars["String"]["output"];
  addressLine4: Scalars["String"]["output"];
  city: Scalars["String"]["output"];
  country: Scalars["String"]["output"];
  postalCode: Maybe<Scalars["String"]["output"]>;
  state: Maybe<Scalars["String"]["output"]>;
};

export type IAmericanCompanyIssuerFinancialsTemplate = {
  __typename: "AmericanCompanyIssuerFinancialsTemplate";
  globalTemplateCode: Scalars["String"]["output"];
  industryTemplateName: Scalars["String"]["output"];
  reportStyleName: Scalars["String"]["output"];
  templateCode: Scalars["String"]["output"];
};

export type IAmericanCompanyIssuerSecurity = {
  __typename: "AmericanCompanyIssuerSecurity";
  depositoryReceiptRatio: Maybe<Scalars["String"]["output"]>;
  depositoryReceiptType: Maybe<Scalars["String"]["output"]>;
  investmentType: IInvestmentType;
  ipoDate: Maybe<Scalars["Date"]["output"]>;
  isPrimaryShare: Maybe<Scalars["Boolean"]["output"]>;
  tickerSymbol: Scalars["TickerSymbol"]["output"];
  trailingReturns: Maybe<ITrailingReturns>;
};

export type IAmericanCompanyLiquidity = {
  __typename: "AmericanCompanyLiquidity";
  sharesOutstanding: Maybe<IAmericanCompanySharesOutstanding>;
  speculativeContent: Array<ISpeculativeContent>;
};

export type IAmericanCompanyPortfolioAsset = {
  __typename: "AmericanCompanyPortfolioAsset";
  industrySegment: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  primaryLogo: Maybe<Scalars["String"]["output"]>;
  tickerSymbolPrefix: Scalars["String"]["output"];
};

export type IAmericanCompanySharesOutstanding = {
  __typename: "AmericanCompanySharesOutstanding";
  closelyHeldShares: Scalars["BigInt"]["output"];
  float: Scalars["BigInt"]["output"];
  totalOfSharesOutstanding: Scalars["BigInt"]["output"];
};

export type IAmericanCompanyTotalCompensationByYear = ITotalCompensationByYear & {
  __typename: "AmericanCompanyTotalCompensationByYear";
  fiscalYear: Scalars["String"]["output"];
  netIncome: Scalars["Float"]["output"];
  percentOfNetIncome: Scalars["Float"]["output"];
  percentageOfRevenue: Scalars["Float"]["output"];
  revenue: Scalars["Float"]["output"];
  totalBoardOfDirectorsCompensation: Scalars["Float"]["output"];
  totalCompensation: Scalars["Float"]["output"];
  totalExecutiveCompensation: Scalars["Float"]["output"];
};

export type IAmericanIssuerSearchResultNode = IIssuerSearchResultNode &
  IUniformResourceLocatable & {
    __typename: "AmericanIssuerSearchResultNode";
    _id: Scalars["ObjectID"]["output"];
    industry: Maybe<Scalars["String"]["output"]>;
    industryGroup: Maybe<Scalars["String"]["output"]>;
    isDepositoryReceipt: Scalars["Boolean"]["output"];
    isReit: Scalars["Boolean"]["output"];
    listingExchangeCode: Scalars["String"]["output"];
    marketCapitalization: Maybe<IMarketCap>;
    name: Scalars["String"]["output"];
    niceLogo: Maybe<IIssuerLogo>;
    numberOfEmployees: Maybe<Scalars["Int"]["output"]>;
    resourcePath: Scalars["String"]["output"];
    sector: Maybe<Scalars["String"]["output"]>;
    tickerSymbol: Scalars["String"]["output"];
    tickerSymbols: Array<Scalars["String"]["output"]>;
    url: Scalars["URL"]["output"];
  };

export type IAmericanIssuerSpecialDividendsCorporateAction = {
  __typename: "AmericanIssuerSpecialDividendsCorporateAction";
  amountPaid: Scalars["Float"]["output"];
  excludingDate: Maybe<Scalars["Date"]["output"]>;
  payDate: Maybe<Scalars["Date"]["output"]>;
  recordDate: Maybe<Scalars["Date"]["output"]>;
};

export type IAmericanReitTotalCompensationByYear = ITotalCompensationByYear & {
  __typename: "AmericanREITTotalCompensationByYear";
  fiscalYear: Scalars["String"]["output"];
  fundsFromOperations: Scalars["Float"]["output"];
  percentOfFundsFromOperations: Scalars["Float"]["output"];
  percentageOfRevenue: Scalars["Float"]["output"];
  revenue: Scalars["Float"]["output"];
  totalBoardOfDirectorsCompensation: Scalars["Float"]["output"];
  totalCompensation: Scalars["Float"]["output"];
  totalExecutiveCompensation: Scalars["Float"]["output"];
};

export type IAmericanReitPortfolioAsset = {
  __typename: "AmericanReitPortfolioAsset";
  industrySegment: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  primaryLogo: Maybe<Scalars["String"]["output"]>;
  tickerSymbolPrefix: Scalars["String"]["output"];
};

export type IAmericanSecurity = ISecurity & {
  __typename: "AmericanSecurity";
  _id: Scalars["ObjectID"]["output"];
  issuer: Maybe<IIssuer>;
  name: Scalars["String"]["output"];
  sharesOutstanding: Scalars["BigInt"]["output"];
  tickerSymbol: Scalars["TickerSymbol"]["output"];
  trailingReturns: Maybe<ITrailingReturns>;
  type: IGlobalSecurityType;
};

export type IAnalystRating = {
  __typename: "AnalystRating";
  analyst: IUser;
  rating: Scalars["Int"]["output"];
};

export type IAnswerablePost = {
  answeredAt: Maybe<Scalars["DateTime"]["output"]>;
  answeredBy: Maybe<IUser>;
};

export type IAsset = IAmericanCompanyIssuer | IBrazilianCompanyIssuer | IBrazilianRealEstateFund;

export type IAssetDiscussionComment = IComment &
  IEditableComment &
  ILockableComment &
  IPinnableComment &
  IReactable &
  IUniformResourceLocatable & {
    __typename: "AssetDiscussionComment";
    _id: Scalars["ObjectID"]["output"];
    asset: IAsset;
    assetType: IAssetType;
    author: IUser;
    bodyAsPlainText: Scalars["String"]["output"];
    editHistory: Array<ICommentContentEdit>;
    isLocked: Scalars["Boolean"]["output"];
    isPinned: Scalars["Boolean"]["output"];
    lastEditedAt: Maybe<Scalars["DateTime"]["output"]>;
    lockedAt: Maybe<Scalars["DateTime"]["output"]>;
    lockedBy: Maybe<IUser>;
    originalBodyAsPlainText: Scalars["String"]["output"];
    participants: IAssetDiscussionCommentParticipants;
    pinnedAt: Maybe<Scalars["DateTime"]["output"]>;
    pinnedBy: Maybe<IUser>;
    postedAt: Scalars["DateTime"]["output"];
    reactionGroups: Array<IReactionGroup>;
    replies: IAssetDiscussionCommentRepliesPagination;
    resourcePath: Scalars["String"]["output"];
    shortId: Scalars["ID"]["output"];
    url: Scalars["URL"]["output"];
    viewerCanDestroy: Scalars["Boolean"]["output"];
    viewerCanEdit: Scalars["Boolean"]["output"];
    viewerCanReact: Scalars["Boolean"]["output"];
    viewerCanReply: Scalars["Boolean"]["output"];
    viewerCanViewReplies: Scalars["Boolean"]["output"];
    viewerDidAuthor: Scalars["Boolean"]["output"];
  };

export type IAssetDiscussionCommentParticipantsArgs = {
  top: Scalars["Int"]["input"];
};

export type IAssetDiscussionCommentRepliesArgs = {
  cursor: InputMaybe<Scalars["Cursor"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy: InputMaybe<IAssetDiscussionCommentOrder>;
};

export type IAssetDiscussionCommentOrder = {
  direction: IOrderDirection;
  field: IAssetDiscussionCommentOrderField;
};

export enum IAssetDiscussionCommentOrderField {
  MostReplied = "MOST_REPLIED",
  PostedAt = "POSTED_AT",
}

export type IAssetDiscussionCommentPagination = {
  __typename: "AssetDiscussionCommentPagination";
  nodes: Array<IAssetDiscussionComment>;
  pageInfo: IPageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type IAssetDiscussionCommentParticipants = {
  __typename: "AssetDiscussionCommentParticipants";
  nodes: Array<IUser>;
  totalCount: Scalars["Int"]["output"];
};

export type IAssetDiscussionCommentRepliesPagination = {
  __typename: "AssetDiscussionCommentRepliesPagination";
  nodes: Array<IAssetDiscussionCommentReply>;
  pageInfo: IPageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type IAssetDiscussionCommentReply = IComment &
  IEditableComment &
  IReactable & {
    __typename: "AssetDiscussionCommentReply";
    _id: Scalars["ObjectID"]["output"];
    author: IUser;
    bodyAsPlainText: Scalars["String"]["output"];
    editHistory: Array<ICommentContentEdit>;
    lastEditedAt: Maybe<Scalars["DateTime"]["output"]>;
    originalBodyAsPlainText: Scalars["String"]["output"];
    postedAt: Scalars["DateTime"]["output"];
    reactionGroups: Array<IReactionGroup>;
    shortId: Scalars["ID"]["output"];
    viewerCanDestroy: Scalars["Boolean"]["output"];
    viewerCanEdit: Scalars["Boolean"]["output"];
    viewerCanReact: Scalars["Boolean"]["output"];
    viewerDidAuthor: Scalars["Boolean"]["output"];
  };

export enum IAssetNamespace {
  Bra = "BRA",
  Usa = "USA",
}

export type IAssetPortfolioPosition = {
  __typename: "AssetPortfolioPosition";
  _id: Scalars["ObjectID"]["output"];
  goal: Scalars["Float"]["output"];
  numberOfShares: Scalars["Float"]["output"];
  price: IQuoteChartNode;
  security: ISecurity;
  tickerSymbol: Scalars["TickerSymbol"]["output"];
};

export type IAssetPortfolioResultsCenterItem = {
  __typename: "AssetPortfolioResultsCenterItem";
  _id: Scalars["ObjectID"]["output"];
  asset: IPortfolioAsset;
  financialHighlights: Maybe<IFinancialHighlights>;
  latestFinancialsDeliveryDate: Scalars["Date"]["output"];
  netIncomeDiff: Array<IQuarterlyNetIncomeDifferential>;
};

export enum IAssetType {
  AmericanCompany = "AMERICAN_COMPANY",
  AmericanReit = "AMERICAN_REIT",
  BrazilianCompany = "BRAZILIAN_COMPANY",
  BrazilianRealEstateFund = "BRAZILIAN_REAL_ESTATE_FUND",
  DepositaryReceipt = "DEPOSITARY_RECEIPT",
  Etf = "ETF",
}

export type IAuthenticationResult = {
  __typename: "AuthenticationResult";
  accessToken: Scalars["String"]["output"];
  email: Scalars["String"]["output"];
  isRecentlyCreatedUser: Scalars["Boolean"]["output"];
};

export type IAvailableHighlights = {
  __typename: "AvailableHighlights";
  _id: Scalars["ObjectID"]["output"];
  description: Scalars["String"]["output"];
};

export type IB3IndexParticipation = {
  __typename: "B3IndexParticipation";
  /**
   * All individual components that are present in the index. For example: PETR3 and PETR4 are usually present on IBOV.
   * Hence the individual totals will be listed here
   */
  components: Array<IB3IndexParticipationComponent>;
  marketIndex: IB3MarketIndex;
  /** Is the sum of all components' participation */
  stockParticipationPercent: Scalars["Float"]["output"];
  /** Is the sum of all components' theoretical quantity */
  theoreticalQuantity: Scalars["BigInt"]["output"];
};

export type IB3IndexParticipationComponent = {
  __typename: "B3IndexParticipationComponent";
  stockParticipationPercent: Scalars["Float"]["output"];
  theoreticalQuantity: Scalars["BigInt"]["output"];
  tickerSymbol: Scalars["TickerSymbol"]["output"];
};

export type IB3MarketIndex = {
  __typename: "B3MarketIndex";
  name: Scalars["String"]["output"];
  tickerSymbol: Scalars["TickerSymbol"]["output"];
  validSince: Scalars["Date"]["output"];
  validUntil: Scalars["Date"]["output"];
};

export enum IB3MarketIndexMaturity {
  /** Valid for the day opening */
  Day = "DAY",
  /** Valid for the quarter */
  Quarter = "QUARTER",
}

export enum IBalancingStrategySetting {
  FixedGoal = "FIXED_GOAL",
  WeightedGoal = "WEIGHTED_GOAL",
}

export type IBlackCourse = {
  __typename: "BlackCourse";
  _id: Scalars["ObjectID"]["output"];
  cover: Maybe<IBlackCourseCover>;
  createdAt: Scalars["DateTime"]["output"];
  description: Scalars["String"]["output"];
  lastUpdatedAt: Maybe<Scalars["DateTime"]["output"]>;
  lessons: Array<IBlackCourseLesson>;
  modules: Array<IBlackCourseModule>;
  name: Scalars["String"]["output"];
  objectId: Scalars["ID"]["output"];
  postEngagement: Maybe<IPostEngagement>;
  publishedAt: Maybe<Scalars["DateTime"]["output"]>;
  slug: Scalars["String"]["output"];
};

export type IBlackCourseCover = {
  __typename: "BlackCourseCover";
  height: Scalars["Int"]["output"];
  url: Scalars["String"]["output"];
  width: Scalars["Int"]["output"];
};

export type IBlackCourseEdge = {
  __typename: "BlackCourseEdge";
  node: IBlackCourse;
};

export type IBlackCourseLesson = {
  __typename: "BlackCourseLesson";
  _id: Scalars["ObjectID"]["output"];
  attachments: Array<IBlackCourseLessonAttachment>;
  createdAt: Scalars["DateTime"]["output"];
  description: Scalars["String"]["output"];
  lastUpdatedAt: Maybe<Scalars["DateTime"]["output"]>;
  media: Array<IFundamenteiMedia>;
  objectId: Scalars["ID"]["output"];
  title: Scalars["String"]["output"];
  viewerCompletedAt: Maybe<Scalars["DateTime"]["output"]>;
};

export type IBlackCourseLessonAttachment = {
  __typename: "BlackCourseLessonAttachment";
  attachedAt: Scalars["DateTime"]["output"];
  file: IBlackCourseLessonAttachmentFile;
  objectId: Scalars["ID"]["output"];
  url: Scalars["String"]["output"];
};

export type IBlackCourseLessonAttachmentFile = {
  __typename: "BlackCourseLessonAttachmentFile";
  mimeType: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  sha256: Maybe<Scalars["String"]["output"]>;
  size: Scalars["Int"]["output"];
};

export type IBlackCourseModule = {
  __typename: "BlackCourseModule";
  _id: Scalars["ObjectID"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  description: Scalars["String"]["output"];
  lastUpdatedAt: Maybe<Scalars["DateTime"]["output"]>;
  lessons: Array<IBlackCourseLesson>;
  name: Scalars["String"]["output"];
  objectId: Scalars["ID"]["output"];
};

export type IBlackCoursesConnection = {
  __typename: "BlackCoursesConnection";
  edges: Array<IBlackCourseEdge>;
  nodes: Array<IBlackCourse>;
  pageInfo: IPageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type IBlackMediaPlaybackStateInput = {
  focus: InputMaybe<IPlaybackFocusState>;
  mediaId: Scalars["ID"]["input"];
  playbackState: IPlaybackPlayerState;
  playedSoFar: Scalars["Int"]["input"];
};

export type IBlackSubscriptionPeriod = {
  __typename: "BlackSubscriptionPeriod";
  endDate: Scalars["Date"]["output"];
  isRenewable: Scalars["Boolean"]["output"];
  isSeptember23LaunchSale: Scalars["Boolean"]["output"];
  renewUntil: Maybe<Scalars["Date"]["output"]>;
  startDate: Scalars["Date"]["output"];
};

export type IBookmark = {
  __typename: "Bookmark";
  _id: Scalars["ObjectID"]["output"];
  bookmarkedAt: Scalars["DateTime"]["output"];
  issuer: IIssuer;
  overallRating: Maybe<Scalars["Float"]["output"]>;
};

export type IBookmarksBreakdown = {
  __typename: "BookmarksBreakdown";
  acoesCount: Scalars["Int"]["output"];
  fiisCount: Scalars["Int"]["output"];
  reitsCount: Scalars["Int"]["output"];
  stocksCount: Scalars["Int"]["output"];
};

export enum IBookmarksOrder {
  CreatedAt = "CREATED_AT",
  IssuerName = "ISSUER_NAME",
  MarketCapitalization = "MARKET_CAPITALIZATION",
  Rating = "RATING",
}

export type IBrazilianBankFinancialStatementRow = {
  bisRatio: Scalars["BigInt"]["output"];
  cashFlowForDividends: Scalars["BigInt"]["output"];
  cashFlowForDividendsToNetIncomeRatio: Scalars["Float"]["output"];
  endOfPeriod: Scalars["Date"]["output"];
  financialIntermediationRevenue: Scalars["BigInt"]["output"];
  fiscalYear: Scalars["String"]["output"];
  incomeTaxes: Scalars["BigInt"]["output"];
  netEquity: Scalars["BigInt"]["output"];
  netIncome: Scalars["BigInt"]["output"];
  netIncomeFromDiscontinuedOperations: Scalars["BigInt"]["output"];
  netMargin: Scalars["Float"]["output"];
  payout: Scalars["BigInt"]["output"];
  payoutToNetIncomeRatio: Scalars["BigInt"]["output"];
  provisionForCreditLosses: Scalars["BigInt"]["output"];
  provisionForCreditLossesToNetIncomeRatio: Scalars["BigInt"]["output"];
  returnOnEquity: Scalars["Float"]["output"];
  startOfPeriod: Scalars["Date"]["output"];
  totalAssets: Scalars["BigInt"]["output"];
};

export type IBrazilianBankQuarterlyFinancialsInput = {
  bisRatio: Scalars["BigInt"]["input"];
  cashFlowForDividends: Scalars["BigInt"]["input"];
  endOfPeriod: Scalars["DateTime"]["input"];
  financialIntermediationRevenue: Scalars["BigInt"]["input"];
  incomeTaxes: Scalars["BigInt"]["input"];
  netEquity: Scalars["BigInt"]["input"];
  netIncome: Scalars["BigInt"]["input"];
  netIncomeFromDiscontinuedOperations: Scalars["BigInt"]["input"];
  provisionForCreditLosses: Scalars["BigInt"]["input"];
  startOfPeriod: Scalars["DateTime"]["input"];
  totalAssets: Scalars["BigInt"]["input"];
};

export type IBrazilianBankYearlyFinancialsInput = {
  bisRatio: Scalars["BigInt"]["input"];
  cashFlowForDividends: Scalars["BigInt"]["input"];
  endOfPeriod: Scalars["DateTime"]["input"];
  financialIntermediationRevenue: Scalars["BigInt"]["input"];
  incomeTaxes: Scalars["BigInt"]["input"];
  netEquity: Scalars["BigInt"]["input"];
  netIncome: Scalars["BigInt"]["input"];
  netIncomeFromDiscontinuedOperations: Scalars["BigInt"]["input"];
  provisionForCreditLosses: Scalars["BigInt"]["input"];
  startOfPeriod: Scalars["DateTime"]["input"];
  totalAssets: Scalars["BigInt"]["input"];
};

export type IBrazilianCompanyFinancialStatementRow = {
  capex: Scalars["BigInt"]["output"];
  capexToCashFlowFromOperationsRatio: Scalars["Float"]["output"];
  cash: Scalars["BigInt"]["output"];
  cashFlowForDividends: Scalars["BigInt"]["output"];
  cashFlowForDividendsToNetIncomeRatio: Scalars["Float"]["output"];
  cashFlowFromFinancing: Scalars["BigInt"]["output"];
  cashFlowFromOperations: Scalars["BigInt"]["output"];
  debt: Scalars["BigInt"]["output"];
  debtToNetEquityRatio: Scalars["Float"]["output"];
  depreciationAndAmortization: Scalars["BigInt"]["output"];
  ebit: Scalars["BigInt"]["output"];
  ebitToNetRevenueRatio: Scalars["Float"]["output"];
  ebitda: Scalars["BigInt"]["output"];
  endOfPeriod: Scalars["Date"]["output"];
  financialResult: Scalars["BigInt"]["output"];
  fiscalYear: Scalars["String"]["output"];
  freeCashFlow: Scalars["BigInt"]["output"];
  incomeTaxes: Scalars["BigInt"]["output"];
  incomeTaxesToTaxableProfitRatio: Scalars["BigInt"]["output"];
  netDebt: Scalars["BigInt"]["output"];
  netDebtToEbitdaRatio: Scalars["Float"]["output"];
  netEquity: Scalars["BigInt"]["output"];
  netIncome: Scalars["BigInt"]["output"];
  netIncomeFromDiscontinuedOperations: Scalars["BigInt"]["output"];
  netMargin: Scalars["Float"]["output"];
  netRevenue: Scalars["BigInt"]["output"];
  payout: Scalars["BigInt"]["output"];
  payoutToNetIncomeRatio: Scalars["BigInt"]["output"];
  returnOnEquity: Scalars["Float"]["output"];
  startOfPeriod: Scalars["Date"]["output"];
};

export enum IBrazilianCompanyFinancialStatementsAggregation {
  Consolidated = "CONSOLIDATED",
  Individual = "INDIVIDUAL",
}

export type IBrazilianCompanyFinancials = {
  __typename: "BrazilianCompanyFinancials";
  quarterly: Array<IQuarterlyBrazilianCompanyFinancials>;
  template: IBrazilianCompanyFinancialsTemplate;
  yearly: Array<IYearlyBrazilianCompanyFinancials>;
};

export enum IBrazilianCompanyFinancialsTemplate {
  Bank = "BANK",
  Company = "COMPANY",
  Insurer = "INSURER",
  Unknown = "UNKNOWN",
}

export type IBrazilianCompanyIssuer = IIssuerLogos &
  IRankedIssuer & {
    __typename: "BrazilianCompanyIssuer";
    _id: Scalars["ObjectID"]["output"];
    analysisVideo: Maybe<IYouTubeVideo>;
    /** Is the current company auditor */
    auditor: Maybe<IBrazilianCompanyIssuerAuditor>;
    /**
     * All auditors as reported by the company. Some companies may remove old auditors from their reporting. It's also
     * known that some companies have 2 auditors or don't report dates at which some of them stopped rendering services
     */
    auditors: Array<IBrazilianCompanyIssuerAuditor>;
    /**
     * Is a snapshot of the company's participation on the specified B3 Market Index. Valid indexes are: IBOV, IBRX,
     * IBXL, IBRA, ITAG, IGCT, IGNM, IGCX, IFNC, BDRX, ICON, IEEX, IFIX, IMAT, IDIV, INDX, IMOB, MLCX, SMLL, UTIL, IVBX,
     * ICO2, ISEE
     */
    b3IndexParticipation: Maybe<IB3IndexParticipation>;
    /** Share bookkeeper agent. Usually a bank. This information is normalized internally */
    bookkeeper: Maybe<Scalars["String"]["output"]>;
    /**
     * Is the link pointing to the latest Brief History writing published by the company. It is a direct URL to
     * CVM's RAD PDF viewer
     */
    briefHistoryUrl: Scalars["URL"]["output"];
    /** A short/medium internally redacted description of the company and what it does */
    businessDescription: Scalars["String"]["output"];
    /**
     * The composition of the issuer's capital stock. Includes the total of shares and the specific amounts of Common and
     * Preferred shares
     */
    capitalStockComposition: Maybe<IBrazilianCompanyIssuerCapitalStockComposition>;
    /** Company's CEO. This information is pulled from the list of officers through position comparisons */
    chiefExecutiveOfficer: Maybe<IBrazilianCompanyIssuerOfficer>;
    /** Is the company's brazilian Taxpayer ID (Cadastro Nacional de Pessoas Jurídicas) */
    cnpj: Scalars["String"]["output"];
    /** A list of all available covers for this Issuer */
    covers: Array<ICover>;
    cvmCode: Scalars["String"]["output"];
    /** Is the date of which the issuer was initially registered on CVM */
    cvmRegistrationDate: Maybe<Scalars["Date"]["output"]>;
    /** Is the company incorporation/establishment date */
    dateOfIncorporation: Maybe<Scalars["Date"]["output"]>;
    /**
     * All debt obligation totals aggregated by fiscal year. The brackets included are the ones standardized and
     * reported by the issuer
     */
    debtObligations: Array<IBrazilianCompanyIssuerDebtObligation>;
    /**
     * Is the link pointing to the latest Description of the Issuer and its subsidiaries writing published by the
     * company. It is a direct URL to CVM's RAD PDF viewer
     */
    descriptionOfActivitiesUrl: Scalars["URL"]["output"];
    /** The most recent number of employees reported by the company */
    employeeNumbers: Maybe<IEmployeeNumbers>;
    engagementStats: IIssuerEngagementStats;
    equityInterests: Array<IBrazilianCompanyIssuerEquityInterest>;
    familyRelationships: Array<IBrazilianCompanyIssuerFamilyRelationship>;
    financials: IBrazilianCompanyFinancials;
    /** Will be either "STANDARD", "BANK" or "INSURANCE" */
    financialsTemplate: Maybe<IBrazilianCompanyIssuerFinancialsTemplate>;
    /**
     * It's a "open.spotify.com" embed URL pointing to the company's Finnancial Results Webcast uploaded by
     * Central de Resultados
     */
    finnacialResultsSpotifyWebcastUrl: Maybe<Scalars["URL"]["output"]>;
    /** Day and month of the company's Fiscal Year ending */
    fiscalYearEnd: IBrazilianCompanyIssuerFiscalYearEnd;
    /** Securities listed on markets other than Brazil. This also applies if the issuer is foreigner */
    foreignMarketSecurities: Array<IBrazilianCompanyIssuerForeignMarketSecurity>;
    /** Is the year extracted from the incorporation/establishment date */
    foundedYear: Maybe<Scalars["String"]["output"]>;
    /** The total of shares available for public trading */
    freeFloat: Maybe<IBrazilianCompanyIssuerFreeFloat>;
    /** Company's ratings at https://glassdoor.com */
    glassdoorRatings: Maybe<IGlassdoorRatings>;
    /** Company's page at https://glassdoor.com.br */
    glassdoorUrl: Maybe<Scalars["URL"]["output"]>;
    /** Headquarters details pulled from the company's most recent Registration form */
    headquarters: IBrazilianCompanyIssuerHeadquarters;
    /** The historical number of employees reported by the company */
    historicalEmployeeNumbers: Array<IEmployeeNumbers>;
    /** The industry sector object */
    industrySector: Maybe<IIndustrySector>;
    intangibleAssets: Array<IBrazilianCompanyIssuerIntangibleAsset>;
    /** A URL to the company's Investor Relations website */
    investorRelationsUrl: Maybe<Scalars["URL"]["output"]>;
    ipoDate: Maybe<Scalars["Date"]["output"]>;
    /** Indicates whether this Issuer is issues only a Depositary Receipt */
    isBrazilianDepositaryReceipt: Scalars["Boolean"]["output"];
    isInPortfolio: Scalars["Boolean"]["output"];
    /**
     * Is a description of all company Key customers. This information is only required for companies whose have at least
     * 10% of its revenue sourced from a few customers
     */
    keyCustomers: Maybe<IBrazilianCompanyIssuerKeyCustomers>;
    /**
     * Is the last financial highlights we've written for the company. Not all companies will have that information
     * due to relevance
     */
    lastFinancialHighlights: Maybe<IFinancialHighlights>;
    /** Is the management report PDF that is included in the last Quarterly (ITR) financials published */
    lastQuarterlyManagementReport: Maybe<IBrazilianIssuerManagementReport>;
    /** Is the management report PDF that is included in the last Yearly (DFP) financials published */
    lastYearlyManagementReport: Maybe<IBrazilianIssuerManagementReport>;
    /** Listing Segment determined by the currently listed securities */
    listingSegment: Maybe<IBrazilianCompanyIssuerListingSegment>;
    /**
     * Top 3 shareholders of the company. It pulls whoever owns the most amount of ON (Common Stock) shares and has at
     * least 1% of those shares
     */
    majorityShareholders: Array<IBrazilianCompanyIssuerShareholderPosition>;
    /** Wraps a summary of the "Mandatory minimum dividend" relevant data included in the Dividend Policy of the issuer */
    mandatoryMinimumDividend: Maybe<IBrazilianCompanyIssuerMandatoryMinimumDividend>;
    marketCapitalization: Maybe<IMarketCap>;
    /**
     * Is the most recent relevant Material Fact published by the company. Can be either categorized as "Fato Relevante"
     * or "Comunicado ao Mercado"
     */
    mostRecentRelevantMaterialFact: Maybe<ICvmMaterialFact>;
    /** Company's name */
    name: Scalars["String"]["output"];
    /** Is the next upcoming Calendar Event for this Issuer */
    nextUpcomingCalendarEvent: Maybe<IAgendaEvent>;
    niceLogo: Maybe<IIssuerLogo>;
    /** List of officers working for the company. All boards included */
    officers: Array<IBrazilianCompanyIssuerOfficer>;
    /**
     * Is the link pointing to the latest Operating Segments overview published by the company. It is a direct URL to
     * CVM's RAD PDF viewer
     */
    operatingSegmentsUrl: Scalars["URL"]["output"];
    otherSecuritiesIssued: Array<IBrazilianCompanyIssuerOtherSecurityIssued>;
    /** Is the position in the overall Site ranking */
    overallRankingPlacement: Maybe<IRankingPlacement>;
    /** Is the Rating in the overall Site ranking */
    overallRankingRating: Maybe<Scalars["Float"]["output"]>;
    /** Is the Rating Summary in the overall Site ranking */
    overallRankingRatingSummary: Maybe<IRatingSummary>;
    /**
     * Is the link pointing to the latest Ownership Structure writing published by the company. It is a direct URL to
     * CVM's RAD PDF viewer
     */
    ownershipStructureUrl: Scalars["URL"]["output"];
    /** Is a list of the most relevant shareholders. Usually it only includes holders with at least 5% of company shares */
    positionOfShareholders: Array<IBrazilianCompanyIssuerShareholderPosition>;
    primaryTickerSymbol: Scalars["TickerSymbol"]["output"];
    propertyPlantEquipment: Array<IBrazilianCompanyIssuerPropertyPlantEquipment>;
    quickAnalysis: Maybe<IQuickAnalysis>;
    /** Is the rating that the User has given (if any) */
    rating: Maybe<IViewerRating>;
    /** Company's ratings at https://reclameaqui.com.br */
    reclameAquiRatings: Maybe<IReclameAquiRatings>;
    /** Company's page at https://reclameaqui.com.br */
    reclameAquiUrl: Maybe<Scalars["URL"]["output"]>;
    /**
     * Is the link pointing to the latest Risk Factors PDF published by the company. It is a direct URL to CVM's RAD
     * PDF viewer
     */
    riskFactorsUrl: Maybe<Scalars["URL"]["output"]>;
    /** The industry sector name */
    sector: Scalars["String"]["output"];
    /** All listed securities tied to that company */
    securities: Array<IBrazilianSecurity>;
    /** Total shares outstanding (TSO). Also includes the number of individual, corporate and institutional shareholders */
    sharesOutstanding: Maybe<IBrazilianCompanyIssuerSharesOutstanding>;
    standardLogo: Maybe<IIssuerLogo>;
    /** Is a list of all subsidiaries of this issuer */
    subsidiaries: Array<IBrazilianCompanyIssuer>;
    /** Company's Tag Along rights for both Common, Preferred and Unit stock */
    tagAlong: Maybe<IBrazilianCompanyIssuerTagAlong>;
    tickerSymbolPrefix: Scalars["String"]["output"];
    /** Compensation totals aggregated by fiscal year. All boards included */
    totalCompensations: Array<IBrazilianCompanyIssuerTotalCompensations>;
    /** The total of shares held at treasury */
    treasuryShares: Maybe<IBrazilianCompanyIssuerTreasuryShares>;
    viewerDidBookmark: Scalars["Boolean"]["output"];
    /** A URL to the company's main website */
    website: Maybe<Scalars["URL"]["output"]>;
    youtubeVideos: Array<IYouTubeVideo>;
  };

export type IBrazilianCompanyIssuerB3IndexParticipationArgs = {
  marketIndexSymbol: Scalars["TickerSymbol"]["input"];
  maturity?: InputMaybe<IB3MarketIndexMaturity>;
};

export type IBrazilianCompanyIssuerMarketCapitalizationArgs = {
  tickerSymbol: InputMaybe<Scalars["TickerSymbol"]["input"]>;
};

export type IBrazilianCompanyIssuerMostRecentRelevantMaterialFactArgs = {
  deliveryDateStart: InputMaybe<Scalars["Date"]["input"]>;
};

export type IBrazilianCompanyIssuerOverallRankingPlacementArgs = {
  evaluationPeriod?: InputMaybe<IRatingEvaluationPeriod>;
};

export type IBrazilianCompanyIssuerOverallRankingRatingSummaryArgs = {
  evaluationPeriod?: InputMaybe<IRatingEvaluationPeriod>;
};

export type IBrazilianCompanyIssuerAuditor = {
  __typename: "BrazilianCompanyIssuerAuditor";
  cvmCode: Scalars["String"]["output"];
  dateSince: Maybe<Scalars["Date"]["output"]>;
  dateUntil: Maybe<Scalars["Date"]["output"]>;
  name: Scalars["String"]["output"];
};

export type IBrazilianCompanyIssuerCapitalStockComposition = {
  __typename: "BrazilianCompanyIssuerCapitalStockComposition";
  totalShares: Scalars["Float"]["output"];
  totalSharesOfCommonStock: Scalars["Float"]["output"];
  totalSharesOfPreferredStock: Scalars["Float"]["output"];
};

/** Is the total amount of debt owed by the company within the reported brackets */
export type IBrazilianCompanyIssuerDebtObligation = {
  __typename: "BrazilianCompanyIssuerDebtObligation";
  fiscalYearEnd: Scalars["Date"]["output"];
  fiscalYearStart: Scalars["Date"]["output"];
  lessThanOneYear: Scalars["Float"]["output"];
  moreThanFiveYears: Scalars["Float"]["output"];
  oneToThreeYears: Scalars["Float"]["output"];
  threeToFiveYears: Scalars["Float"]["output"];
  totalDebt: Scalars["Float"]["output"];
};

export type IBrazilianCompanyIssuerEquityInterest = {
  __typename: "BrazilianCompanyIssuerEquityInterest";
  acquisitionAndMaintenanceReason: Scalars["String"]["output"];
  bookValue: Scalars["Float"]["output"];
  country: Scalars["String"]["output"];
  dateOfBookValue: Maybe<Scalars["Date"]["output"]>;
  dateOfMarketValue: Maybe<Scalars["Date"]["output"]>;
  descriptionOfActivitiesDeveloped: Scalars["String"]["output"];
  geographicalCountry: Maybe<IGeographicalCountry>;
  marketValue: Scalars["Float"]["output"];
  name: Scalars["String"]["output"];
  participation: Scalars["Float"]["output"];
};

export type IBrazilianCompanyIssuerFamilyRelationship = {
  __typename: "BrazilianCompanyIssuerFamilyRelationship";
  issuerSubsidiaryOrController: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  position: Scalars["String"]["output"];
  relatedPersonIssuerSubsidiaryOrController: Scalars["String"]["output"];
  relatedPersonName: Scalars["String"]["output"];
  relatedPersonPosition: Scalars["String"]["output"];
  relationship: Scalars["String"]["output"];
};

export enum IBrazilianCompanyIssuerFinancialsTemplate {
  Bank = "BANK",
  Insurance = "INSURANCE",
  Standard = "STANDARD",
}

export type IBrazilianCompanyIssuerFiscalYearEnd = {
  __typename: "BrazilianCompanyIssuerFiscalYearEnd";
  day: Scalars["Int"]["output"];
  month: Scalars["Int"]["output"];
};

export type IBrazilianCompanyIssuerForeignMarketSecurity = {
  __typename: "BrazilianCompanyIssuerForeignMarketSecurity";
  country: Scalars["String"]["output"];
  custodian: Maybe<Scalars["String"]["output"]>;
  dateOfListing: Maybe<Scalars["Date"]["output"]>;
  depositoryBank: Maybe<Scalars["String"]["output"]>;
  description: Scalars["String"]["output"];
  entity: Scalars["String"]["output"];
  market: Scalars["String"]["output"];
  proportion: Maybe<Scalars["String"]["output"]>;
  security: Scalars["String"]["output"];
  tradingAdmissionDate: Maybe<Scalars["Date"]["output"]>;
  tradingSegment: Maybe<Scalars["String"]["output"]>;
  tradingVolume: Maybe<Scalars["Float"]["output"]>;
};

export type IBrazilianCompanyIssuerFreeFloat = IBrazilianCompanyIssuerSharesTotal & {
  __typename: "BrazilianCompanyIssuerFreeFloat";
  percentageOfTotalShares: Scalars["Float"]["output"];
  percentageOfTotalSharesOfCommonStock: Scalars["Float"]["output"];
  percentageOfTotalSharesOfPreferredStock: Scalars["Float"]["output"];
  totalShares: Scalars["Float"]["output"];
  totalSharesOfCommonStock: Scalars["Float"]["output"];
  totalSharesOfPreferredStock: Scalars["Float"]["output"];
};

export type IBrazilianCompanyIssuerHeadquarters = {
  __typename: "BrazilianCompanyIssuerHeadquarters";
  city: Scalars["String"]["output"];
  country: Scalars["String"]["output"];
  neighborhood: Scalars["String"]["output"];
  postalCode: Scalars["String"]["output"];
  state: Scalars["String"]["output"];
  street: Scalars["String"]["output"];
};

export type IBrazilianCompanyIssuerIntangibleAsset = {
  __typename: "BrazilianCompanyIssuerIntangibleAsset";
  description: Scalars["String"]["output"];
  eventsThatMayCauseLossOfRights: Scalars["String"]["output"];
  lossOfRightsConsequences: Scalars["String"]["output"];
  term: Scalars["String"]["output"];
  type: Scalars["String"]["output"];
};

export type IBrazilianCompanyIssuerKeyCustomers = {
  __typename: "BrazilianCompanyIssuerKeyCustomers";
  description: Scalars["String"]["output"];
  reportDate: Scalars["Date"]["output"];
  reportSource: Scalars["String"]["output"];
  reportSourceUrl: Scalars["URL"]["output"];
  yearOfReference: Scalars["Int"]["output"];
};

export enum IBrazilianCompanyIssuerListingSegment {
  /**
   * MA - Esse segmento permite efetuar a listagem sem oferta, ou seja, você pode listar a sua empresa na B3 e tem até 7
   * anos para realizar o IPO
   */
  BovespaMais = "BOVESPA_MAIS",
  /**
   * M2 - O segmento de listagem Bovespa Mais Nível 2 é similar ao Bovespa Mais, porém com algumas exceções. As empresas
   * listadas têm o direito de manter ações preferenciais (PN)
   */
  BovespaMaisNivel_2 = "BOVESPA_MAIS_NIVEL_2",
  /** MB - Mercado de Balcão é o ambiente em que são negociados títulos e ações fora da Bolsa de Valores */
  MercadoDeBalcao = "MERCADO_DE_BALCAO",
  /**
   * N1 - As empresas listadas no segmento Nível 1 devem adotar práticas que favoreçam a transparência e o acesso às
   * informações pelos investidores. Para isso, divulgam informações adicionais às exigidas em lei, como por exemplo,
   * um calendário anual de eventos corporativos. O free float mínimo de 25% deve ser mantido nesse segmento
   */
  Nivel_1 = "NIVEL_1",
  /**
   * N2 - O segmento de listagem Nível 2 é similar ao Novo Mercado, porém com algumas exceções. As empresas listadas têm
   * o direito de manter ações preferenciais (PN)
   */
  Nivel_2 = "NIVEL_2",
  /**
   * NM - O Novo Mercado conduz as empresas ao mais elevado padrão de governança corporativa. As empresas listadas nesse
   * segmento podem emitir apenas ações com direito de voto, as chamadas ações ordinárias (ON)
   */
  NovoMercado = "NOVO_MERCADO",
  /** Tradicional */
  Tradicional = "TRADICIONAL",
}

export type IBrazilianCompanyIssuerMandatoryMinimumDividend = {
  __typename: "BrazilianCompanyIssuerMandatoryMinimumDividend";
  commonPreferredDiscrepancy: Maybe<Scalars["String"]["output"]>;
  frequency: Maybe<Scalars["String"]["output"]>;
  minimumDividend: Scalars["String"]["output"];
  reportDate: Scalars["Date"]["output"];
  reportSource: Scalars["String"]["output"];
  reportSourceUrl: Scalars["URL"]["output"];
};

export type IBrazilianCompanyIssuerOfficer = {
  __typename: "BrazilianCompanyIssuerOfficer";
  board: Scalars["String"]["output"];
  dateOfBirth: Maybe<Scalars["Date"]["output"]>;
  effective: Maybe<Scalars["Date"]["output"]>;
  electedDate: Maybe<Scalars["Date"]["output"]>;
  ellectedByControllingShareholder: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
  numberOfConsecutiveTerms: Scalars["Int"]["output"];
  occupation: Scalars["String"]["output"];
  otherPositionOrDuties: Maybe<Scalars["String"]["output"]>;
  position: Scalars["String"]["output"];
  termOfOffice: Scalars["String"]["output"];
};

export type IBrazilianCompanyIssuerOperatingSegments = {
  __typename: "BrazilianCompanyIssuerOperatingSegments";
  fiscalYear: Scalars["Int"]["output"];
  reportDate: Scalars["Date"]["output"];
  reportSource: Scalars["String"]["output"];
  reportSourceUrl: Scalars["URL"]["output"];
  revenueSources: Array<IBrazilianCompanyIssuerRevenueSource>;
};

export type IBrazilianCompanyIssuerOtherSecurityIssued = {
  __typename: "BrazilianCompanyIssuerOtherSecurityIssued";
  debtBalanceOutstanding: Scalars["Float"]["output"];
  description: Scalars["String"]["output"];
  issueDate: Maybe<Scalars["Date"]["output"]>;
  maturity: Maybe<Scalars["Date"]["output"]>;
  quantity: Scalars["Float"]["output"];
  type: Scalars["String"]["output"];
};

export type IBrazilianCompanyIssuerPropertyPlantEquipment = {
  __typename: "BrazilianCompanyIssuerPropertyPlantEquipment";
  city: Maybe<Scalars["String"]["output"]>;
  country: Scalars["String"]["output"];
  description: Scalars["String"]["output"];
  geographicalCountry: Maybe<IGeographicalCountry>;
  state: Maybe<Scalars["String"]["output"]>;
  typeOfProperty: Scalars["String"]["output"];
};

export type IBrazilianCompanyIssuerRevenueSource = {
  __typename: "BrazilianCompanyIssuerRevenueSource";
  /** Is the name/description of the source of revenue */
  description: Scalars["String"]["output"];
  /** Is the percentage that this source represents in the Total Revenue/Net Revenue */
  proportion: Scalars["Float"]["output"];
};

export type IBrazilianCompanyIssuerShareholderPosition = IBrazilianCompanyIssuerSharesTotal & {
  __typename: "BrazilianCompanyIssuerShareholderPosition";
  isControllingShareholder: Scalars["Boolean"]["output"];
  /** Indicates if the shareholder is a Government entity or Government-related entity */
  isGovernmentOrGovernmentRelated: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
  nationality: Scalars["String"]["output"];
  percentageOfTotalShares: Scalars["Float"]["output"];
  percentageOfTotalSharesOfCommonStock: Scalars["Float"]["output"];
  percentageOfTotalSharesOfPreferredStock: Scalars["Float"]["output"];
  totalShares: Scalars["Float"]["output"];
  totalSharesOfCommonStock: Scalars["Float"]["output"];
  totalSharesOfPreferredStock: Scalars["Float"]["output"];
  type: IBrazilianCompanyIssuerShareholderType;
};

export enum IBrazilianCompanyIssuerShareholderType {
  Corporation = "CORPORATION",
  Individual = "INDIVIDUAL",
  Other = "OTHER",
}

export type IBrazilianCompanyIssuerSharesOutstanding = IBrazilianCompanyIssuerSharesTotal & {
  __typename: "BrazilianCompanyIssuerSharesOutstanding";
  lastShareholdersMeeting: Maybe<Scalars["Date"]["output"]>;
  percentageOfTotalShares: Scalars["Float"]["output"];
  percentageOfTotalSharesOfCommonStock: Scalars["Float"]["output"];
  percentageOfTotalSharesOfPreferredStock: Scalars["Float"]["output"];
  totalOfCorporateShareholders: Scalars["Float"]["output"];
  totalOfIndividualShareholders: Scalars["Float"]["output"];
  totalOfInstitutionalShareholders: Scalars["Float"]["output"];
  totalShares: Scalars["Float"]["output"];
  totalSharesOfCommonStock: Scalars["Float"]["output"];
  totalSharesOfPreferredStock: Scalars["Float"]["output"];
};

export type IBrazilianCompanyIssuerSharesTotal = {
  percentageOfTotalShares: Scalars["Float"]["output"];
  percentageOfTotalSharesOfCommonStock: Scalars["Float"]["output"];
  percentageOfTotalSharesOfPreferredStock: Scalars["Float"]["output"];
  totalShares: Scalars["Float"]["output"];
  totalSharesOfCommonStock: Scalars["Float"]["output"];
  totalSharesOfPreferredStock: Scalars["Float"]["output"];
};

export type IBrazilianCompanyIssuerTagAlong = {
  __typename: "BrazilianCompanyIssuerTagAlong";
  commonStock: Maybe<Scalars["Float"]["output"]>;
  preferredStock: Maybe<Scalars["Float"]["output"]>;
  unitStock: Maybe<Scalars["Float"]["output"]>;
};

/** Is the total compensation amounts paid to all company' boards within the reporting period */
export type IBrazilianCompanyIssuerTotalCompensations = {
  __typename: "BrazilianCompanyIssuerTotalCompensations";
  boardOfDirectors: Scalars["Float"]["output"];
  boardOfExecutiveOfficers: Scalars["Float"]["output"];
  fiscalCouncil: Scalars["Float"]["output"];
  fiscalYearEnd: Scalars["Date"]["output"];
  fiscalYearStart: Scalars["Date"]["output"];
  totalCompensation: Scalars["Float"]["output"];
};

export type IBrazilianCompanyIssuerTreasuryShares = IBrazilianCompanyIssuerSharesTotal & {
  __typename: "BrazilianCompanyIssuerTreasuryShares";
  percentageOfTotalShares: Scalars["Float"]["output"];
  percentageOfTotalSharesOfCommonStock: Scalars["Float"]["output"];
  percentageOfTotalSharesOfPreferredStock: Scalars["Float"]["output"];
  totalShares: Scalars["Float"]["output"];
  totalSharesOfCommonStock: Scalars["Float"]["output"];
  totalSharesOfPreferredStock: Scalars["Float"]["output"];
};

export type IBrazilianCompanyLiquidity = {
  __typename: "BrazilianCompanyLiquidity";
  sharesOutstanding: Maybe<IBrazilianCompanySharesOutstanding>;
  speculativeContent: Array<ISpeculativeContent>;
  totalOfShares: Maybe<Scalars["BigInt"]["output"]>;
};

export type IBrazilianCompanyPortfolioAsset = {
  __typename: "BrazilianCompanyPortfolioAsset";
  industrySegment: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  primaryLogo: Maybe<Scalars["String"]["output"]>;
  tickerSymbolPrefix: Scalars["String"]["output"];
};

export type IBrazilianCompanyQuarterlyFinancialsInput = {
  capex: Scalars["BigInt"]["input"];
  cash: Scalars["BigInt"]["input"];
  cashFlowForDividends: Scalars["BigInt"]["input"];
  cashFlowFromFinancing: Scalars["BigInt"]["input"];
  cashFlowFromOperations: Scalars["BigInt"]["input"];
  debt: Scalars["BigInt"]["input"];
  depreciationAndAmortization: Scalars["BigInt"]["input"];
  ebit: Scalars["BigInt"]["input"];
  endOfPeriod: Scalars["DateTime"]["input"];
  financialResult: Scalars["BigInt"]["input"];
  incomeTaxes: Scalars["BigInt"]["input"];
  netEquity: Scalars["BigInt"]["input"];
  netIncome: Scalars["BigInt"]["input"];
  netIncomeFromDiscontinuedOperations: Scalars["BigInt"]["input"];
  netRevenue: Scalars["BigInt"]["input"];
  startOfPeriod: Scalars["DateTime"]["input"];
};

export type IBrazilianCompanySharesOutstanding = {
  __typename: "BrazilianCompanySharesOutstanding";
  fiscalYear: Scalars["Date"]["output"];
  percentOfShares: Scalars["Float"]["output"];
  totalOfSharesOutstanding: Scalars["BigInt"]["output"];
};

export type IBrazilianCompanyYearlyFinancialsInput = {
  capex: Scalars["BigInt"]["input"];
  cash: Scalars["BigInt"]["input"];
  cashFlowForDividends: Scalars["BigInt"]["input"];
  cashFlowFromFinancing: Scalars["BigInt"]["input"];
  cashFlowFromOperations: Scalars["BigInt"]["input"];
  debt: Scalars["BigInt"]["input"];
  depreciationAndAmortization: Scalars["BigInt"]["input"];
  ebit: Scalars["BigInt"]["input"];
  endOfPeriod: Scalars["DateTime"]["input"];
  financialResult: Scalars["BigInt"]["input"];
  incomeTaxes: Scalars["BigInt"]["input"];
  netEquity: Scalars["BigInt"]["input"];
  netIncome: Scalars["BigInt"]["input"];
  netIncomeFromDiscontinuedOperations: Scalars["BigInt"]["input"];
  netRevenue: Scalars["BigInt"]["input"];
  startOfPeriod: Scalars["DateTime"]["input"];
};

export type IBrazilianInsurerFinancialStatementRow = {
  capex: Scalars["BigInt"]["output"];
  capexToCashFlowFromOperationsRatio: Scalars["Float"]["output"];
  cash: Scalars["BigInt"]["output"];
  cashFlowForDividends: Scalars["BigInt"]["output"];
  cashFlowForDividendsToNetIncomeRatio: Scalars["Float"]["output"];
  cashFlowFromFinancing: Scalars["BigInt"]["output"];
  cashFlowFromOperations: Scalars["BigInt"]["output"];
  debt: Scalars["BigInt"]["output"];
  debtToNetEquityRatio: Scalars["Float"]["output"];
  depreciationAndAmortization: Scalars["BigInt"]["output"];
  ebit: Scalars["BigInt"]["output"];
  ebitToNetRevenueRatio: Scalars["Float"]["output"];
  ebitda: Scalars["BigInt"]["output"];
  endOfPeriod: Scalars["Date"]["output"];
  financialResult: Scalars["BigInt"]["output"];
  fiscalYear: Scalars["String"]["output"];
  freeCashFlow: Scalars["BigInt"]["output"];
  incomeTaxes: Scalars["BigInt"]["output"];
  incomeTaxesToTaxableProfitRatio: Scalars["BigInt"]["output"];
  netDebt: Scalars["BigInt"]["output"];
  netDebtToEbitdaRatio: Scalars["Float"]["output"];
  netEquity: Scalars["BigInt"]["output"];
  netIncome: Scalars["BigInt"]["output"];
  netIncomeFromDiscontinuedOperations: Scalars["BigInt"]["output"];
  netMargin: Scalars["Float"]["output"];
  netRevenue: Scalars["BigInt"]["output"];
  payout: Scalars["BigInt"]["output"];
  payoutToNetIncomeRatio: Scalars["BigInt"]["output"];
  returnOnEquity: Scalars["Float"]["output"];
  startOfPeriod: Scalars["Date"]["output"];
};

export type IBrazilianInsurerQuarterlyFinancialsInput = {
  capex: Scalars["BigInt"]["input"];
  cash: Scalars["BigInt"]["input"];
  cashFlowForDividends: Scalars["BigInt"]["input"];
  cashFlowFromFinancing: Scalars["BigInt"]["input"];
  cashFlowFromOperations: Scalars["BigInt"]["input"];
  debt: Scalars["BigInt"]["input"];
  depreciationAndAmortization: Scalars["BigInt"]["input"];
  ebit: Scalars["BigInt"]["input"];
  endOfPeriod: Scalars["DateTime"]["input"];
  financialResult: Scalars["BigInt"]["input"];
  incomeTaxes: Scalars["BigInt"]["input"];
  netEquity: Scalars["BigInt"]["input"];
  netIncome: Scalars["BigInt"]["input"];
  netIncomeFromDiscontinuedOperations: Scalars["BigInt"]["input"];
  netRevenue: Scalars["BigInt"]["input"];
  startOfPeriod: Scalars["DateTime"]["input"];
};

export type IBrazilianInsurerYearlyFinancialsInput = {
  capex: Scalars["BigInt"]["input"];
  cash: Scalars["BigInt"]["input"];
  cashFlowForDividends: Scalars["BigInt"]["input"];
  cashFlowFromFinancing: Scalars["BigInt"]["input"];
  cashFlowFromOperations: Scalars["BigInt"]["input"];
  debt: Scalars["BigInt"]["input"];
  depreciationAndAmortization: Scalars["BigInt"]["input"];
  ebit: Scalars["BigInt"]["input"];
  endOfPeriod: Scalars["DateTime"]["input"];
  financialResult: Scalars["BigInt"]["input"];
  incomeTaxes: Scalars["BigInt"]["input"];
  netEquity: Scalars["BigInt"]["input"];
  netIncome: Scalars["BigInt"]["input"];
  netIncomeFromDiscontinuedOperations: Scalars["BigInt"]["input"];
  netRevenue: Scalars["BigInt"]["input"];
  startOfPeriod: Scalars["DateTime"]["input"];
};

export type IBrazilianIssuerManagementReport = {
  __typename: "BrazilianIssuerManagementReport";
  dateOfReference: Scalars["Date"]["output"];
  deliveryDate: Scalars["Date"]["output"];
  pdfURL: Scalars["URL"]["output"];
  type: Maybe<Scalars["String"]["output"]>;
};

export type IBrazilianRealEstateFund = IIssuerLogos &
  IRankedIssuer & {
    __typename: "BrazilianRealEstateFund";
    _id: Scalars["ObjectID"]["output"];
    administrator: IBrazilianRealEstateFundAdministrator;
    analysisVideo: Maybe<IYouTubeVideo>;
    audience: Scalars["String"]["output"];
    b3Href: Scalars["String"]["output"];
    classification: Array<Scalars["String"]["output"]>;
    cnpj: Scalars["String"]["output"];
    /** A list of all available covers for this Issuer */
    covers: Array<ICover>;
    dateOfDuration: Maybe<Scalars["Date"]["output"]>;
    distributionOfAssets: Maybe<IBrazilianRealEstateFundDistributionOfAssets>;
    engagementStats: IIssuerEngagementStats;
    financials: IBrazilianRealEstateFundFinancials;
    fundosNetHref: Scalars["String"]["output"];
    gallery: Array<IBrazilianRealEstateFundGalleryPicture>;
    ifixParticipation: Maybe<IIfixParticipation>;
    investmentsProgram: Scalars["String"]["output"];
    isFiagro: Maybe<Scalars["Boolean"]["output"]>;
    isInPortfolio: Scalars["Boolean"]["output"];
    lastMonthlyReportDate: Maybe<Scalars["Date"]["output"]>;
    lastQuarterlyReportDate: Maybe<Scalars["Date"]["output"]>;
    lastYearlyReportDate: Maybe<Scalars["Date"]["output"]>;
    listingSegment: Scalars["String"]["output"];
    managementReport: Maybe<IBrazilianRealEstateFundFnetDocument>;
    manager: Maybe<IBrazilianRealEstateFundManager>;
    marketCapitalization: Maybe<IMarketCap>;
    name: Scalars["String"]["output"];
    niceLogo: Maybe<IIssuerLogo>;
    openGraphLogoUrl: Maybe<Scalars["String"]["output"]>;
    /** Is the position in the overall Site ranking */
    overallRankingPlacement: Maybe<IRankingPlacement>;
    /** Is the Rating in the overall Site ranking */
    overallRankingRating: Maybe<Scalars["Float"]["output"]>;
    /** Is the Rating Summary in the overall Site ranking */
    overallRankingRatingSummary: Maybe<IRatingSummary>;
    overview: IBrazilianRealEstateFundOverview;
    primaryLogo: Maybe<IBrazilianRealEstateFundLogo>;
    properties: Array<IBrazilianRealEstateFundRealProperty>;
    /** Is the rating that the User has given (if any) */
    rating: Maybe<IViewerRating>;
    selfRegulation: IBrazilianRealEstateFundSelfRegulation;
    shortDescription: Scalars["String"]["output"];
    standardLogo: Maybe<IIssuerLogo>;
    startOfOperation: Scalars["String"]["output"];
    tickerSymbol: Scalars["TickerSymbol"]["output"];
    tickerSymbolPrefix: Scalars["String"]["output"];
    tradingName: Scalars["String"]["output"];
    trailingReturns: Maybe<ITrailingReturns>;
    viewerDidBookmark: Scalars["Boolean"]["output"];
    website: Maybe<Scalars["URL"]["output"]>;
    youtubeVideos: Array<IYouTubeVideo>;
  };

export type IBrazilianRealEstateFundCnpjArgs = {
  humanReadable: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type IBrazilianRealEstateFundOverallRankingPlacementArgs = {
  evaluationPeriod?: InputMaybe<IRatingEvaluationPeriod>;
};

export type IBrazilianRealEstateFundOverallRankingRatingSummaryArgs = {
  evaluationPeriod?: InputMaybe<IRatingEvaluationPeriod>;
};

export type IBrazilianRealEstateFundStartOfOperationArgs = {
  humanReadable: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type IBrazilianRealEstateFundAdministrator = {
  __typename: "BrazilianRealEstateFundAdministrator";
  cnpj: Scalars["String"]["output"];
  compensationPolicy: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
};

export type IBrazilianRealEstateFundAdministratorCnpjArgs = {
  humanReadable: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type IBrazilianRealEstateFundDistributionOfAssets = {
  __typename: "BrazilianRealEstateFundDistributionOfAssets";
  cash: Scalars["Float"]["output"];
  dateOfReference: Scalars["Date"]["output"];
  fundsOfFunds: Scalars["Float"]["output"];
  otherInvestments: Scalars["Float"]["output"];
  realEstate: Scalars["Float"]["output"];
};

export type IBrazilianRealEstateFundFinancialStatementRow = {
  capitalWithLiquidity: Scalars["Float"]["output"];
  cdi: Scalars["Float"]["output"];
  distributedIncome: Scalars["Float"]["output"];
  dividendYield: Scalars["Float"]["output"];
  dividendYieldToCdiRatio: Scalars["Float"]["output"];
  equityValuePerQuota: Scalars["Float"]["output"];
  financialExpenses: Scalars["Float"]["output"];
  financialRevenue: Scalars["Float"]["output"];
  leverage: Scalars["Float"]["output"];
  netEquity: Scalars["Float"]["output"];
  netTotalFinancialFlow: Scalars["Float"]["output"];
  otherCosts: Scalars["Float"]["output"];
  performanceAndAdministrationTaxes: Scalars["Float"]["output"];
  totalAssets: Scalars["Float"]["output"];
  totalFinancialFlow: Scalars["Float"]["output"];
  totalFromOthers: Scalars["Float"]["output"];
  totalFromRents: Scalars["Float"]["output"];
  totalInvested: Scalars["Float"]["output"];
  totalLiabilities: Scalars["Float"]["output"];
  totalOfFundsOfFunds: Scalars["Float"]["output"];
  totalOfOtherInvestments: Scalars["Float"]["output"];
  totalOfQuotaholders: Scalars["Int"]["output"];
  totalOfQuotasIssued: Scalars["Int"]["output"];
  totalValueOfRealEstate: Scalars["Float"]["output"];
  yieldPerQuota: Scalars["Float"]["output"];
};

export type IBrazilianRealEstateFundFinancials = {
  __typename: "BrazilianRealEstateFundFinancials";
  monthly: Array<IMonthlyBrazilianRealEstateFundFinancialStatementRow>;
};

export type IBrazilianRealEstateFundFnetDocument = {
  __typename: "BrazilianRealEstateFundFnetDocument";
  category: Scalars["String"]["output"];
  dateOfDelivery: Scalars["Date"]["output"];
  dateOfReference: Scalars["Date"]["output"];
  linkHref: Scalars["String"]["output"];
  type: Scalars["String"]["output"];
};

export type IBrazilianRealEstateFundGalleryPicture = {
  __typename: "BrazilianRealEstateFundGalleryPicture";
  alternativeText: Maybe<Scalars["String"]["output"]>;
  colorPalette: Maybe<Array<Scalars["String"]["output"]>>;
  dominantColor: Maybe<Scalars["String"]["output"]>;
  height: Scalars["Int"]["output"];
  url: Scalars["String"]["output"];
  width: Scalars["Int"]["output"];
};

export type IBrazilianRealEstateFundGalleryPictureUrlArgs = {
  height: InputMaybe<Scalars["Int"]["input"]>;
  width: InputMaybe<Scalars["Int"]["input"]>;
};

export type IBrazilianRealEstateFundIssuer = IIssuerLogos &
  IRankedIssuer & {
    __typename: "BrazilianRealEstateFundIssuer";
    _id: Scalars["ObjectID"]["output"];
    cnpj: Scalars["String"]["output"];
    engagementStats: IIssuerEngagementStats;
    isInPortfolio: Scalars["Boolean"]["output"];
    marketCapitalization: Maybe<IMarketCap>;
    name: Scalars["String"]["output"];
    niceLogo: Maybe<IIssuerLogo>;
    /** Is the position in the overall Site ranking */
    overallRankingPlacement: Maybe<IRankingPlacement>;
    /** Is the Rating in the overall Site ranking */
    overallRankingRating: Maybe<Scalars["Float"]["output"]>;
    /** Is the Rating Summary in the overall Site ranking */
    overallRankingRatingSummary: Maybe<IRatingSummary>;
    /** Is the rating that the User has given (if any) */
    rating: Maybe<IViewerRating>;
    sector: Scalars["String"]["output"];
    shortDescription: Scalars["String"]["output"];
    standardLogo: Maybe<IIssuerLogo>;
    tickerSymbolPrefix: Scalars["String"]["output"];
    trailingReturns: Maybe<ITrailingReturns>;
    viewerDidBookmark: Scalars["Boolean"]["output"];
  };

export type IBrazilianRealEstateFundIssuerOverallRankingPlacementArgs = {
  evaluationPeriod?: InputMaybe<IRatingEvaluationPeriod>;
};

export type IBrazilianRealEstateFundIssuerOverallRankingRatingSummaryArgs = {
  evaluationPeriod?: InputMaybe<IRatingEvaluationPeriod>;
};

export type IBrazilianRealEstateFundLand = {
  __typename: "BrazilianRealEstateFundLand";
  address: Scalars["String"]["output"];
  area: Scalars["Float"]["output"];
  otherRelevantInformation: Scalars["String"]["output"];
};

export type IBrazilianRealEstateFundLiquidity = {
  __typename: "BrazilianRealEstateFundLiquidity";
  speculativeContent: Array<ISpeculativeContent>;
  totalOfQuotaholders: Maybe<Scalars["BigInt"]["output"]>;
  totalOfQuotas: Maybe<Scalars["BigInt"]["output"]>;
};

export type IBrazilianRealEstateFundLogo = {
  __typename: "BrazilianRealEstateFundLogo";
  colorPalette: Maybe<Array<Scalars["String"]["output"]>>;
  dominantColor: Maybe<Scalars["String"]["output"]>;
  height: Scalars["Int"]["output"];
  url: Scalars["String"]["output"];
  width: Scalars["Int"]["output"];
};

export type IBrazilianRealEstateFundLogoUrlArgs = {
  height: InputMaybe<Scalars["Int"]["input"]>;
  width: InputMaybe<Scalars["Int"]["input"]>;
};

export type IBrazilianRealEstateFundManager = {
  __typename: "BrazilianRealEstateFundManager";
  cnpj: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
};

export type IBrazilianRealEstateFundManagerCnpjArgs = {
  humanReadable: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type IBrazilianRealEstateFundOverview = {
  __typename: "BrazilianRealEstateFundOverview";
  totalOfCities: Scalars["Int"]["output"];
  totalOfGrossLeaseableArea: Scalars["Float"]["output"];
  totalOfLands: Scalars["Int"]["output"];
  totalOfRealEstateProperties: Scalars["Int"]["output"];
  totalOfRegions: Scalars["Int"]["output"];
  totalOfSecurities: Scalars["Int"]["output"];
  totalOfStates: Scalars["Int"]["output"];
  totalOfTenants: Scalars["Int"]["output"];
};

export type IBrazilianRealEstateFundPortfolioAsset = {
  __typename: "BrazilianRealEstateFundPortfolioAsset";
  name: Scalars["String"]["output"];
  operatingSegment: Scalars["String"]["output"];
  primaryLogo: Maybe<Scalars["String"]["output"]>;
  tickerSymbolPrefix: Scalars["String"]["output"];
};

export type IBrazilianRealEstateFundProperty = {
  address: Scalars["String"]["output"];
  area: Scalars["Float"]["output"];
  id: Scalars["String"]["output"];
  location: Maybe<IBrazilianRealEstateFundPropertyLocation>;
  name: Scalars["String"]["output"];
  units: Scalars["Int"]["output"];
};

export type IBrazilianRealEstateFundPropertyLocation = {
  __typename: "BrazilianRealEstateFundPropertyLocation";
  abbreviatedStateName: Maybe<Scalars["String"]["output"]>;
  address: Maybe<Scalars["String"]["output"]>;
  city: Maybe<Scalars["String"]["output"]>;
  country: Maybe<Scalars["String"]["output"]>;
  formatted: Scalars["String"]["output"];
  neighborhood: Maybe<Scalars["String"]["output"]>;
  number: Maybe<Scalars["String"]["output"]>;
  postalCode: Maybe<Scalars["String"]["output"]>;
  state: Maybe<Scalars["String"]["output"]>;
};

export type IBrazilianRealEstateFundPropertyToRent = IBrazilianRealEstateFundProperty & {
  __typename: "BrazilianRealEstateFundPropertyToRent";
  address: Scalars["String"]["output"];
  area: Scalars["Float"]["output"];
  delinquencyRate: Maybe<Scalars["Float"]["output"]>;
  id: Scalars["String"]["output"];
  location: Maybe<IBrazilianRealEstateFundPropertyLocation>;
  name: Scalars["String"]["output"];
  units: Scalars["Int"]["output"];
  vacancyRate: Maybe<Scalars["Float"]["output"]>;
};

export type IBrazilianRealEstateFundPropertyToRentUnderConstruction = IBrazilianRealEstateFundProperty & {
  __typename: "BrazilianRealEstateFundPropertyToRentUnderConstruction";
  address: Scalars["String"]["output"];
  area: Scalars["Float"]["output"];
  id: Scalars["String"]["output"];
  location: Maybe<IBrazilianRealEstateFundPropertyLocation>;
  name: Scalars["String"]["output"];
  units: Scalars["Int"]["output"];
};

export type IBrazilianRealEstateFundPropertyToSale = IBrazilianRealEstateFundProperty & {
  __typename: "BrazilianRealEstateFundPropertyToSale";
  address: Scalars["String"]["output"];
  area: Scalars["Float"]["output"];
  id: Scalars["String"]["output"];
  location: Maybe<IBrazilianRealEstateFundPropertyLocation>;
  name: Scalars["String"]["output"];
  units: Scalars["Int"]["output"];
};

export type IBrazilianRealEstateFundPropertyToSaleUnderConstruction = IBrazilianRealEstateFundProperty & {
  __typename: "BrazilianRealEstateFundPropertyToSaleUnderConstruction";
  address: Scalars["String"]["output"];
  area: Scalars["Float"]["output"];
  id: Scalars["String"]["output"];
  location: Maybe<IBrazilianRealEstateFundPropertyLocation>;
  name: Scalars["String"]["output"];
  units: Scalars["Int"]["output"];
};

export type IBrazilianRealEstateFundRealProperty =
  | IBrazilianRealEstateFundLand
  | IBrazilianRealEstateFundPropertyToRent
  | IBrazilianRealEstateFundPropertyToRentUnderConstruction
  | IBrazilianRealEstateFundPropertyToSale
  | IBrazilianRealEstateFundPropertyToSaleUnderConstruction;

export type IBrazilianRealEstateFundSelfRegulation = {
  __typename: "BrazilianRealEstateFundSelfRegulation";
  management: Scalars["String"]["output"];
  mandate: Scalars["String"]["output"];
  primarySegment: Scalars["String"]["output"];
};

export type IBrazilianSecurity = ISecurity & {
  __typename: "BrazilianSecurity";
  _id: Scalars["ObjectID"]["output"];
  category: ISecurityCategory;
  corporateGovernanceLevel: Scalars["String"]["output"];
  hasQuotes: Scalars["Boolean"]["output"];
  isin: Scalars["String"]["output"];
  issuer: Maybe<IIssuer>;
  name: Scalars["String"]["output"];
  sharesOutstanding: Scalars["BigInt"]["output"];
  tickerSymbol: Scalars["TickerSymbol"]["output"];
  trailingReturns: Maybe<ITrailingReturns>;
  type: IGlobalSecurityType;
};

export enum ICacheControlScope {
  Private = "PRIVATE",
  Public = "PUBLIC",
}

export type ICaixaPretaPurchaseReceipt = {
  __typename: "CaixaPretaPurchaseReceipt";
  approvedDate: Maybe<Scalars["DateTime"]["output"]>;
  orderDate: Scalars["DateTime"]["output"];
  status: Scalars["String"]["output"];
  transactionId: Scalars["ID"]["output"];
  warrantyExpireDate: Maybe<Scalars["DateTime"]["output"]>;
};

export type IChangeFinancialsTemplateVisibilityInput = {
  visibility: IFinancialsTemplateVisibility;
};

export type ICheckoutParametersInput = {
  cancelResourcePath: InputMaybe<Scalars["String"]["input"]>;
  locale: InputMaybe<Scalars["String"]["input"]>;
  successResourcePath: InputMaybe<Scalars["String"]["input"]>;
};

export enum ICheckoutProduct {
  FundamenteiBlackPremiumDowngrade = "FUNDAMENTEI_BLACK_PREMIUM_DOWNGRADE",
  FundamenteiBlackRenewal = "FUNDAMENTEI_BLACK_RENEWAL",
  MonthlyRecurringSubscription = "MONTHLY_RECURRING_SUBSCRIPTION",
  OneTimeFundamenteiBlack = "ONE_TIME_FUNDAMENTEI_BLACK",
  YearlyRecurringSubscription = "YEARLY_RECURRING_SUBSCRIPTION",
}

export type ICloudflareBlackVideo = IFundamenteiMedia & {
  __typename: "CloudflareBlackVideo";
  chapters: Array<IVideoChapter>;
  chaptersAsWebVTT: Maybe<Scalars["String"]["output"]>;
  durationInSeconds: Scalars["Int"]["output"];
  mediaType: IFundamenteiMediaType;
  objectId: Scalars["ID"]["output"];
  playbackUrls: ICloudflareBlackVideoPlaybackUrLs;
  posterUrls: ICloudflareBlackVideoPosterUrLs;
  thumbnailsAsWebVTT: Maybe<Scalars["String"]["output"]>;
  viewerPlaybackState: Maybe<IVideoPlaybackStateDetails>;
};

export type ICloudflareBlackVideoPosterUrlsArgs = {
  fit?: InputMaybe<Scalars["String"]["input"]>;
  timeInSeconds?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ICloudflareBlackVideoThumbnailsAsWebVttArgs = {
  frequency: InputMaybe<Scalars["Int"]["input"]>;
};

export type ICloudflareBlackVideoPlaybackUrLs = {
  __typename: "CloudflareBlackVideoPlaybackURLs";
  dash: Scalars["URL"]["output"];
  hls: Scalars["URL"]["output"];
  preview: Scalars["URL"]["output"];
};

export type ICloudflareBlackVideoPosterUrLs = {
  __typename: "CloudflareBlackVideoPosterURLs";
  default: Scalars["URL"]["output"];
  high: Scalars["URL"]["output"];
  maxres: Scalars["URL"]["output"];
  medium: Scalars["URL"]["output"];
  standard: Scalars["URL"]["output"];
};

export type IComment = {
  _id: Scalars["ObjectID"]["output"];
  author: IUser;
  bodyAsPlainText: Scalars["String"]["output"];
  postedAt: Scalars["DateTime"]["output"];
  shortId: Scalars["ID"]["output"];
  viewerCanDestroy: Scalars["Boolean"]["output"];
  viewerCanEdit: Scalars["Boolean"]["output"];
  viewerDidAuthor: Scalars["Boolean"]["output"];
};

export type ICommentContentEdit = {
  __typename: "CommentContentEdit";
  bodyAsPlainText: Scalars["String"]["output"];
  editedAt: Scalars["DateTime"]["output"];
  editor: IUser;
};

export type ICommonPost = IEditablePost &
  IPost &
  IReactable &
  IUniformResourceLocatable & {
    __typename: "CommonPost";
    _id: Scalars["ObjectID"]["output"];
    author: IUser;
    bodyAsJson: Scalars["JSON"]["output"];
    bodyAsPlainText: Scalars["String"]["output"];
    contextKey: Maybe<Scalars["String"]["output"]>;
    contextType: IPostContextType;
    createdAt: Scalars["DateTime"]["output"];
    editHistory: Array<IPostContentEdit>;
    lastRepliedAt: Maybe<Scalars["DateTime"]["output"]>;
    objectId: Scalars["ID"]["output"];
    originalBodyAsPlainText: Scalars["String"]["output"];
    participants: IPostParticipants;
    reactionGroups: Array<IReactionGroup>;
    replies: IPostRepliesPagination;
    resourcePath: Scalars["String"]["output"];
    shortId: Scalars["ID"]["output"];
    title: Scalars["String"]["output"];
    updatedAt: Maybe<Scalars["DateTime"]["output"]>;
    url: Scalars["URL"]["output"];
    viewerCanDestroy: Scalars["Boolean"]["output"];
    viewerCanEdit: Scalars["Boolean"]["output"];
    viewerCanReact: Scalars["Boolean"]["output"];
    viewerCanReply: Scalars["Boolean"]["output"];
    viewerCanViewReplies: Scalars["Boolean"]["output"];
    viewerDidAuthor: Scalars["Boolean"]["output"];
  };

export type ICommonPostParticipantsArgs = {
  top: Scalars["Int"]["input"];
};

export type ICommonPostRepliesArgs = {
  cursor: InputMaybe<Scalars["Cursor"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy: InputMaybe<IPostOrder>;
};

export type ICompanyIssuer = INode & {
  __typename: "CompanyIssuer";
  _id: Scalars["ObjectID"]["output"];
  country: ISecurityCountry;
  description: Maybe<Scalars["String"]["output"]>;
  descriptionTranslations: Array<ITranslatedTextContent>;
  exchangeId: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  niceLogo: Maybe<IIssuerLogo>;
  sector: Maybe<Scalars["String"]["output"]>;
  standardLogo: Maybe<IIssuerLogo>;
  tickerSymbol: Scalars["TickerSymbol"]["output"];
  vertical: IPlatformVertical;
};

export type ICompanyIssuerDescriptionArgs = {
  language: InputMaybe<Scalars["ISO6391LanguageCode"]["input"]>;
  sentences: InputMaybe<Scalars["PositiveInt"]["input"]>;
};

export type ICompanyIssuerDescriptionTranslationsArgs = {
  languages: Array<Scalars["ISO6391LanguageCode"]["input"]>;
};

export type ICompanyIssuerSearchRefInput = {
  country: InputMaybe<ISecurityCountry>;
  exchangeId: InputMaybe<Scalars["String"]["input"]>;
  tickerSymbol: Scalars["TickerSymbol"]["input"];
  vertical: InputMaybe<IPlatformVertical>;
};

export type ICover = {
  __typename: "Cover";
  blurhash: Scalars["String"]["output"];
  colorPalette: Array<Scalars["String"]["output"]>;
  dominantColor: Maybe<Scalars["String"]["output"]>;
  height: Scalars["Int"]["output"];
  id: Scalars["ID"]["output"];
  mimeType: Scalars["String"]["output"];
  url: Scalars["URL"]["output"];
  width: Scalars["Int"]["output"];
};

export type ICvmInvestorRelationsContact = {
  __typename: "CvmInvestorRelationsContact";
  email: Maybe<Scalars["String"]["output"]>;
  name: Maybe<Scalars["String"]["output"]>;
  phone: Maybe<Scalars["String"]["output"]>;
};

export type ICvmMaterialFact = {
  __typename: "CvmMaterialFact";
  category: Scalars["String"]["output"];
  dateOfReference: Scalars["DateTime"]["output"];
  deliveryDate: Scalars["DateTime"]["output"];
  deliveryFrequency: Maybe<Scalars["String"]["output"]>;
  deliveryProtocol: Maybe<Scalars["String"]["output"]>;
  downloadUrl: Scalars["URL"]["output"];
  investorRelationsContact: Maybe<ICvmInvestorRelationsContact>;
  issuer: Maybe<IBrazilianCompanyIssuer>;
  kind: Maybe<Scalars["String"]["output"]>;
  protocol: Scalars["Int"]["output"];
  sequentialId: Maybe<Scalars["Int"]["output"]>;
  subjectAsReported: Maybe<Scalars["String"]["output"]>;
  subjects: Array<Scalars["String"]["output"]>;
  type: Maybe<Scalars["String"]["output"]>;
  url: Scalars["URL"]["output"];
};

export enum ICvmMaterialFactIssuersFilter {
  /** Filter by issuers that are in the user's bookmarks */
  InBookmarks = "IN_BOOKMARKS",
  /** Filter by issuers that are in the user's portfolio */
  InPortfolio = "IN_PORTFOLIO",
}

export type ICvmSubmission = {
  __typename: "CvmSubmission";
  primaryDocumentHref: Maybe<Scalars["URL"]["output"]>;
};

export enum IDiscussionCommentsFeedFilterBy {
  OwnComments = "OWN_COMMENTS",
  OwnPortfolio = "OWN_PORTFOLIO",
  Participating = "PARTICIPATING",
}

export type IDiscussionsLeaderboardNode = {
  __typename: "DiscussionsLeaderboardNode";
  commentsCount: Scalars["Int"]["output"];
  repliesCount: Scalars["Int"]["output"];
  totalCount: Scalars["Int"]["output"];
  user: IUser;
};

export type IDividendYieldDetails = {
  __typename: "DividendYieldDetails";
  amountPaid: Scalars["Float"]["output"];
  dividendYield: Scalars["Float"]["output"];
  tickerSymbol: Scalars["TickerSymbol"]["output"];
};

export type IDividendsCorporateAction = {
  __typename: "DividendsCorporateAction";
  availableYears: Array<Scalars["Int"]["output"]>;
  dividendYieldByTickerSymbol: Array<IDividendYieldDetails>;
  dividendsPaidByYear: Array<IDividendsPaidByYearDetails>;
  nodes: Array<IDividendsPaidDetails>;
};

export type IDividendsPaidByYearDetails = {
  __typename: "DividendsPaidByYearDetails";
  amountPaid: Scalars["Float"]["output"];
  fiscalYear: Scalars["Int"]["output"];
  tickerSymbol: Scalars["TickerSymbol"]["output"];
};

export type IDividendsPaidDetails = {
  __typename: "DividendsPaidDetails";
  amountPaid: Scalars["Float"]["output"];
  distributionFrequency: Maybe<Scalars["Int"]["output"]>;
  dividendType: Maybe<Scalars["String"]["output"]>;
  excludingDate: Scalars["Date"]["output"];
  payDate: Maybe<Scalars["Date"]["output"]>;
  recordDate: Maybe<Scalars["Date"]["output"]>;
  tickerSymbol: Scalars["TickerSymbol"]["output"];
};

export type IDynamicConfiguration = {
  __typename: "DynamicConfiguration";
  key: Scalars["String"]["output"];
  value: Scalars["JSON"]["output"];
};

export type IDynamicConfigurationInput = {
  key: Scalars["String"]["input"];
  value: Scalars["JSON"]["input"];
};

export type IEarningsResultsEvent = IAgendaEvent & {
  __typename: "EarningsResultsEvent";
  _id: Scalars["ObjectID"]["output"];
  areFinancialsUpdated: Maybe<Scalars["Boolean"]["output"]>;
  eventDate: Scalars["Date"]["output"];
  eventFiscalYear: Scalars["Int"]["output"];
  eventType: IAgendaEventType;
  issuer: Maybe<IIssuer>;
};

export type IEditableComment = {
  editHistory: Array<ICommentContentEdit>;
  lastEditedAt: Maybe<Scalars["DateTime"]["output"]>;
  originalBodyAsPlainText: Scalars["String"]["output"];
};

export type IEditablePost = {
  editHistory: Array<IPostContentEdit>;
  originalBodyAsPlainText: Scalars["String"]["output"];
  updatedAt: Maybe<Scalars["DateTime"]["output"]>;
};

export type IEffectiveForexRate = {
  __typename: "EffectiveForexRate";
  asOfDate: Scalars["Date"]["output"];
  baseCurrency: Scalars["String"]["output"];
  price: Scalars["Float"]["output"];
  quoteCurrency: Scalars["String"]["output"];
};

export type IEmployeeNumbers = {
  __typename: "EmployeeNumbers";
  /** Date of which the observation was made */
  asOfDate: Scalars["Date"]["output"];
  /** Is the amount of full-time employees reported by the company */
  fullTimeEmployees: Scalars["Int"]["output"];
  /**
   * Is the amount of part-time employees reported by the company. This information is often not available since most
   * companies only reports the total amount which's equivalent to all employees
   */
  partTimeEmployees: Scalars["Int"]["output"];
  /** Total amount of employees reported by the company, both full-time and part-time */
  totalOfEmployees: Scalars["Int"]["output"];
};

export type IEvaluatedFeatureFlagVariant = {
  __typename: "EvaluatedFeatureFlagVariant";
  flag: IFeatureFlag;
  value: Maybe<Scalars["JSON"]["output"]>;
  variant: IFeatureFlagVariant;
};

export type IExecutiveCompensationHistoryItem = {
  __typename: "ExecutiveCompensationHistoryItem";
  allOtherCompensation: Maybe<Scalars["Float"]["output"]>;
  bonus: Maybe<Scalars["Float"]["output"]>;
  calculatedTotal: Scalars["Float"]["output"];
  changeInPensionValue: Maybe<Scalars["Float"]["output"]>;
  currency: Scalars["String"]["output"];
  forexRate: Maybe<IEffectiveForexRate>;
  longTermIncentivePlanPayouts: Maybe<Scalars["Float"]["output"]>;
  maximumPerformanceSharePotential: Maybe<Scalars["Float"]["output"]>;
  nonEquityIncentiveAward: Maybe<Scalars["Float"]["output"]>;
  optionAward: Maybe<Scalars["Float"]["output"]>;
  otherAnnualCompensation: Maybe<Scalars["Float"]["output"]>;
  reportDate: Scalars["Date"]["output"];
  reportSource: Scalars["String"]["output"];
  restrictedStockAward: Maybe<Scalars["Float"]["output"]>;
  salary: Maybe<Scalars["Float"]["output"]>;
  securityUnderlyingOptions: Maybe<Scalars["Float"]["output"]>;
  totalCompensation: Maybe<Scalars["Float"]["output"]>;
};

export type IFeatureFlag = {
  __typename: "FeatureFlag";
  key: Scalars["ID"]["output"];
  objectId: Scalars["ID"]["output"];
};

export type IFeatureFlagVariant = {
  __typename: "FeatureFlagVariant";
  key: Scalars["ID"]["output"];
  objectId: Scalars["ID"]["output"];
};

export type IFinancialBusinessSegment = IFinancialSegment & {
  __typename: "FinancialBusinessSegment";
  financialIndicators: Array<IFinancialSegmentFinancialValueIndicator>;
  isPrimary: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
  ratioAdjustedIndicators: Array<IFinancialSegmentRatioIndicator>;
};

export type IFinancialGeographicSegment = IFinancialSegment & {
  __typename: "FinancialGeographicSegment";
  financialIndicators: Array<IFinancialSegmentFinancialValueIndicator>;
  isPrimary: Scalars["Boolean"]["output"];
  isoCountryCode: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  ratioAdjustedIndicators: Array<IFinancialSegmentRatioIndicator>;
  regionAsReported: Maybe<Scalars["String"]["output"]>;
};

export type IFinancialHighlights = {
  __typename: "FinancialHighlights";
  _id: Scalars["ObjectID"]["output"];
  fiscalPeriodDate: Scalars["Date"]["output"];
  fiscalPeriodQuarter: IFiscalPeriodQuarter;
  highlights: Scalars["JSON"]["output"];
  oneLiner: Maybe<Scalars["String"]["output"]>;
};

export type IFinancialHighlightsHistory = {
  __typename: "FinancialHighlightsHistory";
  availableHighlights: Array<IAvailableHighlights>;
  financialHighlights: IFinancialHighlights;
  reportSource: Maybe<IBrazilianIssuerManagementReport>;
};

export type IFinancialIndicatorDisplayRule = {
  __typename: "FinancialIndicatorDisplayRule";
  backgroundColor: Scalars["String"]["output"];
  formatting: Array<Scalars["String"]["output"]>;
  textColor: Scalars["String"]["output"];
};

export type IFinancialIndicatorSeriesCurrencyNode = IFinancialIndicatorSeriesNode & {
  __typename: "FinancialIndicatorSeriesCurrencyNode";
  formattedValue: Scalars["String"]["output"];
  theme: Maybe<IFinancialIndicatorDisplayRule>;
  value: Scalars["Float"]["output"];
};

export type IFinancialIndicatorSeriesFinancialNode = IFinancialIndicatorSeriesNode & {
  __typename: "FinancialIndicatorSeriesFinancialNode";
  formattedValue: Scalars["String"]["output"];
  theme: Maybe<IFinancialIndicatorDisplayRule>;
  value: Scalars["Float"]["output"];
};

export type IFinancialIndicatorSeriesMetricNode = IFinancialIndicatorSeriesNode & {
  __typename: "FinancialIndicatorSeriesMetricNode";
  formattedValue: Scalars["String"]["output"];
  theme: Maybe<IFinancialIndicatorDisplayRule>;
  value: Scalars["Float"]["output"];
};

export type IFinancialIndicatorSeriesNode = {
  formattedValue: Scalars["String"]["output"];
  theme: Maybe<IFinancialIndicatorDisplayRule>;
};

export type IFinancialIndicatorSeriesNumberNode = IFinancialIndicatorSeriesNode & {
  __typename: "FinancialIndicatorSeriesNumberNode";
  formattedValue: Scalars["String"]["output"];
  theme: Maybe<IFinancialIndicatorDisplayRule>;
  value: Scalars["Float"]["output"];
};

export type IFinancialIndicatorSeriesPercentNode = IFinancialIndicatorSeriesNode & {
  __typename: "FinancialIndicatorSeriesPercentNode";
  formattedValue: Scalars["String"]["output"];
  theme: Maybe<IFinancialIndicatorDisplayRule>;
  value: Scalars["Float"]["output"];
};

export type IFinancialIndicatorSeriesRatioNode = IFinancialIndicatorSeriesNode & {
  __typename: "FinancialIndicatorSeriesRatioNode";
  formattedValue: Scalars["String"]["output"];
  theme: Maybe<IFinancialIndicatorDisplayRule>;
  value: Scalars["Float"]["output"];
};

export type IFinancialIndicatorSeriesSkeletonNode = IFinancialIndicatorSeriesNode & {
  __typename: "FinancialIndicatorSeriesSkeletonNode";
  formattedValue: Scalars["String"]["output"];
  text: Scalars["String"]["output"];
  theme: Maybe<IFinancialIndicatorDisplayRule>;
};

export type IFinancialIndicatorSeriesTextNode = IFinancialIndicatorSeriesNode & {
  __typename: "FinancialIndicatorSeriesTextNode";
  formattedValue: Scalars["String"]["output"];
  text: Scalars["String"]["output"];
  theme: Maybe<IFinancialIndicatorDisplayRule>;
};

export type IFinancialSegment = {
  financialIndicators: Array<IFinancialSegmentIndicator>;
  isPrimary: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
  ratioAdjustedIndicators: Array<IFinancialSegmentIndicator>;
};

export type IFinancialSegmentFinancialValueIndicator = IFinancialSegmentIndicator & {
  __typename: "FinancialSegmentFinancialValueIndicator";
  history: Array<IFinancialSegmentIndicatorHistoryNode>;
  name: Scalars["String"]["output"];
  value: Scalars["Float"]["output"];
};

export type IFinancialSegmentIndicator = {
  history: Array<IFinancialSegmentIndicatorHistoryNode>;
  name: Scalars["String"]["output"];
  value: Scalars["Float"]["output"];
};

export type IFinancialSegmentIndicatorHistoryNode = {
  __typename: "FinancialSegmentIndicatorHistoryNode";
  periodEndingDate: Scalars["Date"]["output"];
  value: Scalars["Float"]["output"];
};

export type IFinancialSegmentIndicatorTotal = {
  __typename: "FinancialSegmentIndicatorTotal";
  history: Array<IFinancialSegmentIndicatorTotalHistoryNode>;
  name: Scalars["String"]["output"];
  value: Scalars["Float"]["output"];
};

export type IFinancialSegmentIndicatorTotalHistoryNode = {
  __typename: "FinancialSegmentIndicatorTotalHistoryNode";
  periodEndingDate: Scalars["Date"]["output"];
  value: Scalars["Float"]["output"];
};

export type IFinancialSegmentRatioIndicator = IFinancialSegmentIndicator & {
  __typename: "FinancialSegmentRatioIndicator";
  history: Array<IFinancialSegmentIndicatorHistoryNode>;
  name: Scalars["String"]["output"];
  value: Scalars["Float"]["output"];
};

export type IFinancialSegments = {
  __typename: "FinancialSegments";
  adjustedTotals: Array<IFinancialSegmentIndicatorTotal>;
  currency: Scalars["String"]["output"];
  periodEndingDate: Scalars["Date"]["output"];
  reportSource: Maybe<IReportSource>;
  revenueHistory: Array<IFinancialSegmentIndicatorHistoryNode>;
  segments: Array<IFinancialSegment>;
  unadjustedTotals: Array<IFinancialSegmentIndicatorTotal>;
};

export type IFinancialsAvailability = {
  __typename: "FinancialsAvailability";
  interim: Array<Scalars["String"]["output"]>;
  reportDates: Array<Scalars["Date"]["output"]>;
  statementType: IFinancialsStatementType;
  viewerCanSee: Array<Scalars["Boolean"]["output"]>;
};

export type IFinancialsComparisonSheet = {
  __typename: "FinancialsComparisonSheet";
  adversaries: Array<IFinancialsComparisonSheetAdversaryColumn>;
  indicators: Array<IFinancialsComparisonSheetIndicator>;
  overridingTemplate: Maybe<IFinancialsTemplate>;
  reportDates: Array<Scalars["Date"]["output"]>;
  statementType: IFinancialsStatementType;
  systemTemplate: Maybe<IFinancialsTemplate>;
  viewerSelectedTemplate: Maybe<IFinancialsTemplate>;
};

export type IFinancialsComparisonSheetAdversaryColumn = {
  __typename: "FinancialsComparisonSheetAdversaryColumn";
  country: ISecurityCountry;
  indicators: Array<IFinancialsComparisonSheetAdversaryColumnIndicatorSeries>;
  isAnnual: Array<Maybe<Scalars["Boolean"]["output"]>>;
  isMonthly: Array<Maybe<Scalars["Boolean"]["output"]>>;
  isPreliminaryTrailingTwelveMonths: Array<Maybe<Scalars["Boolean"]["output"]>>;
  isQuarterly: Array<Maybe<Scalars["Boolean"]["output"]>>;
  isTrailingTwelveMonths: Array<Maybe<Scalars["Boolean"]["output"]>>;
  issuer: Maybe<ICompanyIssuer>;
  reportDates: Array<Scalars["Date"]["output"]>;
  tickerSymbol: Scalars["TickerSymbol"]["output"];
  vertical: IPlatformVertical;
};

export type IFinancialsComparisonSheetAdversaryColumnIndicatorSeries = {
  __typename: "FinancialsComparisonSheetAdversaryColumnIndicatorSeries";
  objectId: Scalars["IndicatorID"]["output"];
  series: Array<IFinancialIndicatorSeriesNode>;
  viewerCanSee: Scalars["Boolean"]["output"];
};

export type IFinancialsComparisonSheetIndicator = IIndicatorDefinition & {
  __typename: "FinancialsComparisonSheetIndicator";
  category: IIndicatorCategory;
  columnTheme: IIndicatorColumnTheme;
  dataSource: IIndicatorDataSource;
  documentation: Array<IIndicatorDocumentation>;
  kind: IIndicatorKind;
  numberFormatOptions: Maybe<INumberFormatOptions>;
  objectId: Scalars["IndicatorID"]["output"];
  preferredDocumentation: IIndicatorDocumentation;
  vertical: IIndicatorVertical;
  viewerCanSee: Scalars["Boolean"]["output"];
};

export type IFinancialsSeriesSecurityInput = {
  country: ISecurityCountry;
  tickerSymbol: Scalars["TickerSymbol"]["input"];
};

export type IFinancialsSeriesSheet = {
  __typename: "FinancialsSeriesSheet";
  reportDates: Array<Scalars["Date"]["output"]>;
  series: Array<IFinancialsSeriesSheetSeries>;
  statementType: IFinancialsStatementType;
};

export type IFinancialsSeriesSheetSeries = {
  __typename: "FinancialsSeriesSheetSeries";
  country: ISecurityCountry;
  indicators: Array<IFinancialsSheetIndicator>;
  isAnnual: Array<Maybe<Scalars["Boolean"]["output"]>>;
  isMonthly: Array<Maybe<Scalars["Boolean"]["output"]>>;
  isPreliminaryTrailingTwelveMonths: Array<Maybe<Scalars["Boolean"]["output"]>>;
  isQuarterly: Array<Maybe<Scalars["Boolean"]["output"]>>;
  isTrailingTwelveMonths: Array<Maybe<Scalars["Boolean"]["output"]>>;
  issuer: Maybe<ICompanyIssuer>;
  reportDates: Array<Scalars["Date"]["output"]>;
  tickerSymbol: Scalars["TickerSymbol"]["output"];
  vertical: IPlatformVertical;
};

export type IFinancialsSheet = {
  __typename: "FinancialsSheet";
  context: Array<Maybe<IFinancialsSheetContextItem>>;
  financialsCurrency: Scalars["String"]["output"];
  forexRates: Array<IEffectiveForexRate>;
  indicators: Array<IFinancialsSheetIndicator>;
  interim: Array<Maybe<Scalars["String"]["output"]>>;
  isAnnual: Array<Maybe<Scalars["Boolean"]["output"]>>;
  isMonthly: Array<Maybe<Scalars["Boolean"]["output"]>>;
  isPreliminaryTrailingTwelveMonths: Array<Maybe<Scalars["Boolean"]["output"]>>;
  isQuarterly: Array<Maybe<Scalars["Boolean"]["output"]>>;
  isTrailingTwelveMonths: Array<Maybe<Scalars["Boolean"]["output"]>>;
  overridingTemplate: Maybe<IFinancialsTemplate>;
  quotes: Array<IQuoteChartNode>;
  reportDates: Array<Scalars["Date"]["output"]>;
  requestedCurrency: Scalars["String"]["output"];
  statementType: IFinancialsStatementType;
  systemTemplate: IFinancialsTemplate;
  viewerSelectedTemplate: Maybe<IFinancialsTemplate>;
};

export type IFinancialsSheetComparisonAdversaryInput = {
  country: ISecurityCountry;
  tickerSymbol: Scalars["TickerSymbol"]["input"];
};

export type IFinancialsSheetContextItem = IFinancialsSheetFnetContextItem | IFinancialsSheetSecContextItem;

export type IFinancialsSheetFnetContextItem = {
  __typename: "FinancialsSheetFNETContextItem";
  dateOfReference: Scalars["Date"]["output"];
  deliveredAt: Scalars["DateTime"]["output"];
  filingId: Scalars["ID"]["output"];
  linkHref: Scalars["String"]["output"];
};

export type IFinancialsSheetIndicator = IIndicatorDefinition & {
  __typename: "FinancialsSheetIndicator";
  category: IIndicatorCategory;
  columnTheme: IIndicatorColumnTheme;
  dataSource: IIndicatorDataSource;
  documentation: Array<IIndicatorDocumentation>;
  kind: IIndicatorKind;
  numberFormatOptions: Maybe<INumberFormatOptions>;
  objectId: Scalars["IndicatorID"]["output"];
  preferredDocumentation: IIndicatorDocumentation;
  series: Array<IFinancialIndicatorSeriesNode>;
  vertical: IIndicatorVertical;
  viewerCanSee: Scalars["Boolean"]["output"];
};

export enum IFinancialsSheetIndicatorsCriterion {
  Include = "INCLUDE",
  Only = "ONLY",
  Without = "WITHOUT",
}

export enum IFinancialsSheetLimitCriterion {
  NumberOfRows = "NUMBER_OF_ROWS",
  NumberOfYears = "NUMBER_OF_YEARS",
  Unbounded = "UNBOUNDED",
}

export type IFinancialsSheetSecContextItem = {
  __typename: "FinancialsSheetSECContextItem";
  accessionNumber: Scalars["ID"]["output"];
  filedAt: Scalars["DateTime"]["output"];
  formType: Scalars["String"]["output"];
  linkToDetails: Scalars["String"]["output"];
  linkToHighlights: Maybe<Scalars["String"]["output"]>;
};

export enum IFinancialsStatementType {
  Annual = "ANNUAL",
  Monthly = "MONTHLY",
  Quarterly = "QUARTERLY",
}

export type IFinancialsTemplate = INode &
  IStarrable & {
    __typename: "FinancialsTemplate";
    _id: Scalars["ObjectID"]["output"];
    archivedAt: Maybe<Scalars["DateTime"]["output"]>;
    betterSuitedFor: Array<IIssuer>;
    countryCode: ISecurityCountry;
    createdAt: Scalars["DateTime"]["output"];
    createdBy: Maybe<IUser>;
    dataSource: IIndicatorDataSource;
    deletedAt: Maybe<Scalars["DateTime"]["output"]>;
    description: Scalars["String"]["output"];
    id: Scalars["ID"]["output"];
    indicators: Array<IIndicator>;
    isFundamenteiTeamTemplate: Scalars["Boolean"]["output"];
    isFundamenteiTemplate: Scalars["Boolean"]["output"];
    isNativeTemplate: Scalars["Boolean"]["output"];
    lastUpdatedAt: Maybe<Scalars["DateTime"]["output"]>;
    name: Scalars["String"]["output"];
    namespace: IFinancialsTemplateNamespace;
    objectId: Scalars["ID"]["output"];
    overrideCriteria: Maybe<IFinancialsTemplateOverrideCriteria>;
    stargazerCount: Scalars["Int"]["output"];
    templateCodes: Array<IFinancialsTemplateCode>;
    useCount: Scalars["Int"]["output"];
    vertical: IPlatformVertical;
    viewerCanArchive: Scalars["Boolean"]["output"];
    viewerCanChangeVisibility: Scalars["Boolean"]["output"];
    viewerCanCopy: Scalars["Boolean"]["output"];
    viewerCanDelete: Scalars["Boolean"]["output"];
    viewerCanEdit: Scalars["Boolean"]["output"];
    viewerHasStarred: Scalars["Boolean"]["output"];
    visibility: IFinancialsTemplateVisibility;
  };

export type IFinancialsTemplateBreakdown = {
  __typename: "FinancialsTemplateBreakdown";
  archivedCount: Scalars["Int"]["output"];
  myTemplatesCount: Scalars["Int"]["output"];
  publicCount: Scalars["Int"]["output"];
};

export enum IFinancialsTemplateCode {
  Bank = "BANK",
  Insurance = "INSURANCE",
  Mining = "MINING",
  Normal = "NORMAL",
  Transport = "TRANSPORT",
  Utility = "UTILITY",
}

export type IFinancialsTemplateInput = {
  betterSuitedFor: InputMaybe<Array<Scalars["ObjectID"]["input"]>>;
  countryCode: ISecurityCountry;
  dataSource: IIndicatorDataSource;
  description: Scalars["String"]["input"];
  indicators: Array<Scalars["IndicatorID"]["input"]>;
  name: Scalars["String"]["input"];
  overrideCriteria: InputMaybe<IFinancialsTemplateOverrideCriteriaInput>;
  templateCodes: Array<IFinancialsTemplateCode>;
  vertical: IPlatformVertical;
  visibility: IFinancialsTemplateVisibility;
};

export enum IFinancialsTemplateNamespace {
  Fundamentei = "FUNDAMENTEI",
  System = "SYSTEM",
  User = "USER",
}

export type IFinancialsTemplateOverrideCriteria = {
  __typename: "FinancialsTemplateOverrideCriteria";
  forIssuers: Maybe<Array<IIssuer>>;
  forPlatformVertical: Maybe<IPlatformVertical>;
  forTemplateCodes: Maybe<Array<IFinancialsTemplateCode>>;
};

export type IFinancialsTemplateOverrideCriteriaInput = {
  forIssuers: Array<Scalars["ObjectID"]["input"]>;
  forPlatformVertical: InputMaybe<IPlatformVertical>;
  forTemplateCodes: Array<IFinancialsTemplateCode>;
};

export enum IFinancialsTemplateVisibility {
  Private = "PRIVATE",
  Public = "PUBLIC",
}

export type IFinancialsTemplatesQueryFilters = {
  filterByCountry: InputMaybe<ISecurityCountry>;
  filterByIndicatorDataSource: InputMaybe<IIndicatorDataSource>;
  filterByNamespace: InputMaybe<Array<IFinancialsTemplateNamespace>>;
  filterByPlatformVertical: InputMaybe<Array<IPlatformVertical>>;
  filterByTemplateCodes: InputMaybe<Array<IFinancialsTemplateCode>>;
  filterByViewerArchivedTemplates: InputMaybe<Scalars["Boolean"]["input"]>;
  filterByViewerId: InputMaybe<Scalars["ObjectID"]["input"]>;
};

export type IFinancialsTemplatesQueryOrder = {
  direction: IOrderDirection;
  field: IFinancialsTemplatesQueryOrderField;
};

export enum IFinancialsTemplatesQueryOrderField {
  CreatedAt = "CREATED_AT",
  UseCount = "USE_COUNT",
}

export enum IFiscalPeriodQuarter {
  Q1 = "Q1",
  Q2 = "Q2",
  Q3 = "Q3",
  Q4 = "Q4",
}

export type IForexRate = {
  __typename: "ForexRate";
  currency: Scalars["String"]["output"];
  quote: Scalars["Float"]["output"];
};

export type IForumPost = IEditablePost &
  ILockablePost &
  IPinnablePost &
  IPost &
  IReactable &
  IUniformResourceLocatable & {
    __typename: "ForumPost";
    _id: Scalars["ObjectID"]["output"];
    author: IUser;
    bodyAsJson: Scalars["JSON"]["output"];
    bodyAsPlainText: Scalars["String"]["output"];
    contextKey: Maybe<Scalars["String"]["output"]>;
    contextType: IPostContextType;
    createdAt: Scalars["DateTime"]["output"];
    editHistory: Array<IPostContentEdit>;
    isLocked: Scalars["Boolean"]["output"];
    isPinned: Scalars["Boolean"]["output"];
    lastRepliedAt: Maybe<Scalars["DateTime"]["output"]>;
    lockedAt: Maybe<Scalars["DateTime"]["output"]>;
    lockedBy: Maybe<IUser>;
    objectId: Scalars["ID"]["output"];
    originalBodyAsPlainText: Scalars["String"]["output"];
    participants: IPostParticipants;
    pinnedAt: Maybe<Scalars["DateTime"]["output"]>;
    pinnedBy: Maybe<IUser>;
    reactionGroups: Array<IReactionGroup>;
    replies: IPostRepliesPagination;
    replyAnswer: Maybe<IPostReply>;
    resourcePath: Scalars["String"]["output"];
    shortId: Scalars["ID"]["output"];
    title: Scalars["String"]["output"];
    updatedAt: Maybe<Scalars["DateTime"]["output"]>;
    url: Scalars["URL"]["output"];
    viewerCanDestroy: Scalars["Boolean"]["output"];
    viewerCanEdit: Scalars["Boolean"]["output"];
    viewerCanReact: Scalars["Boolean"]["output"];
    viewerCanReply: Scalars["Boolean"]["output"];
    viewerCanViewReplies: Scalars["Boolean"]["output"];
    viewerDidAuthor: Scalars["Boolean"]["output"];
  };

export type IForumPostParticipantsArgs = {
  top: Scalars["Int"]["input"];
};

export type IForumPostRepliesArgs = {
  cursor: InputMaybe<Scalars["Cursor"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy: InputMaybe<IPostOrder>;
};

export type IFreeFloatHistoryItem = {
  __typename: "FreeFloatHistoryItem";
  asOfDate: Scalars["Date"]["output"];
  floatShare: Scalars["Float"]["output"];
  floatShareToTotalSharesOutstanding: Scalars["Float"]["output"];
  totalSharesOutstanding: Scalars["Float"]["output"];
};

export type IFundamenteiMedia = {
  mediaType: IFundamenteiMediaType;
};

export enum IFundamenteiMediaType {
  Audio = "AUDIO",
  Video = "VIDEO",
}

export type IGeographicalCountry = {
  __typename: "GeographicalCountry";
  flagSvg: IGeographicalCountrySvgFlag;
  isoCode: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
};

export type IGeographicalCountrySvgFlag = {
  __typename: "GeographicalCountrySvgFlag";
  ovalHref: Scalars["String"]["output"];
  squaredHref: Scalars["String"]["output"];
};

export type IGitDetails = {
  __typename: "GitDetails";
  branch: Maybe<Scalars["String"]["output"]>;
  date: Scalars["DateTime"]["output"];
  hasUnstagedChanges: Maybe<Scalars["Boolean"]["output"]>;
  isDirty: Maybe<Scalars["Boolean"]["output"]>;
  shaLong: Scalars["ID"]["output"];
  shaShort: Scalars["ID"]["output"];
};

export type IGitHubRepositoryCommit = {
  __typename: "GitHubRepositoryCommit";
  authoredDate: Maybe<Scalars["DateTime"]["output"]>;
  repository: Scalars["ID"]["output"];
  sha: Scalars["String"]["output"];
};

export type IGlassdoorRatings = {
  __typename: "GlassdoorRatings";
  allReviewsCount: Scalars["Float"]["output"];
  asOfDate: Scalars["Date"]["output"];
  businessOutlookRating: Scalars["Float"]["output"];
  careerOpportunitiesRating: Scalars["Float"]["output"];
  ceoRating: Scalars["Float"]["output"];
  ceoRatingsCount: Scalars["Float"]["output"];
  compensationAndBenefitsRating: Scalars["Float"]["output"];
  cultureAndValuesRating: Scalars["Float"]["output"];
  diversityAndInclusionRating: Scalars["Float"]["output"];
  filteredReviewsCount: Scalars["Float"]["output"];
  glassdoorUrl: Scalars["URL"]["output"];
  overallRating: Scalars["Float"]["output"];
  ratedReviewsCount: Scalars["Float"]["output"];
  recommendToFriendRating: Scalars["Float"]["output"];
  reviewCount: Scalars["Float"]["output"];
  seniorManagementRating: Scalars["Float"]["output"];
  workLifeBalanceRating: Scalars["Float"]["output"];
};

export enum IGlobalSecurityType {
  DepositaryReceipt = "DEPOSITARY_RECEIPT",
  Equity = "EQUITY",
  Etf = "ETF",
}

export type IGoalByCountrySetting = {
  __typename: "GoalByCountrySetting";
  americanSecurities: Scalars["Int"]["output"];
  brazilianSecurities: Scalars["Int"]["output"];
};

export type IGoalByCountrySettingInput = {
  americanSecurities: Scalars["Int"]["input"];
  brazilianSecurities: Scalars["Int"]["input"];
};

export type IGoalBySecurityClassificationSetting = {
  __typename: "GoalBySecurityClassificationSetting";
  americanCompanies: Scalars["Float"]["output"];
  americanDepositaryReceipts: Scalars["Float"]["output"];
  americanExchangeTradedFunds: Scalars["Float"]["output"];
  americanReits: Scalars["Float"]["output"];
  brazilianCompanies: Scalars["Float"]["output"];
  brazilianDepositaryReceipts: Scalars["Float"]["output"];
  brazilianExchangeTradedFunds: Scalars["Float"]["output"];
  brazilianRealEstateFunds: Scalars["Float"]["output"];
  fixedIncome: Scalars["Float"]["output"];
  others: Scalars["Float"]["output"];
};

export type IGoalBySecurityClassificationSettingInput = {
  americanCompanies: Scalars["Float"]["input"];
  americanDepositaryReceipts: Scalars["Float"]["input"];
  americanExchangeTradedFunds: Scalars["Float"]["input"];
  americanReits: Scalars["Float"]["input"];
  brazilianCompanies: Scalars["Float"]["input"];
  brazilianDepositaryReceipts: Scalars["Float"]["input"];
  brazilianExchangeTradedFunds: Scalars["Float"]["input"];
  brazilianRealEstateFunds: Scalars["Float"]["input"];
  fixedIncome: Scalars["Float"]["input"];
  others: Scalars["Float"]["input"];
};

export type IHistoricalFfoVersusQuote = {
  __typename: "HistoricalFFOVersusQuote";
  quarterly: Array<IHistoricalFfoVersusQuotePoint>;
  yearly: Array<IHistoricalFfoVersusQuotePoint>;
};

export type IHistoricalFfoVersusQuotePoint = {
  __typename: "HistoricalFFOVersusQuotePoint";
  adjustedClose: Scalars["Float"]["output"];
  fiscalDate: Scalars["Date"]["output"];
  fundsFromOperations: Scalars["Float"]["output"];
  quoteDate: Scalars["Date"]["output"];
};

export type IHistoricalNetIncomeVersusQuote = {
  __typename: "HistoricalNetIncomeVersusQuote";
  quarterly: Array<IHistoricalNetIncomeVersusQuotePoint>;
  yearly: Array<IHistoricalNetIncomeVersusQuotePoint>;
};

export type IHistoricalNetIncomeVersusQuotePoint = {
  __typename: "HistoricalNetIncomeVersusQuotePoint";
  adjustedClose: Scalars["Float"]["output"];
  fiscalDate: Scalars["Date"]["output"];
  netIncome: Scalars["Float"]["output"];
  quoteDate: Scalars["Date"]["output"];
};

export type IHistoricalQuotePeriod = {
  __typename: "HistoricalQuotePeriod";
  humanReadablePeriod: Scalars["String"]["output"];
  period: IQuotePeriod;
};

export type IHistoricalQuotes = {
  __typename: "HistoricalQuotes";
  historicalQuotesPeriods: Array<IHistoricalQuotePeriod>;
  quotes: Scalars["String"]["output"];
};

export type IHistoricalQuotesQuotesArgs = {
  period: IQuotePeriod;
};

export type IHistoricalReturn = {
  __typename: "HistoricalReturn";
  allTime: Array<IHistoricalReturnPoint>;
  lastQuote: Maybe<IQuoteChartNode>;
};

export type IHistoricalReturnPoint = {
  __typename: "HistoricalReturnPoint";
  adjustedClose: Scalars["Float"]["output"];
  date: Scalars["Date"]["output"];
  returnRate: Scalars["Float"]["output"];
};

export type IHistoricalShareholders = {
  __typename: "HistoricalShareholders";
  allTime: Array<IHistoricalShareholdersDistribution>;
};

export type IHistoricalShareholdersDistribution = {
  __typename: "HistoricalShareholdersDistribution";
  fiscalYear: Scalars["Date"]["output"];
  totalOfCorporateShareholders: Scalars["Int"]["output"];
  totalOfIndividualShareholders: Scalars["Int"]["output"];
  totalOfInstitutionalShareholders: Scalars["Int"]["output"];
  totalOfShareholders: Scalars["Int"]["output"];
};

export type IHistoricalTradingSessionPeriod = {
  __typename: "HistoricalTradingSessionPeriod";
  humanReadablePeriod: Scalars["String"]["output"];
  period: ITradingSessionPeriod;
};

export type IHistoricalTradingSessions = {
  __typename: "HistoricalTradingSessions";
  tradingSessions: Scalars["String"]["output"];
  tradingSessionsPeriods: Array<IHistoricalTradingSessionPeriod>;
};

export type IHistoricalTradingSessionsTradingSessionsArgs = {
  period: ITradingSessionPeriod;
};

export type IIfixParticipation = {
  __typename: "IFIXParticipation";
  asPercentage: Scalars["Float"]["output"];
  theoreticalQuantity: Scalars["Int"]["output"];
};

export type IImportPositionsToPortfolioInput = {
  numberOfShares: Scalars["Float"]["input"];
  tickerSymbol: Scalars["TickerSymbol"]["input"];
};

export type IIndicator = IIndicatorDefinition &
  INode & {
    __typename: "Indicator";
    category: IIndicatorCategory;
    columnTheme: IIndicatorColumnTheme;
    dataSource: IIndicatorDataSource;
    documentation: Array<IIndicatorDocumentation>;
    id: Scalars["ID"]["output"];
    kind: IIndicatorKind;
    numberFormatOptions: Maybe<INumberFormatOptions>;
    objectId: Scalars["IndicatorID"]["output"];
    vertical: IIndicatorVertical;
    viewerCanSee: Scalars["Boolean"]["output"];
  };

export enum IIndicatorCategory {
  BalanceSheet = "BALANCE_SHEET",
  CashFlow = "CASH_FLOW",
  Fundamentei = "FUNDAMENTEI",
  IncomeStatement = "INCOME_STATEMENT",
  Metrics = "METRICS",
  Other = "OTHER",
  Payout = "PAYOUT",
  Ratios = "RATIOS",
}

export type IIndicatorColumnTheme = {
  __typename: "IndicatorColumnTheme";
  backgroundColor: Scalars["String"]["output"];
  textColor: Scalars["String"]["output"];
};

export enum IIndicatorDataSource {
  Cvm = "CVM",
  Fnet = "FNET",
  Global = "GLOBAL",
  Manual = "MANUAL",
  Morningstar = "MORNINGSTAR",
  Other = "OTHER",
  Unspecified = "UNSPECIFIED",
}

export type IIndicatorDefinition = {
  category: IIndicatorCategory;
  columnTheme: IIndicatorColumnTheme;
  dataSource: IIndicatorDataSource;
  documentation: Array<IIndicatorDocumentation>;
  kind: IIndicatorKind;
  numberFormatOptions: Maybe<INumberFormatOptions>;
  objectId: Scalars["IndicatorID"]["output"];
  vertical: IIndicatorVertical;
  viewerCanSee: Scalars["Boolean"]["output"];
};

export type IIndicatorDocumentation = {
  __typename: "IndicatorDocumentation";
  abbreviation: Maybe<Scalars["String"]["output"]>;
  description: Scalars["String"]["output"];
  languageCode: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
};

export enum IIndicatorKind {
  Currency = "CURRENCY",
  Financial = "FINANCIAL",
  Metric = "METRIC",
  Number = "NUMBER",
  Percent = "PERCENT",
  Ratio = "RATIO",
  Text = "TEXT",
}

export enum IIndicatorVertical {
  Acoes = "ACOES",
  All = "ALL",
  Fiis = "FIIS",
  Reits = "REITS",
  Stocks = "STOCKS",
  StocksAndReits = "STOCKS_AND_REITS",
}

export type IIndicatorsFilters = {
  filterByDataSource: InputMaybe<Array<IIndicatorDataSource>>;
  filterByIndicatorVertical: InputMaybe<Array<IIndicatorVertical>>;
};

export enum IIndicatorsTablePlacement {
  End = "END",
  Start = "START",
}

export type IIndustrySector = {
  __typename: "IndustrySector";
  _id: Scalars["ObjectID"]["output"];
  name: Scalars["String"]["output"];
};

export enum IInvestmentType {
  ClosedEndFund = "CLOSED_END_FUND",
  DepositoryReceipt = "DEPOSITORY_RECEIPT",
  Equity = "EQUITY",
  PreferredStock = "PREFERRED_STOCK",
  Unit = "UNIT",
  Warrant = "WARRANT",
}

export type IIssuer =
  | IAmericanCompanyIssuer
  | IBrazilianCompanyIssuer
  | IBrazilianRealEstateFund
  | IBrazilianRealEstateFundIssuer;

export type IIssuerEngagementStats = {
  __typename: "IssuerEngagementStats";
  totalDiscussionsCommentsCount: Scalars["Int"]["output"];
  viewerUnseenDiscussionsCommentsCount: Scalars["Int"]["output"];
};

export enum IIssuerListingCountry {
  Br = "BR",
  Us = "US",
}

export type IIssuerLogo = {
  __typename: "IssuerLogo";
  /** A short palette of colors that are present in the logo */
  colorPalette: Array<Scalars["String"]["output"]>;
  /** Is the HEX code for the predominant color on the logo */
  dominantColor: Maybe<Scalars["String"]["output"]>;
  /** Is the geometry of the logo: NICE or STANDARD */
  geometry: IIssuerLogoGeometry;
  /** Is the CDN URL where the logo is hosted */
  url: Scalars["String"]["output"];
};

export enum IIssuerLogoGeometry {
  /**
   * Is for when a logo is in a squared shape that's internally crafted by us (Fundamentei). Not all companies will
   * have this format since it demands a lot of manual work
   */
  Nice = "NICE",
  /**
   * Is for when a logo is in a format that's originally published by the company. It can be squared, rounded or
   * whatever shape represents the original version of the logo
   */
  Standard = "STANDARD",
}

export type IIssuerLogos = {
  niceLogo: Maybe<IIssuerLogo>;
  standardLogo: Maybe<IIssuerLogo>;
};

export enum IIssuerSearchNarrowCriteria {
  SameDowJonesIndicator = "SAME_DOW_JONES_INDICATOR",
  SameFinnancialsTemplate = "SAME_FINNANCIALS_TEMPLATE",
  SameIndustry = "SAME_INDUSTRY",
  SameIndustryGroup = "SAME_INDUSTRY_GROUP",
  SameMarketCapitalizationTier = "SAME_MARKET_CAPITALIZATION_TIER",
  SameNumberOfEmployeesTier = "SAME_NUMBER_OF_EMPLOYEES_TIER",
  SameSector = "SAME_SECTOR",
  SameStandardAndPoorsIndicator = "SAME_STANDARD_AND_POORS_INDICATOR",
  SameType = "SAME_TYPE",
}

export type IIssuerSearchResultNode = {
  _id: Scalars["ObjectID"]["output"];
  listingExchangeCode: Scalars["String"]["output"];
  marketCapitalization: Maybe<IMarketCap>;
  name: Scalars["String"]["output"];
  niceLogo: Maybe<IIssuerLogo>;
  tickerSymbol: Scalars["String"]["output"];
  tickerSymbols: Array<Scalars["String"]["output"]>;
};

export enum IIssuerType {
  AmericanCompany = "AMERICAN_COMPANY",
  AmericanReit = "AMERICAN_REIT",
  BrazilianCompany = "BRAZILIAN_COMPANY",
  BrazilianRealEstateFund = "BRAZILIAN_REAL_ESTATE_FUND",
}

export type IKeyPerformanceIndicator = {
  __typename: "KeyPerformanceIndicator";
  description: Maybe<Scalars["String"]["output"]>;
  format: Scalars["String"]["output"];
  isCurrency: Scalars["Boolean"]["output"];
  isDiscontinued: Scalars["Boolean"]["output"];
  isImportant: Scalars["Boolean"]["output"];
  kpi: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  series: Array<Maybe<Scalars["Float"]["output"]>>;
};

export type IKeyPerformanceIndicators = {
  __typename: "KeyPerformanceIndicators";
  annual: Array<IKeyPerformanceIndicator>;
  currency: Scalars["Currency"]["output"];
  quarterly: Array<IKeyPerformanceIndicator>;
  reportDates: IKeyPerformanceIndicatorsReportDates;
};

export type IKeyPerformanceIndicatorsReportDates = {
  __typename: "KeyPerformanceIndicatorsReportDates";
  annual: Array<Scalars["Date"]["output"]>;
  isTrailingTwelveMonths: Array<Scalars["Boolean"]["output"]>;
  quarterly: Array<Scalars["Date"]["output"]>;
};

export type IKillSwitch = {
  __typename: "KillSwitch";
  isOn: Scalars["Boolean"]["output"];
  key: Scalars["ID"]["output"];
  objectId: Scalars["ID"]["output"];
};

export type ILabeledEvent = IAgendaEvent & {
  __typename: "LabeledEvent";
  _id: Scalars["ObjectID"]["output"];
  eventDate: Scalars["Date"]["output"];
  eventType: IAgendaEventType;
  issuer: Maybe<IIssuer>;
  label: Scalars["String"]["output"];
};

export type ILiquidity = IAmericanCompanyLiquidity | IBrazilianCompanyLiquidity | IBrazilianRealEstateFundLiquidity;

export type ILockableComment = {
  isLocked: Scalars["Boolean"]["output"];
  lockedAt: Maybe<Scalars["DateTime"]["output"]>;
  lockedBy: Maybe<IUser>;
};

export type ILockablePost = {
  isLocked: Scalars["Boolean"]["output"];
  lockedAt: Maybe<Scalars["DateTime"]["output"]>;
  lockedBy: Maybe<IUser>;
};

export type IMarketCap = {
  __typename: "MarketCap";
  asOfDate: Scalars["Date"]["output"];
  totalCapitalization: Scalars["Float"]["output"];
};

export type IMarketCapHistory = {
  __typename: "MarketCapHistory";
  nodes: Array<IMarketCapHistoryItem>;
  tickerSymbol: Scalars["String"]["output"];
};

export type IMarketCapHistoryItem = {
  __typename: "MarketCapHistoryItem";
  asOfDate: Scalars["Date"]["output"];
  enterpriseValue: Scalars["Float"]["output"];
  exchangeId: Scalars["String"]["output"];
  tickerSymbol: Scalars["String"]["output"];
  totalCapitalization: Scalars["Float"]["output"];
};

export type IMonthlyBrazilianRealEstateFundFinancialStatementRow = {
  __typename: "MonthlyBrazilianRealEstateFundFinancialStatementRow";
  capitalWithLiquidity: Scalars["Float"]["output"];
  cdi: Scalars["Float"]["output"];
  distributedIncome: Scalars["Float"]["output"];
  dividendYield: Scalars["Float"]["output"];
  dividendYieldToCdiRatio: Scalars["Float"]["output"];
  equityValuePerQuota: Scalars["Float"]["output"];
  financialExpenses: Scalars["Float"]["output"];
  financialRevenue: Scalars["Float"]["output"];
  fiscalDate: Scalars["Date"]["output"];
  leverage: Scalars["Float"]["output"];
  netEquity: Scalars["Float"]["output"];
  netTotalFinancialFlow: Scalars["Float"]["output"];
  otherCosts: Scalars["Float"]["output"];
  performanceAndAdministrationTaxes: Scalars["Float"]["output"];
  totalAssets: Scalars["Float"]["output"];
  totalFinancialFlow: Scalars["Float"]["output"];
  totalFromOthers: Scalars["Float"]["output"];
  totalFromRents: Scalars["Float"]["output"];
  totalInvested: Scalars["Float"]["output"];
  totalLiabilities: Scalars["Float"]["output"];
  totalOfFundsOfFunds: Scalars["Float"]["output"];
  totalOfOtherInvestments: Scalars["Float"]["output"];
  totalOfQuotaholders: Scalars["Int"]["output"];
  totalOfQuotasIssued: Scalars["Int"]["output"];
  totalValueOfRealEstate: Scalars["Float"]["output"];
  yieldPerQuota: Scalars["Float"]["output"];
};

export type IMorningstarPerson = {
  __typename: "MorningstarPerson";
  age: Maybe<Scalars["Int"]["output"]>;
  biography: Scalars["String"]["output"];
  centralIndexKey: Scalars["ID"]["output"];
  executiveCompensationHistory: Array<IExecutiveCompensationHistoryItem>;
  firstName: Scalars["String"]["output"];
  fullName: Scalars["String"]["output"];
  gender: IPersonGender;
  id: Scalars["ID"]["output"];
  isChiefExecutiveOfficer: Scalars["Boolean"]["output"];
  isDirector: Scalars["Boolean"]["output"];
  isIndependentDirector: Scalars["Boolean"]["output"];
  isOfficer: Scalars["Boolean"]["output"];
  lastName: Scalars["String"]["output"];
  prefix: Maybe<Scalars["String"]["output"]>;
  reportDate: Maybe<Scalars["Date"]["output"]>;
  reportSource: Scalars["String"]["output"];
  reportedTitle: Maybe<Scalars["String"]["output"]>;
  standardTitle: Maybe<Scalars["String"]["output"]>;
  status: IMorningstarPersonStatus;
  suffix: Maybe<Scalars["String"]["output"]>;
};

export type IMorningstarPersonExecutiveCompensationHistoryArgs = {
  currency: InputMaybe<Scalars["String"]["input"]>;
};

export enum IMorningstarPersonStatus {
  Active = "ACTIVE",
  Deceased = "DECEASED",
  Inactive = "INACTIVE",
  Resigned = "RESIGNED",
  Retired = "RETIRED",
  Terminated = "TERMINATED",
}

export type IMutation = {
  __typename: "Mutation";
  addFinancialsTemplate: IFinancialsTemplate;
  addIssuerToBookmarks: IIssuer;
  addPositionToPortfolio: IAssetPortfolioPosition;
  addReaction: IReactable;
  addStar: IStarrable;
  batchSetDynamicConfiguration: Array<IDynamicConfiguration>;
  billingPortalUrl: Maybe<Scalars["URL"]["output"]>;
  cancelAndRefundSubscriptionById: Maybe<Scalars["Boolean"]["output"]>;
  changeAssetPortfolioSettings: IUser;
  changeCountry: Maybe<IUser>;
  changeFinancialsTemplateVisibility: IFinancialsTemplate;
  changePassword: Maybe<Scalars["Boolean"]["output"]>;
  changeUserSetting: IUserSettingsV2;
  changeUserSettings: IUserSettingsV2;
  checkoutUrl: Maybe<Scalars["URL"]["output"]>;
  clearSelectedCompaniesHistory: Maybe<Scalars["Void"]["output"]>;
  closeEduardoFiisCourseAdvertisement: IUser;
  closeEduardoFirstLiveAboutFiisAdvertisement: IUser;
  closeEduardoStocksCourseAdvertisement: IUser;
  closeMyProfitPartnership: IUser;
  closeNomadPartnership: IUser;
  closeRemessaOnlineDiscount: IUser;
  closeValuationAdvertisement: IUser;
  closeYearlyPlan: IUser;
  confirmEmailChange: Maybe<Scalars["Boolean"]["output"]>;
  connectWithGoogleViaAccessToken: Maybe<Scalars["Boolean"]["output"]>;
  createPost: Maybe<IPost>;
  destroyAssetDiscussionComment: Scalars["Boolean"]["output"];
  destroyAssetDiscussionCommentReply: Scalars["Boolean"]["output"];
  destroyPost: Scalars["Boolean"]["output"];
  destroyPostReply: Scalars["Boolean"]["output"];
  editAssetDiscussionComment: IAssetDiscussionComment;
  editAssetDiscussionCommentReply: IAssetDiscussionCommentReply;
  editFinancialsTemplate: IFinancialsTemplate;
  editPost: Maybe<IPost>;
  editPostReply: Maybe<IPostReply>;
  emitSignal: Scalars["Boolean"]["output"];
  evaluateFlags: Array<IEvaluatedFeatureFlagVariant>;
  finishSubscriptionTrial: Maybe<IStripeSubscription>;
  forgotPassword: Maybe<Scalars["Boolean"]["output"]>;
  importPositionsToPortfolio: Array<IAssetPortfolioPosition>;
  keepSubscriptionById: Maybe<IStripeSubscription>;
  killSwitch: IKillSwitch;
  lockAssetDiscussionComment: IAssetDiscussionComment;
  lockPost: Maybe<IPost>;
  loginWithCredentials: Maybe<IAuthenticationResult>;
  loginWithGoogleAccessToken: Maybe<IAuthenticationResult>;
  loginWithGoogleIdToken: Maybe<IAuthenticationResult>;
  notifyAboutMediaPlaybackState: Scalars["ID"]["output"];
  now: Maybe<Scalars["BigInt"]["output"]>;
  permanentlyDeleteArchivedTemplates: Scalars["Boolean"]["output"];
  permanentlyDeleteFinancialsTemplate: IFinancialsTemplate;
  pinAssetDiscussionComment: IAssetDiscussionComment;
  pinPost: Maybe<IPost>;
  pinPostReply: Maybe<IPostReply>;
  postCommentOnAsset: IAssetDiscussionComment;
  /** Add, change or delete the rating for the specified Issuer */
  rateIssuer: Maybe<IRankedIssuer>;
  registerUserInteraction: Maybe<Scalars["Void"]["output"]>;
  removeAllPortfolioPositions: Scalars["Boolean"]["output"];
  removePositionFromPortfolio: Scalars["Boolean"]["output"];
  removePositionFromPortfolioByTickerSymbol: Scalars["Boolean"]["output"];
  removeReaction: IReactable;
  removeStar: IStarrable;
  renameUser: Maybe<IUser>;
  replyToAssetDiscussionComment: IAssetDiscussionCommentReply;
  replyToPost: Maybe<IPostReply>;
  requestEmailChange: Maybe<Scalars["Boolean"]["output"]>;
  resetPassword: Maybe<Scalars["Boolean"]["output"]>;
  saveIssuerNotes: Maybe<IUserNotes>;
  sendSupportMessage: Maybe<Scalars["Boolean"]["output"]>;
  setAsDefaultFinancialsTemplate: IFinancialsTemplate;
  setDynamicConfiguration: IDynamicConfiguration;
  setPassword: Maybe<IUser>;
  setPostReplyAnswer: Maybe<IPostReply>;
  signInWithGoogleOAuthIdToken: Maybe<IAuthenticationResult>;
  signUpFromGoogleOAuthAccessToken: Maybe<IAuthenticationResult>;
  signUpFromGoogleOAuthIdToken: Maybe<IAuthenticationResult>;
  signUpFromRegistrationForm: Maybe<IAuthenticationResult>;
  submitBrazilianBankQuarterlyFinancials: Maybe<IBrazilianCompanyIssuer>;
  submitBrazilianBankYearlyFinancials: Maybe<IBrazilianCompanyIssuer>;
  submitBrazilianCompanyQuarterlyFinancials: Maybe<IBrazilianCompanyIssuer>;
  submitBrazilianCompanyYearlyFinancials: Maybe<IBrazilianCompanyIssuer>;
  submitBrazilianInsurerQuarterlyFinancials: Maybe<IBrazilianCompanyIssuer>;
  submitBrazilianInsurerYearlyFinancials: Maybe<IBrazilianCompanyIssuer>;
  throw: Maybe<Scalars["BigInt"]["output"]>;
  toggleArchivedOnFinancialsTemplate: IFinancialsTemplate;
  toggleBookmarkForIndicator: IUser;
  toggleBookmarkOnIssuer: IIssuer;
  toggleCompletedOnLesson: IBlackCourseLesson;
  unfurl: Array<Maybe<IUnfurl>>;
  unfurlFromText: Array<IUnfurl>;
  unlockAssetDiscussionComment: IAssetDiscussionComment;
  unlockPost: Maybe<IPost>;
  unpinAssetDiscussionComment: IAssetDiscussionComment;
  unpinPost: Maybe<IPost>;
  unpinPostReply: Maybe<IPostReply>;
  unsetPostReplyAnswer: Maybe<IPostReply>;
  updateAssetPortfolioPositions: Array<IAssetPortfolioPosition>;
  uploadFile: Maybe<IRawUploadedFileDetails>;
  verifyEmail: Maybe<Scalars["Boolean"]["output"]>;
};

export type IMutationAddFinancialsTemplateArgs = {
  input: IFinancialsTemplateInput;
};

export type IMutationAddIssuerToBookmarksArgs = {
  issuerId: Scalars["ObjectID"]["input"];
};

export type IMutationAddPositionToPortfolioArgs = {
  input: IAddPositionToPortfolioInput;
};

export type IMutationAddReactionArgs = {
  input: IAddReactionInput;
};

export type IMutationAddStarArgs = {
  input: IAddStarInput;
};

export type IMutationBatchSetDynamicConfigurationArgs = {
  configurations: Array<IDynamicConfigurationInput>;
};

export type IMutationCancelAndRefundSubscriptionByIdArgs = {
  stripeSubscriptionId: Scalars["ID"]["input"];
};

export type IMutationChangeAssetPortfolioSettingsArgs = {
  input: IUserAssetPortfolioSettingsInput;
};

export type IMutationChangeCountryArgs = {
  country: Scalars["String"]["input"];
};

export type IMutationChangeFinancialsTemplateVisibilityArgs = {
  input: IChangeFinancialsTemplateVisibilityInput;
  objectId: Scalars["FinancialsTemplateID"]["input"];
};

export type IMutationChangePasswordArgs = {
  newPassword: Scalars["String"]["input"];
  oldPassword: Scalars["String"]["input"];
};

export type IMutationChangeUserSettingArgs = {
  key: Scalars["ID"]["input"];
  value: Scalars["JSON"]["input"];
};

export type IMutationChangeUserSettingsArgs = {
  input: Scalars["JSON"]["input"];
};

export type IMutationCheckoutUrlArgs = {
  coupon: InputMaybe<Scalars["ID"]["input"]>;
  currency: InputMaybe<Scalars["String"]["input"]>;
  parameters: InputMaybe<ICheckoutParametersInput>;
  product: ICheckoutProduct;
};

export type IMutationConfirmEmailChangeArgs = {
  token: Scalars["JWT"]["input"];
};

export type IMutationConnectWithGoogleViaAccessTokenArgs = {
  accessToken: Scalars["String"]["input"];
};

export type IMutationCreatePostArgs = {
  bodyAsJson: Scalars["JSON"]["input"];
  bodyAsPlainText: Scalars["String"]["input"];
  contextKey: InputMaybe<Scalars["String"]["input"]>;
  contextType: IPostContextType;
  title: InputMaybe<Scalars["String"]["input"]>;
};

export type IMutationDestroyAssetDiscussionCommentArgs = {
  commentId: Scalars["ObjectID"]["input"];
};

export type IMutationDestroyAssetDiscussionCommentReplyArgs = {
  replyId: Scalars["ObjectID"]["input"];
};

export type IMutationDestroyPostArgs = {
  objectId: Scalars["ID"]["input"];
};

export type IMutationDestroyPostReplyArgs = {
  objectId: Scalars["ID"]["input"];
};

export type IMutationEditAssetDiscussionCommentArgs = {
  bodyAsPlainText: Scalars["String"]["input"];
  commentId: Scalars["ObjectID"]["input"];
};

export type IMutationEditAssetDiscussionCommentReplyArgs = {
  bodyAsPlainText: Scalars["String"]["input"];
  replyId: Scalars["ObjectID"]["input"];
};

export type IMutationEditFinancialsTemplateArgs = {
  input: IFinancialsTemplateInput;
  objectId: Scalars["FinancialsTemplateID"]["input"];
};

export type IMutationEditPostArgs = {
  bodyAsJson: Scalars["JSON"]["input"];
  bodyAsPlainText: Scalars["String"]["input"];
  objectId: Scalars["ID"]["input"];
  title: InputMaybe<Scalars["String"]["input"]>;
};

export type IMutationEditPostReplyArgs = {
  bodyAsJson: Scalars["JSON"]["input"];
  bodyAsPlainText: Scalars["String"]["input"];
  objectId: Scalars["ID"]["input"];
};

export type IMutationEmitSignalArgs = {
  payload: Scalars["JSON"]["input"];
  signal: ISignalType;
};

export type IMutationEvaluateFlagsArgs = {
  keys: Array<Scalars["ID"]["input"]>;
};

export type IMutationFinishSubscriptionTrialArgs = {
  stripeSubscriptionId: InputMaybe<Scalars["ID"]["input"]>;
};

export type IMutationForgotPasswordArgs = {
  email: Scalars["EmailAddress"]["input"];
};

export type IMutationImportPositionsToPortfolioArgs = {
  input: Array<IImportPositionsToPortfolioInput>;
};

export type IMutationKeepSubscriptionByIdArgs = {
  stripeSubscriptionId: Scalars["ID"]["input"];
};

export type IMutationKillSwitchArgs = {
  isOn: Scalars["Boolean"]["input"];
  key: Scalars["ID"]["input"];
};

export type IMutationLockAssetDiscussionCommentArgs = {
  commentId: Scalars["ObjectID"]["input"];
};

export type IMutationLockPostArgs = {
  objectId: Scalars["ID"]["input"];
};

export type IMutationLoginWithCredentialsArgs = {
  email: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
};

export type IMutationLoginWithGoogleAccessTokenArgs = {
  accessToken: Scalars["String"]["input"];
};

export type IMutationLoginWithGoogleIdTokenArgs = {
  idToken: Scalars["String"]["input"];
};

export type IMutationNotifyAboutMediaPlaybackStateArgs = {
  input: IBlackMediaPlaybackStateInput;
};

export type IMutationPermanentlyDeleteFinancialsTemplateArgs = {
  objectId: Scalars["FinancialsTemplateID"]["input"];
};

export type IMutationPinAssetDiscussionCommentArgs = {
  commentId: Scalars["ObjectID"]["input"];
};

export type IMutationPinPostArgs = {
  objectId: Scalars["ID"]["input"];
};

export type IMutationPinPostReplyArgs = {
  objectId: Scalars["ID"]["input"];
};

export type IMutationPostCommentOnAssetArgs = {
  assetId: Scalars["ObjectID"]["input"];
  assetType: IAssetType;
  bodyAsPlainText: Scalars["String"]["input"];
  country: Scalars["String"]["input"];
};

export type IMutationRateIssuerArgs = {
  input: IRatingInput;
};

export type IMutationRegisterUserInteractionArgs = {
  input: IUserInteractionInput;
};

export type IMutationRemovePositionFromPortfolioArgs = {
  positionId: Scalars["ObjectID"]["input"];
};

export type IMutationRemovePositionFromPortfolioByTickerSymbolArgs = {
  tickerSymbol: Scalars["TickerSymbol"]["input"];
};

export type IMutationRemoveReactionArgs = {
  input: IRemoveReactionInput;
};

export type IMutationRemoveStarArgs = {
  input: IRemoveStarInput;
};

export type IMutationRenameUserArgs = {
  firstName: Scalars["String"]["input"];
  lastName: Scalars["String"]["input"];
};

export type IMutationReplyToAssetDiscussionCommentArgs = {
  bodyAsPlainText: Scalars["String"]["input"];
  commentId: Scalars["ObjectID"]["input"];
};

export type IMutationReplyToPostArgs = {
  bodyAsJson: Scalars["JSON"]["input"];
  bodyAsPlainText: Scalars["String"]["input"];
  postId: Scalars["ID"]["input"];
};

export type IMutationRequestEmailChangeArgs = {
  toEmail: Scalars["EmailAddress"]["input"];
};

export type IMutationResetPasswordArgs = {
  newPassword: Scalars["String"]["input"];
  token: Scalars["String"]["input"];
};

export type IMutationSaveIssuerNotesArgs = {
  bodyAsJson: InputMaybe<Scalars["JSON"]["input"]>;
  bodyAsPlainText: InputMaybe<Scalars["String"]["input"]>;
  issuerId: Scalars["ObjectID"]["input"];
};

export type IMutationSendSupportMessageArgs = {
  message: ISupportMessageInput;
};

export type IMutationSetAsDefaultFinancialsTemplateArgs = {
  input: ISetAsDefaultFinancialsTemplateInput;
  objectId: Scalars["FinancialsTemplateID"]["input"];
};

export type IMutationSetDynamicConfigurationArgs = {
  key: Scalars["String"]["input"];
  value: Scalars["JSON"]["input"];
};

export type IMutationSetPasswordArgs = {
  newPassword: Scalars["String"]["input"];
};

export type IMutationSetPostReplyAnswerArgs = {
  objectId: Scalars["ID"]["input"];
};

export type IMutationSignInWithGoogleOAuthIdTokenArgs = {
  idToken: Scalars["String"]["input"];
  utms: InputMaybe<IUtmParametersInput>;
};

export type IMutationSignUpFromGoogleOAuthAccessTokenArgs = {
  accessToken: Scalars["String"]["input"];
  utms: InputMaybe<IUtmParametersInput>;
};

export type IMutationSignUpFromGoogleOAuthIdTokenArgs = {
  idToken: Scalars["String"]["input"];
  utms: InputMaybe<IUtmParametersInput>;
};

export type IMutationSignUpFromRegistrationFormArgs = {
  registrationForm: IUserRegistrationFormInput;
  utms: InputMaybe<IUtmParametersInput>;
};

export type IMutationSubmitBrazilianBankQuarterlyFinancialsArgs = {
  aggregation: IBrazilianCompanyFinancialStatementsAggregation;
  companyId: Scalars["ObjectID"]["input"];
  financials: Array<IBrazilianBankQuarterlyFinancialsInput>;
};

export type IMutationSubmitBrazilianBankYearlyFinancialsArgs = {
  aggregation: IBrazilianCompanyFinancialStatementsAggregation;
  companyId: Scalars["ObjectID"]["input"];
  financials: Array<IBrazilianBankYearlyFinancialsInput>;
};

export type IMutationSubmitBrazilianCompanyQuarterlyFinancialsArgs = {
  aggregation: IBrazilianCompanyFinancialStatementsAggregation;
  companyId: Scalars["ObjectID"]["input"];
  financials: Array<IBrazilianCompanyQuarterlyFinancialsInput>;
};

export type IMutationSubmitBrazilianCompanyYearlyFinancialsArgs = {
  aggregation: IBrazilianCompanyFinancialStatementsAggregation;
  companyId: Scalars["ObjectID"]["input"];
  financials: Array<IBrazilianCompanyYearlyFinancialsInput>;
};

export type IMutationSubmitBrazilianInsurerQuarterlyFinancialsArgs = {
  aggregation: IBrazilianCompanyFinancialStatementsAggregation;
  companyId: Scalars["ObjectID"]["input"];
  financials: Array<IBrazilianInsurerQuarterlyFinancialsInput>;
};

export type IMutationSubmitBrazilianInsurerYearlyFinancialsArgs = {
  aggregation: IBrazilianCompanyFinancialStatementsAggregation;
  companyId: Scalars["ObjectID"]["input"];
  financials: Array<IBrazilianInsurerYearlyFinancialsInput>;
};

export type IMutationToggleArchivedOnFinancialsTemplateArgs = {
  objectId: Scalars["FinancialsTemplateID"]["input"];
};

export type IMutationToggleBookmarkForIndicatorArgs = {
  input: IValuationIndicator;
};

export type IMutationToggleBookmarkOnIssuerArgs = {
  issuerId: Scalars["ObjectID"]["input"];
};

export type IMutationToggleCompletedOnLessonArgs = {
  input: IToggleCompletedOnLessonInput;
};

export type IMutationUnfurlArgs = {
  urls: Array<Scalars["URL"]["input"]>;
};

export type IMutationUnfurlFromTextArgs = {
  text: Scalars["String"]["input"];
};

export type IMutationUnlockAssetDiscussionCommentArgs = {
  commentId: Scalars["ObjectID"]["input"];
};

export type IMutationUnlockPostArgs = {
  objectId: Scalars["ID"]["input"];
};

export type IMutationUnpinAssetDiscussionCommentArgs = {
  commentId: Scalars["ObjectID"]["input"];
};

export type IMutationUnpinPostArgs = {
  objectId: Scalars["ID"]["input"];
};

export type IMutationUnpinPostReplyArgs = {
  objectId: Scalars["ID"]["input"];
};

export type IMutationUnsetPostReplyAnswerArgs = {
  objectId: Scalars["ID"]["input"];
};

export type IMutationUpdateAssetPortfolioPositionsArgs = {
  input: Array<IUpdateAssetPortfolioPositionInput>;
};

export type IMutationUploadFileArgs = {
  file: Scalars["Upload"]["input"];
};

export type IMutationVerifyEmailArgs = {
  token: Scalars["String"]["input"];
};

export type INareitClassification = {
  __typename: "NAREITClassification";
  investmentSector: Maybe<Scalars["String"]["output"]>;
  propertySector: Maybe<Scalars["String"]["output"]>;
  propertySubsector: Maybe<Scalars["String"]["output"]>;
};

export type INextUpcomingEventDateRange = {
  __typename: "NextUpcomingEventDateRange";
  filterByEndDate: Scalars["Date"]["output"];
  filterByStartDate: Scalars["Date"]["output"];
};

export type INode = {
  id: Scalars["ID"]["output"];
};

export type INumberFormatOptions = {
  __typename: "NumberFormatOptions";
  compactDisplay: Scalars["String"]["output"];
  maximumFractionDigits: Scalars["Int"]["output"];
  maximumSignificantDigits: Scalars["Int"]["output"];
  minimumFractionDigits: Scalars["Int"]["output"];
  minimumIntegerDigits: Scalars["Int"]["output"];
  minimumSignificantDigits: Scalars["Int"]["output"];
  notation: Scalars["String"]["output"];
  style: Scalars["String"]["output"];
};

export enum IOrderDirection {
  Ascending = "ASCENDING",
  Descending = "DESCENDING",
}

export type IPageInfo = {
  __typename: "PageInfo";
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  hasNextPage: Scalars["Boolean"]["output"];
};

export type IPaginatedAgendaEvent = {
  __typename: "PaginatedAgendaEvent";
  breakdown: IAgendaBreakdown;
  nextUpcomingEventDateRange: Maybe<INextUpcomingEventDateRange>;
  nodes: Array<IAgendaEvent>;
  pageInfo: IPageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type IPaginatedBookmarks = {
  __typename: "PaginatedBookmarks";
  breakdown: IBookmarksBreakdown;
  nodes: Array<IBookmark>;
  pageInfo: IPageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type IPaginatedCvmMaterialFactList = {
  __typename: "PaginatedCvmMaterialFactList";
  nodes: Array<ICvmMaterialFact>;
  pageInfo: IPageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type IPaginatedFinancialsTemplates = {
  __typename: "PaginatedFinancialsTemplates";
  breakdown: IFinancialsTemplateBreakdown;
  nodes: Array<IFinancialsTemplate>;
  pageInfo: IPageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type IPaginatedRanking = {
  __typename: "PaginatedRanking";
  /** Pagination details */
  pageInfo: IPageInfo;
  /** A list of all participants in the ranking. In order for an Issuer to participate it must have at least 10 ratings */
  participants: Array<IRankingParticipant>;
  /** Is the ending date of which the ratings were evaluated */
  periodEndDate: Scalars["Date"]["output"];
  /** Is the start date of which the ratings were evaluated */
  periodStartDate: Scalars["Date"]["output"];
  /** Total members in the whole ranking */
  totalCount: Scalars["Int"]["output"];
};

export type IPayRatioItem = {
  __typename: "PayRatioItem";
  additionalDescription: Maybe<Scalars["String"]["output"]>;
  ceoPay: Scalars["Float"]["output"];
  currency: Scalars["String"]["output"];
  fiscalYear: Scalars["Int"]["output"];
  medianEmployeePay: Scalars["Float"]["output"];
  payRatio: Scalars["Float"]["output"];
  payRatioCompensationMethod: Maybe<Scalars["String"]["output"]>;
  reportDate: Scalars["Date"]["output"];
  reportSource: Scalars["String"]["output"];
};

export type IPaymentOfDividendsEvent = IAgendaEvent & {
  __typename: "PaymentOfDividendsEvent";
  _id: Scalars["ObjectID"]["output"];
  amountPaid: Scalars["Float"]["output"];
  declarationDate: Scalars["Date"]["output"];
  eventDate: Scalars["Date"]["output"];
  eventType: IAgendaEventType;
  excludingDate: Scalars["Date"]["output"];
  issuer: Maybe<IIssuer>;
  payDate: Maybe<Scalars["Date"]["output"]>;
  recordDate: Scalars["Date"]["output"];
  symbol: Scalars["String"]["output"];
};

export type IPaymentOfStockDividendsEvent = IAgendaEvent & {
  __typename: "PaymentOfStockDividendsEvent";
  _id: Scalars["ObjectID"]["output"];
  eventDate: Scalars["Date"]["output"];
  eventType: IAgendaEventType;
  excludingDate: Scalars["Date"]["output"];
  issuer: Maybe<IIssuer>;
  sharePaid: Scalars["Float"]["output"];
  symbol: Scalars["String"]["output"];
};

export type IPerEmployeeRatiosHistoryItem = {
  __typename: "PerEmployeeRatiosHistoryItem";
  asOfDate: Scalars["Date"]["output"];
  netIncomePerFullTimeEmployee: Scalars["Float"]["output"];
  revenuePerEmployee: Scalars["Float"]["output"];
  totalOfEmployees: Scalars["Int"]["output"];
};

export enum IPersonGender {
  Female = "FEMALE",
  Male = "MALE",
}

export type IPinnableComment = {
  isPinned: Scalars["Boolean"]["output"];
  pinnedAt: Maybe<Scalars["DateTime"]["output"]>;
  pinnedBy: Maybe<IUser>;
};

export type IPinnablePost = {
  isPinned: Scalars["Boolean"]["output"];
  pinnedAt: Maybe<Scalars["DateTime"]["output"]>;
  pinnedBy: Maybe<IUser>;
};

export enum IPlatformVertical {
  Acoes = "ACOES",
  All = "ALL",
  Fiis = "FIIS",
  Reits = "REITS",
  Stocks = "STOCKS",
}

export enum IPlaybackFocusState {
  Focused = "FOCUSED",
  Unfocused = "UNFOCUSED",
}

export enum IPlaybackPlayerState {
  Paused = "PAUSED",
  Playing = "PLAYING",
  Stopped = "STOPPED",
}

export type IPortfolioAsset =
  | IAmericanCompanyPortfolioAsset
  | IAmericanReitPortfolioAsset
  | IBrazilianCompanyPortfolioAsset
  | IBrazilianRealEstateFundPortfolioAsset;

export type IPost = {
  _id: Scalars["ObjectID"]["output"];
  author: IUser;
  bodyAsJson: Scalars["JSON"]["output"];
  bodyAsPlainText: Scalars["String"]["output"];
  contextKey: Maybe<Scalars["String"]["output"]>;
  contextType: IPostContextType;
  createdAt: Scalars["DateTime"]["output"];
  objectId: Scalars["ID"]["output"];
  participants: IPostParticipants;
  replies: IPostRepliesPagination;
  shortId: Scalars["ID"]["output"];
  viewerCanDestroy: Scalars["Boolean"]["output"];
  viewerCanEdit: Scalars["Boolean"]["output"];
  viewerCanReply: Scalars["Boolean"]["output"];
  viewerCanViewReplies: Scalars["Boolean"]["output"];
  viewerDidAuthor: Scalars["Boolean"]["output"];
};

export type IPostParticipantsArgs = {
  top: Scalars["Int"]["input"];
};

export type IPostRepliesArgs = {
  cursor: InputMaybe<Scalars["Cursor"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy: InputMaybe<IPostOrder>;
};

export type IPostContentEdit = {
  __typename: "PostContentEdit";
  bodyAsPlainText: Scalars["String"]["output"];
  editedAt: Scalars["DateTime"]["output"];
  editor: IUser;
};

export enum IPostContextType {
  BlackForum = "BLACK_FORUM",
  BlackLesson = "BLACK_LESSON",
  IrpfForum = "IRPF_FORUM",
}

export type IPostEngagement = {
  __typename: "PostEngagement";
  totalReplies: Scalars["Int"]["output"];
};

export enum IPostFilterBy {
  OwnComments = "OWN_COMMENTS",
  Participating = "PARTICIPATING",
}

export type IPostOrder = {
  direction: IOrderDirection;
  field: IPostOrderField;
};

export enum IPostOrderField {
  CreatedAt = "CREATED_AT",
  MostReacted = "MOST_REACTED",
  MostReplied = "MOST_REPLIED",
}

export type IPostPagination = {
  __typename: "PostPagination";
  nodes: Array<IPost>;
  pageInfo: IPageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type IPostParticipants = {
  __typename: "PostParticipants";
  nodes: Array<IUser>;
  totalCount: Scalars["Int"]["output"];
};

export type IPostRepliesPagination = {
  __typename: "PostRepliesPagination";
  nodes: Array<IPostReply>;
  pageInfo: IPageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type IPostReply = IAnswerablePost &
  IEditablePost &
  IPinnablePost &
  IReactable & {
    __typename: "PostReply";
    _id: Scalars["ObjectID"]["output"];
    answeredAt: Maybe<Scalars["DateTime"]["output"]>;
    answeredBy: Maybe<IUser>;
    author: IUser;
    bodyAsJson: Scalars["JSON"]["output"];
    bodyAsPlainText: Scalars["String"]["output"];
    contextKey: Maybe<Scalars["String"]["output"]>;
    contextType: IPostContextType;
    createdAt: Scalars["DateTime"]["output"];
    editHistory: Array<IPostContentEdit>;
    isPinned: Scalars["Boolean"]["output"];
    objectId: Scalars["ID"]["output"];
    originalBodyAsPlainText: Scalars["String"]["output"];
    pinnedAt: Maybe<Scalars["DateTime"]["output"]>;
    pinnedBy: Maybe<IUser>;
    reactionGroups: Array<IReactionGroup>;
    shortId: Scalars["ID"]["output"];
    updatedAt: Maybe<Scalars["DateTime"]["output"]>;
    viewerCanDestroy: Scalars["Boolean"]["output"];
    viewerCanEdit: Scalars["Boolean"]["output"];
    viewerCanReact: Scalars["Boolean"]["output"];
    viewerDidAuthor: Scalars["Boolean"]["output"];
  };

export type IQuarterlyBrazilianBankFinancialStatementRow = IBrazilianBankFinancialStatementRow & {
  __typename: "QuarterlyBrazilianBankFinancialStatementRow";
  bisRatio: Scalars["BigInt"]["output"];
  cashFlowForDividends: Scalars["BigInt"]["output"];
  cashFlowForDividendsToNetIncomeRatio: Scalars["Float"]["output"];
  endOfPeriod: Scalars["Date"]["output"];
  financialIntermediationRevenue: Scalars["BigInt"]["output"];
  fiscalYear: Scalars["String"]["output"];
  humanReadableQuarter: Scalars["String"]["output"];
  incomeTaxes: Scalars["BigInt"]["output"];
  netEquity: Scalars["BigInt"]["output"];
  netIncome: Scalars["BigInt"]["output"];
  netIncomeFromDiscontinuedOperations: Scalars["BigInt"]["output"];
  netMargin: Scalars["Float"]["output"];
  payout: Scalars["BigInt"]["output"];
  payoutToNetIncomeRatio: Scalars["BigInt"]["output"];
  provisionForCreditLosses: Scalars["BigInt"]["output"];
  provisionForCreditLossesToNetIncomeRatio: Scalars["BigInt"]["output"];
  quarterSequence: Scalars["Int"]["output"];
  returnOnEquity: Scalars["Float"]["output"];
  startOfPeriod: Scalars["Date"]["output"];
  totalAssets: Scalars["BigInt"]["output"];
};

export type IQuarterlyBrazilianCompanyFinancialStatementRow = IBrazilianCompanyFinancialStatementRow & {
  __typename: "QuarterlyBrazilianCompanyFinancialStatementRow";
  capex: Scalars["BigInt"]["output"];
  capexToCashFlowFromOperationsRatio: Scalars["Float"]["output"];
  cash: Scalars["BigInt"]["output"];
  cashFlowForDividends: Scalars["BigInt"]["output"];
  cashFlowForDividendsToNetIncomeRatio: Scalars["Float"]["output"];
  cashFlowFromFinancing: Scalars["BigInt"]["output"];
  cashFlowFromOperations: Scalars["BigInt"]["output"];
  debt: Scalars["BigInt"]["output"];
  debtToNetEquityRatio: Scalars["Float"]["output"];
  depreciationAndAmortization: Scalars["BigInt"]["output"];
  ebit: Scalars["BigInt"]["output"];
  ebitToNetRevenueRatio: Scalars["Float"]["output"];
  ebitda: Scalars["BigInt"]["output"];
  endOfPeriod: Scalars["Date"]["output"];
  financialResult: Scalars["BigInt"]["output"];
  fiscalYear: Scalars["String"]["output"];
  freeCashFlow: Scalars["BigInt"]["output"];
  humanReadableQuarter: Scalars["String"]["output"];
  incomeTaxes: Scalars["BigInt"]["output"];
  incomeTaxesToTaxableProfitRatio: Scalars["BigInt"]["output"];
  netDebt: Scalars["BigInt"]["output"];
  netDebtToEbitdaRatio: Scalars["Float"]["output"];
  netEquity: Scalars["BigInt"]["output"];
  netIncome: Scalars["BigInt"]["output"];
  netIncomeFromDiscontinuedOperations: Scalars["BigInt"]["output"];
  netMargin: Scalars["Float"]["output"];
  netRevenue: Scalars["BigInt"]["output"];
  payout: Scalars["BigInt"]["output"];
  payoutToNetIncomeRatio: Scalars["BigInt"]["output"];
  quarterSequence: Scalars["Int"]["output"];
  returnOnEquity: Scalars["Float"]["output"];
  startOfPeriod: Scalars["Date"]["output"];
};

export type IQuarterlyBrazilianCompanyFinancials =
  | IQuarterlyBrazilianBankFinancialStatementRow
  | IQuarterlyBrazilianCompanyFinancialStatementRow
  | IQuarterlyBrazilianInsurerFinancialStatementRow;

export type IQuarterlyBrazilianInsurerFinancialStatementRow = IBrazilianInsurerFinancialStatementRow & {
  __typename: "QuarterlyBrazilianInsurerFinancialStatementRow";
  capex: Scalars["BigInt"]["output"];
  capexToCashFlowFromOperationsRatio: Scalars["Float"]["output"];
  cash: Scalars["BigInt"]["output"];
  cashFlowForDividends: Scalars["BigInt"]["output"];
  cashFlowForDividendsToNetIncomeRatio: Scalars["Float"]["output"];
  cashFlowFromFinancing: Scalars["BigInt"]["output"];
  cashFlowFromOperations: Scalars["BigInt"]["output"];
  debt: Scalars["BigInt"]["output"];
  debtToNetEquityRatio: Scalars["Float"]["output"];
  depreciationAndAmortization: Scalars["BigInt"]["output"];
  ebit: Scalars["BigInt"]["output"];
  ebitToNetRevenueRatio: Scalars["Float"]["output"];
  ebitda: Scalars["BigInt"]["output"];
  endOfPeriod: Scalars["Date"]["output"];
  financialResult: Scalars["BigInt"]["output"];
  fiscalYear: Scalars["String"]["output"];
  freeCashFlow: Scalars["BigInt"]["output"];
  humanReadableQuarter: Scalars["String"]["output"];
  incomeTaxes: Scalars["BigInt"]["output"];
  incomeTaxesToTaxableProfitRatio: Scalars["BigInt"]["output"];
  netDebt: Scalars["BigInt"]["output"];
  netDebtToEbitdaRatio: Scalars["Float"]["output"];
  netEquity: Scalars["BigInt"]["output"];
  netIncome: Scalars["BigInt"]["output"];
  netIncomeFromDiscontinuedOperations: Scalars["BigInt"]["output"];
  netMargin: Scalars["Float"]["output"];
  netRevenue: Scalars["BigInt"]["output"];
  payout: Scalars["BigInt"]["output"];
  payoutToNetIncomeRatio: Scalars["BigInt"]["output"];
  quarterSequence: Scalars["Int"]["output"];
  returnOnEquity: Scalars["Float"]["output"];
  startOfPeriod: Scalars["Date"]["output"];
};

export type IQuarterlyNetIncomeDifferential = {
  __typename: "QuarterlyNetIncomeDifferential";
  fiscalDate: Scalars["Date"]["output"];
  netIncome: Scalars["BigInt"]["output"];
};

export type IQuery = {
  __typename: "Query";
  agenda: IPaginatedAgendaEvent;
  americanIssuerSpecialDividendsPaid: Array<IAmericanIssuerSpecialDividendsCorporateAction>;
  assetById: Maybe<IAsset>;
  assetDiscussionCommentByShortId: Maybe<IAssetDiscussionComment>;
  assetPortfolioPositions: Array<IAssetPortfolioPosition>;
  assetPortfolioRanking: Array<IRankedCompanySnapshot>;
  assetsListing: Array<IBrazilianCompanyIssuer>;
  availableCheckoutProducts: Array<ICheckoutProduct>;
  blackVideoById: ICloudflareBlackVideo;
  bookmarks: IPaginatedBookmarks;
  committedRevisions: Array<IGitHubRepositoryCommit>;
  companyIssuers: Array<ICompanyIssuer>;
  couponById: Maybe<IStripeCoupon>;
  courseById: IBlackCourse;
  courseBySlug: IBlackCourse;
  courses: IBlackCoursesConnection;
  cvmMaterialFacts: IPaginatedCvmMaterialFactList;
  discussionCommentsByAsset: IAssetDiscussionCommentPagination;
  discussionCommentsFeed: IAssetDiscussionCommentPagination;
  discussionsLeaderboard: Array<IDiscussionsLeaderboardNode>;
  dividendsPaid: IDividendsCorporateAction;
  dumpHttpRequestContext: Maybe<Scalars["JSON"]["output"]>;
  dynamicConfigurationByKeys: Array<IDynamicConfiguration>;
  featureFlags: Scalars["JSONObject"]["output"];
  financialBusinessSegments: Maybe<IFinancialSegments>;
  financialGeographicSegments: Maybe<IFinancialSegments>;
  financialHighlights: Maybe<IFinancialHighlightsHistory>;
  financialsAvailability: Array<IFinancialsAvailability>;
  financialsSeries: IFinancialsSeriesSheet;
  financialsSheet: IFinancialsSheet;
  financialsSheetForComparison: IFinancialsComparisonSheet;
  financialsTemplates: IPaginatedFinancialsTemplates;
  forexRates: Array<IForexRate>;
  freeFloatHistory: Array<IFreeFloatHistoryItem>;
  git: Maybe<IGitDetails>;
  indicators: Array<IIndicator>;
  issuerByTickerSymbol: Maybe<IIssuer>;
  issuerPeers: Array<IIssuerSearchResultNode>;
  killSwitchboard: Scalars["JSON"]["output"];
  killSwitches: Array<IKillSwitch>;
  kpis: Maybe<IKeyPerformanceIndicators>;
  liquidityByTickerSymbol: Maybe<ILiquidity>;
  marketCapitalizationHistory: Array<IMarketCapHistoryItem>;
  marketCapitalizationHistoryByTickerSymbols: Array<IMarketCapHistory>;
  mostRepliedPosts: Array<IPost>;
  node: Maybe<INode>;
  nodes: Array<INode>;
  now: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * Get the ongoing live stream for the channels we want to notify the users for. Could be our channel "Fundamentei"
   * or Arthur's channel
   */
  ongoingYouTubeLiveStream: Maybe<IYouTubeLiveStream>;
  perEmployeeRatiosHistory: Array<IPerEmployeeRatiosHistoryItem>;
  portfolioResultsCenter: Array<IAssetPortfolioResultsCenterItem>;
  postByShortId: Maybe<IPost>;
  postReplyByShortId: Maybe<IPostReply>;
  posts: IPostPagination;
  premiumPrices: Array<IStripePrice>;
  quotesHistory: Array<IQuotesHistory>;
  /**
   * Is for retrieving a snapshot of the general ranking. In order for an Issuer to appear in the ranking it must have
   * at least 10 ratings within the evaluation period
   */
  rankingV2: IPaginatedRanking;
  realEstateFundByTickerSymbol: Maybe<IBrazilianRealEstateFund>;
  relatedPeers: Maybe<IRelatedPeersBracket>;
  rightsIssues: Array<IRightsIssuesCorporateAction>;
  searchIssuers: Array<IIssuerSearchResultNode>;
  searchIssuersByTickerSymbol: Array<ICompanyIssuer>;
  /** Pulls a list of all available sectors for a specific issuer (Ações BR, Stocks, FIIs, REITs) ranking */
  sectorsForRanking: Array<Scalars["String"]["output"]>;
  securityByTickerSymbol: Maybe<ISecurity>;
  selectedCompaniesHistory: Array<ICompanyIssuer>;
  selfie: Maybe<IUser>;
  sharesHistory: Array<ISharesHistoryItem>;
  sharesholdersHistory: Array<ISharesholdersHistoryItem>;
  sitemapIssuerListing: ISitemapIssuerListing;
  spinOff: Array<ISpinOffCorporateAction>;
  stockDividendsPaid: Array<IStockDividendsCorporateAction>;
  stockSplit: Array<IStockSplitCorporateAction>;
  throw: Maybe<Scalars["BigInt"]["output"]>;
  throwWithVariable: Maybe<Scalars["BigInt"]["output"]>;
  totalYearlyAmericanIssuerCompensation: Array<ITotalCompensationByYear>;
  trailingReturnsByTickerSymbols: Array<ITrailingReturnsValue>;
  userNotes: Maybe<IUserNotes>;
  valuationIndicatorHistoryForTickerSymbols: Array<IValuationIndicatorValueHistory>;
  valuationWallForTickerSymbol: Array<IValuationIndicatorValue>;
  verySlowQuery: Maybe<Scalars["Boolean"]["output"]>;
  /** Get the playlist items for a given playlist ID */
  youtubePlaylistById: Maybe<Array<IYouTubePlaylistItem>>;
};

export type IQueryAgendaArgs = {
  cursor: InputMaybe<Scalars["Cursor"]["input"]>;
  direction: IOrderDirection;
  filterByEndDate: InputMaybe<Scalars["Date"]["input"]>;
  filterByEventType: InputMaybe<Array<IAgendaEventType>>;
  filterByIsInSnP500: InputMaybe<Scalars["Boolean"]["input"]>;
  filterByIssuerIds: InputMaybe<Array<Scalars["ObjectID"]["input"]>>;
  filterByIssuerType: InputMaybe<IIssuerType>;
  filterByIssuersInBookmarks: InputMaybe<Scalars["Boolean"]["input"]>;
  filterByIssuersInPortfolio: InputMaybe<Scalars["Boolean"]["input"]>;
  filterBySearchQuery: InputMaybe<Scalars["String"]["input"]>;
  filterByStartDate: InputMaybe<Scalars["Date"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
};

export type IQueryAmericanIssuerSpecialDividendsPaidArgs = {
  country: ISecurityCountry;
  tickerSymbol: Scalars["TickerSymbol"]["input"];
};

export type IQueryAssetByIdArgs = {
  id: Scalars["ObjectID"]["input"];
};

export type IQueryAssetDiscussionCommentByShortIdArgs = {
  commentShortId: Scalars["ID"]["input"];
};

export type IQueryAvailableCheckoutProductsArgs = {
  token: InputMaybe<Scalars["JWT"]["input"]>;
};

export type IQueryBlackVideoByIdArgs = {
  objectId: Scalars["ID"]["input"];
};

export type IQueryBookmarksArgs = {
  cursor: InputMaybe<Scalars["Cursor"]["input"]>;
  filterByIssuerType: InputMaybe<IIssuerType>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  sortDirection: IOrderDirection;
  sortField: InputMaybe<IBookmarksOrder>;
};

export type IQueryCommittedRevisionsArgs = {
  branch?: InputMaybe<Scalars["ID"]["input"]>;
  repository: Scalars["ID"]["input"];
};

export type IQueryCompanyIssuersArgs = {
  input: Array<ICompanyIssuerSearchRefInput>;
};

export type IQueryCouponByIdArgs = {
  id: Scalars["ID"]["input"];
};

export type IQueryCourseByIdArgs = {
  objectId: Scalars["ID"]["input"];
};

export type IQueryCourseBySlugArgs = {
  slug: Scalars["String"]["input"];
};

export type IQueryCvmMaterialFactsArgs = {
  cursor: InputMaybe<Scalars["Cursor"]["input"]>;
  deliveryDateEnd: Scalars["Date"]["input"];
  deliveryDateStart: Scalars["Date"]["input"];
  filterByCategories: InputMaybe<Array<Scalars["String"]["input"]>>;
  filterByIssuerCnpj: InputMaybe<Scalars["String"]["input"]>;
  filterByIssuers: InputMaybe<ICvmMaterialFactIssuersFilter>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
};

export type IQueryDiscussionCommentsByAssetArgs = {
  assetId: Scalars["ObjectID"]["input"];
  country: Scalars["String"]["input"];
  cursor: InputMaybe<Scalars["Cursor"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy: InputMaybe<IAssetDiscussionCommentOrder>;
};

export type IQueryDiscussionCommentsFeedArgs = {
  country: Scalars["String"]["input"];
  cursor: InputMaybe<Scalars["Cursor"]["input"]>;
  filterBy: InputMaybe<IDiscussionCommentsFeedFilterBy>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
};

export type IQueryDiscussionsLeaderboardArgs = {
  country: Scalars["String"]["input"];
  top?: InputMaybe<Scalars["Int"]["input"]>;
};

export type IQueryDividendsPaidArgs = {
  country: ISecurityCountry;
  filterByExcludingFrom: InputMaybe<Scalars["Int"]["input"]>;
  filterByExcludingTo: InputMaybe<Scalars["Int"]["input"]>;
  tickerSymbol: Scalars["TickerSymbol"]["input"];
};

export type IQueryDynamicConfigurationByKeysArgs = {
  keys: Array<Scalars["String"]["input"]>;
};

export type IQueryFinancialBusinessSegmentsArgs = {
  tickerSymbol: Scalars["TickerSymbol"]["input"];
};

export type IQueryFinancialGeographicSegmentsArgs = {
  tickerSymbol: Scalars["TickerSymbol"]["input"];
};

export type IQueryFinancialHighlightsArgs = {
  financialHighlightsId: InputMaybe<Scalars["ObjectID"]["input"]>;
  issuerId: Scalars["ObjectID"]["input"];
};

export type IQueryFinancialsAvailabilityArgs = {
  country: ISecurityCountry;
  indicatorDataSource?: InputMaybe<IIndicatorDataSource>;
  tickerSymbol: Scalars["TickerSymbol"]["input"];
  vertical?: InputMaybe<IPlatformVertical>;
};

export type IQueryFinancialsSeriesArgs = {
  currency: Scalars["String"]["input"];
  filterByInterim: InputMaybe<Scalars["String"]["input"]>;
  indicatorDataSource?: InputMaybe<IIndicatorDataSource>;
  indicators: Array<Scalars["IndicatorID"]["input"]>;
  limit: Scalars["Int"]["input"];
  securities: Array<IFinancialsSeriesSecurityInput>;
  statementType: IFinancialsStatementType;
  vertical: IPlatformVertical;
};

export type IQueryFinancialsSheetArgs = {
  country?: InputMaybe<ISecurityCountry>;
  currency?: InputMaybe<Scalars["String"]["input"]>;
  filterByCountryCode: InputMaybe<Scalars["String"]["input"]>;
  filterByInterim: InputMaybe<Scalars["String"]["input"]>;
  filterByReportDateAfter: InputMaybe<Scalars["Date"]["input"]>;
  filterByReportDateBefore: InputMaybe<Scalars["Date"]["input"]>;
  financialsTemplate: InputMaybe<Scalars["FinancialsTemplateID"]["input"]>;
  indicatorDataSource?: InputMaybe<IIndicatorDataSource>;
  indicators: InputMaybe<Array<Scalars["IndicatorID"]["input"]>>;
  indicatorsCriterion?: InputMaybe<IFinancialsSheetIndicatorsCriterion>;
  indicatorsPlacement?: InputMaybe<IIndicatorsTablePlacement>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  limitCriterion?: InputMaybe<IFinancialsSheetLimitCriterion>;
  statementType?: InputMaybe<IFinancialsStatementType>;
  tickerSymbol: Scalars["TickerSymbol"]["input"];
  vertical?: InputMaybe<IPlatformVertical>;
};

export type IQueryFinancialsSheetForComparisonArgs = {
  adversaries: Array<IFinancialsSheetComparisonAdversaryInput>;
  currency: Scalars["String"]["input"];
  filterByCountryCode: InputMaybe<Scalars["String"]["input"]>;
  filterByInterim: InputMaybe<Scalars["String"]["input"]>;
  filterByReportDateAfter: InputMaybe<Scalars["Date"]["input"]>;
  filterByReportDateBefore: InputMaybe<Scalars["Date"]["input"]>;
  indicatorDataSource?: InputMaybe<IIndicatorDataSource>;
  indicators: Array<Scalars["IndicatorID"]["input"]>;
  limit: Scalars["Int"]["input"];
  limitCriterion: IFinancialsSheetLimitCriterion;
  statementType: IFinancialsStatementType;
  vertical: IPlatformVertical;
};

export type IQueryFinancialsTemplatesArgs = {
  cursor: InputMaybe<Scalars["Cursor"]["input"]>;
  filters: InputMaybe<IFinancialsTemplatesQueryFilters>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<IFinancialsTemplatesQueryOrder>;
};

export type IQueryForexRatesArgs = {
  baseCurrency: Scalars["String"]["input"];
};

export type IQueryFreeFloatHistoryArgs = {
  tickerSymbol: Scalars["TickerSymbol"]["input"];
};

export type IQueryIndicatorsArgs = {
  filters: InputMaybe<IIndicatorsFilters>;
  objectIds: InputMaybe<Array<Scalars["IndicatorID"]["input"]>>;
};

export type IQueryIssuerByTickerSymbolArgs = {
  country: ISecurityCountry;
  tickerSymbol: Scalars["TickerSymbol"]["input"];
};

export type IQueryIssuerPeersArgs = {
  contextIssuer: Scalars["String"]["input"];
  excludeIssuers?: InputMaybe<Array<Scalars["ObjectID"]["input"]>>;
  listingCountry: InputMaybe<IIssuerListingCountry>;
  narrowCriteria: Array<IIssuerSearchNarrowCriteria>;
  top?: InputMaybe<Scalars["Int"]["input"]>;
};

export type IQueryKpisArgs = {
  language: InputMaybe<Scalars["ISO6391LanguageCode"]["input"]>;
  tickerSymbol: Scalars["String"]["input"];
};

export type IQueryLiquidityByTickerSymbolArgs = {
  namespace: IAssetNamespace;
  tickerSymbol: Scalars["TickerSymbol"]["input"];
};

export type IQueryMarketCapitalizationHistoryArgs = {
  tickerSymbol: Scalars["TickerSymbol"]["input"];
};

export type IQueryMarketCapitalizationHistoryByTickerSymbolsArgs = {
  tickerSymbols: Array<Scalars["TickerSymbol"]["input"]>;
};

export type IQueryMostRepliedPostsArgs = {
  contextType: IPostContextType;
  top?: InputMaybe<Scalars["Int"]["input"]>;
};

export type IQueryNodeArgs = {
  id: Scalars["ID"]["input"];
};

export type IQueryNodesArgs = {
  ids: Array<Scalars["ID"]["input"]>;
};

export type IQueryPerEmployeeRatiosHistoryArgs = {
  tickerSymbol: Scalars["TickerSymbol"]["input"];
};

export type IQueryPostByShortIdArgs = {
  postShortId: Scalars["ID"]["input"];
};

export type IQueryPostReplyByShortIdArgs = {
  replyShortId: Scalars["ID"]["input"];
};

export type IQueryPostsArgs = {
  contextKey: InputMaybe<Scalars["String"]["input"]>;
  contextType: IPostContextType;
  cursor: InputMaybe<Scalars["Cursor"]["input"]>;
  filterBy: InputMaybe<IPostFilterBy>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy: InputMaybe<IPostOrder>;
};

export type IQueryQuotesHistoryArgs = {
  endDate: Scalars["Date"]["input"];
  startDate: Scalars["Date"]["input"];
  tickerSymbols: Array<Scalars["TickerSymbol"]["input"]>;
};

export type IQueryRankingV2Args = {
  cursor: InputMaybe<Scalars["Cursor"]["input"]>;
  evaluationPeriod?: InputMaybe<IRatingEvaluationPeriod>;
  filterBySectorOrSegment: InputMaybe<Scalars["String"]["input"]>;
  issuerType: InputMaybe<IRankingIssuerType>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
};

export type IQueryRealEstateFundByTickerSymbolArgs = {
  tickerSymbol: Scalars["TickerSymbol"]["input"];
};

export type IQueryRelatedPeersArgs = {
  filterByVertical: InputMaybe<IPlatformVertical>;
  tickerSymbol: Scalars["TickerSymbol"]["input"];
};

export type IQueryRightsIssuesArgs = {
  country: ISecurityCountry;
  tickerSymbols: Array<Scalars["TickerSymbol"]["input"]>;
};

export type IQuerySearchIssuersArgs = {
  excludeIssuers?: InputMaybe<Array<Scalars["ObjectID"]["input"]>>;
  issuerType: InputMaybe<IIssuerType>;
  listingCountry: InputMaybe<IIssuerListingCountry>;
  narrowContextIssuer: InputMaybe<Scalars["String"]["input"]>;
  narrowCriteria: InputMaybe<Array<IIssuerSearchNarrowCriteria>>;
  query: Scalars["String"]["input"];
};

export type IQuerySearchIssuersByTickerSymbolArgs = {
  tickerSymbol: Scalars["TickerSymbol"]["input"];
  vertical: InputMaybe<IPlatformVertical>;
};

export type IQuerySectorsForRankingArgs = {
  evaluationPeriod?: InputMaybe<IRatingEvaluationPeriod>;
  issuerType: IRankingIssuerType;
};

export type IQuerySecurityByTickerSymbolArgs = {
  country: ISecurityCountry;
  tickerSymbol: Scalars["TickerSymbol"]["input"];
};

export type IQuerySharesHistoryArgs = {
  tickerSymbol: Scalars["TickerSymbol"]["input"];
};

export type IQuerySharesholdersHistoryArgs = {
  tickerSymbol: Scalars["TickerSymbol"]["input"];
};

export type IQuerySitemapIssuerListingArgs = {
  filterByIssuerType: IIssuerType;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
};

export type IQuerySpinOffArgs = {
  country: ISecurityCountry;
  tickerSymbols: Array<Scalars["TickerSymbol"]["input"]>;
};

export type IQueryStockDividendsPaidArgs = {
  country: ISecurityCountry;
  tickerSymbol: Scalars["TickerSymbol"]["input"];
};

export type IQueryStockSplitArgs = {
  country: ISecurityCountry;
  tickerSymbols: Array<Scalars["TickerSymbol"]["input"]>;
};

export type IQueryThrowWithVariableArgs = {
  var: Scalars["String"]["input"];
};

export type IQueryTotalYearlyAmericanIssuerCompensationArgs = {
  tickerSymbol: Scalars["TickerSymbol"]["input"];
};

export type IQueryTrailingReturnsByTickerSymbolsArgs = {
  tickerSymbols: Array<Scalars["TickerSymbol"]["input"]>;
};

export type IQueryUserNotesArgs = {
  issuerId: Scalars["ObjectID"]["input"];
};

export type IQueryValuationIndicatorHistoryForTickerSymbolsArgs = {
  country: ISecurityCountry;
  indicator: IValuationIndicator;
  period: IValuationPeriod;
  tickerSymbols: Array<Scalars["TickerSymbol"]["input"]>;
};

export type IQueryValuationWallForTickerSymbolArgs = {
  country: ISecurityCountry;
  tickerSymbol: Scalars["TickerSymbol"]["input"];
};

export type IQueryYoutubePlaylistByIdArgs = {
  playlistId: Scalars["ID"]["input"];
};

export type IQuickAnalysis = {
  __typename: "QuickAnalysis";
  textContent: Scalars["String"]["output"];
  yearOfReference: Scalars["Int"]["output"];
};

export type IQuoteChartNode = {
  __typename: "QuoteChartNode";
  close: Scalars["Float"]["output"];
  date: Scalars["Date"]["output"];
  financialVolume: Scalars["Float"]["output"];
  high: Scalars["Float"]["output"];
  low: Scalars["Float"]["output"];
  numberOfTrades: Scalars["Float"]["output"];
  open: Scalars["Float"]["output"];
  tickerSymbol: Scalars["String"]["output"];
  volumeTraded: Scalars["Float"]["output"];
};

export enum IQuotePeriod {
  P1Y = "P1Y",
  P5Y = "P5Y",
  P10Y = "P10Y",
  P15D = "P15D",
  P30D = "P30D",
  P30Y = "P30Y",
  P60D = "P60D",
}

export type IQuotesHistory = {
  __typename: "QuotesHistory";
  nodes: Array<IQuoteChartNode>;
  tickerSymbol: Scalars["String"]["output"];
  trailingReturns: Maybe<ITrailingReturns>;
};

export type IRankedCompanySnapshot = {
  __typename: "RankedCompanySnapshot";
  analystRatings: Array<IAnalystRating>;
  issuer: Maybe<ICompanyIssuer>;
  lastRatedAt: Scalars["DateTime"]["output"];
  overallRating: Scalars["Float"]["output"];
  rating: Maybe<IViewerRating>;
  tickerSymbol: Scalars["String"]["output"];
  totalRatings: Scalars["Int"]["output"];
};

/** Defines a contract with the fields for Issuers' types that are part of the ranking system */
export type IRankedIssuer = {
  /** Is the position in the overall Site ranking */
  overallRankingPlacement: Maybe<IRankingPlacement>;
  /** Is the Rating in the overall Site ranking */
  overallRankingRating: Maybe<Scalars["Float"]["output"]>;
  /** Is the Rating Summary in the overall Site ranking */
  overallRankingRatingSummary: Maybe<IRatingSummary>;
  /** Is the rating that the User has given (if any) */
  rating: Maybe<IViewerRating>;
};

/** Defines a contract with the fields for Issuers' types that are part of the ranking system */
export type IRankedIssuerOverallRankingPlacementArgs = {
  evaluationPeriod?: InputMaybe<IRatingEvaluationPeriod>;
};

/** Defines a contract with the fields for Issuers' types that are part of the ranking system */
export type IRankedIssuerOverallRankingRatingSummaryArgs = {
  evaluationPeriod?: InputMaybe<IRatingEvaluationPeriod>;
};

export enum IRankingIssuerType {
  AmericanCompany = "AMERICAN_COMPANY",
  AmericanReit = "AMERICAN_REIT",
  BrazilianCompany = "BRAZILIAN_COMPANY",
  BrazilianRealEstateFund = "BRAZILIAN_REAL_ESTATE_FUND",
}

export type IRankingParticipant = {
  __typename: "RankingParticipant";
  /** Underlying Issuer that is holding this ranking position */
  issuer: IRankedIssuer;
  /** The underlying Issuer type */
  issuerType: IRankingIssuerType;
  /** Average over all ratings given for the Issuer */
  overallRating: Scalars["Float"]["output"];
  /** Is the position of this participant in the whole list */
  position: Scalars["Int"]["output"];
  /** Number of people that rated the Issuer */
  totalRatings: Scalars["Int"]["output"];
};

export type IRankingPlacement = {
  __typename: "RankingPlacement";
  periodEndDate: Scalars["Date"]["output"];
  periodStartDate: Scalars["Date"]["output"];
  position: Scalars["Int"]["output"];
};

export enum IRatingEvaluationPeriod {
  Last_3Months = "LAST_3_MONTHS",
  Last_6Months = "LAST_6_MONTHS",
  Last_9Months = "LAST_9_MONTHS",
  Last_12Months = "LAST_12_MONTHS",
  PastYear = "PAST_YEAR",
  Ytd = "YTD",
}

export type IRatingInput = {
  issuerId: Scalars["ObjectID"]["input"];
  issuerType: IRankingIssuerType;
  rating: Scalars["Int"]["input"];
};

export type IRatingSummary = {
  __typename: "RatingSummary";
  /** Distribution of each rating. Always includes the 5 stars and the number votes for each rating (1, 2, 3, 4 and 5) */
  distribution: Array<IRatingSummaryDistributionItem>;
  /** Overall rating in the ranking */
  overallRating: Scalars["Float"]["output"];
  /** Is the ending date of which the ratings were evaluated */
  periodEndDate: Scalars["Date"]["output"];
  /** Is the start date of which the ratings were evaluated */
  periodStartDate: Scalars["Date"]["output"];
  /** Total number of ratings (votes) given */
  totalRatings: Scalars["Int"]["output"];
};

export type IRatingSummaryDistributionItem = {
  __typename: "RatingSummaryDistributionItem";
  /** Is the percentage that this rating contributes to the overall rating */
  percentOfTotal: Scalars["Float"]["output"];
  /** Rating from 1 to 5 */
  rating: Scalars["Int"]["output"];
  /** Total number of ratings (votes) given */
  totalRatings: Scalars["Int"]["output"];
};

export type IRawUploadedFileDetails = {
  __typename: "RawUploadedFileDetails";
  encoding: Scalars["String"]["output"];
  fileSize: Scalars["Int"]["output"];
  filename: Scalars["String"]["output"];
  mimetype: Scalars["String"]["output"];
};

export type IReactable = {
  reactionGroups: Array<IReactionGroup>;
  viewerCanReact: Scalars["Boolean"]["output"];
};

export enum IReactionContent {
  Confused = "CONFUSED",
  Eyes = "EYES",
  Heart = "HEART",
  Hooray = "HOORAY",
  Laugh = "LAUGH",
  Rocket = "ROCKET",
  ThumbsUp = "THUMBS_UP",
}

export type IReactionGroup = {
  __typename: "ReactionGroup";
  content: IReactionContent;
  totalReactions: Scalars["Int"]["output"];
  viewerHasReacted: Scalars["Boolean"]["output"];
};

export enum IReactionSubject {
  AssetDiscussionComment = "ASSET_DISCUSSION_COMMENT",
  AssetDiscussionCommentReply = "ASSET_DISCUSSION_COMMENT_REPLY",
  Post = "POST",
  PostReply = "POST_REPLY",
}

export type IReclameAquiRatings = {
  __typename: "ReclameAquiRatings";
  asOfDate: Scalars["Date"]["output"];
  classification: Scalars["String"]["output"];
  finalScore: Scalars["Float"]["output"];
  reclameAquiUrl: Scalars["URL"]["output"];
};

export type IRelatedPeersBracket = {
  __typename: "RelatedPeersBracket";
  comparables: Array<IRelatedPeersComparable>;
  competitors: Array<IRelatedPeersComparable>;
  industryPeers: Array<IRelatedPeersComparable>;
};

export type IRelatedPeersComparable = {
  __typename: "RelatedPeersComparable";
  exchangeId: Scalars["String"]["output"];
  issuer: Maybe<ICompanyIssuer>;
  position: Scalars["Int"]["output"];
  tickerSymbol: Scalars["TickerSymbol"]["output"];
};

export type IRemoveAssetFromPortfolioInput = {
  securityCountry: ISecurityCountry;
  tickerSymbol: Scalars["TickerSymbol"]["input"];
};

export type IRemoveReactionInput = {
  content: IReactionContent;
  subjectId: Scalars["ObjectID"]["input"];
  subjectType: IReactionSubject;
};

export type IRemoveStarInput = {
  starrableId: Scalars["ID"]["input"];
};

export type IReportSource = ICvmSubmission | ISecSubmission;

export type IRightsIssueEvent = IAgendaEvent & {
  __typename: "RightsIssueEvent";
  _id: Scalars["ObjectID"]["output"];
  eventDate: Scalars["Date"]["output"];
  eventType: IAgendaEventType;
  excludingDate: Scalars["Date"]["output"];
  issuer: Maybe<IIssuer>;
  newShare: Scalars["Float"]["output"];
  payDate: Maybe<Scalars["Date"]["output"]>;
  recordDate: Scalars["Date"]["output"];
  subscriptionPrice: Scalars["Float"]["output"];
};

export type IRightsIssuesCorporateAction = {
  __typename: "RightsIssuesCorporateAction";
  excludingDate: Scalars["Date"]["output"];
  newShare: Maybe<Scalars["Float"]["output"]>;
  payDate: Maybe<Scalars["Date"]["output"]>;
  recordDate: Maybe<Scalars["Date"]["output"]>;
  subscriptionPrice: Maybe<Scalars["Float"]["output"]>;
  tickerSymbol: Scalars["TickerSymbol"]["output"];
};

export type ISayOnPayItem = {
  __typename: "SayOnPayItem";
  accessionNumber: Scalars["String"]["output"];
  filingSource: Maybe<ISecSubmission>;
  fiscalYear: Scalars["Int"]["output"];
  reportDate: Scalars["Date"]["output"];
  reportSource: Scalars["String"]["output"];
  sayOnFrequencyAbstention: Maybe<Scalars["Float"]["output"]>;
  sayOnFrequencyAnnual: Maybe<Scalars["Float"]["output"]>;
  sayOnFrequencyBiennial: Maybe<Scalars["Float"]["output"]>;
  sayOnFrequencyBrokerNonVotes: Maybe<Scalars["Float"]["output"]>;
  sayOnFrequencyTriennial: Maybe<Scalars["Float"]["output"]>;
  sayOnPayAbstention: Maybe<Scalars["Float"]["output"]>;
  sayOnPayAgainst: Maybe<Scalars["Float"]["output"]>;
  sayOnPayBrokerNonVotes: Maybe<Scalars["Float"]["output"]>;
  sayOnPayFor: Maybe<Scalars["Float"]["output"]>;
};

export type ISecSubmission = {
  __typename: "SecSubmission";
  acceptanceDateTime: Scalars["DateTime"]["output"];
  accessionNumber: Scalars["String"]["output"];
  fileNumber: Maybe<Scalars["String"]["output"]>;
  filmNumber: Maybe<Scalars["String"]["output"]>;
  formType: Scalars["String"]["output"];
  indexHref: Scalars["URL"]["output"];
  primaryDocumentHref: Maybe<Scalars["URL"]["output"]>;
  reportDate: Maybe<Scalars["Date"]["output"]>;
};

export enum ISecuritiesExchange {
  Bvmf = "BVMF",
  Nas = "NAS",
  Nys = "NYS",
  Otc = "OTC",
}

export type ISecurity = {
  _id: Scalars["ObjectID"]["output"];
  issuer: Maybe<IIssuer>;
  name: Scalars["String"]["output"];
  sharesOutstanding: Scalars["BigInt"]["output"];
  tickerSymbol: Scalars["TickerSymbol"]["output"];
  type: IGlobalSecurityType;
};

export enum ISecurityCategory {
  Bdr = "BDR",
  EtfEquities = "ETF_EQUITIES",
  EtfForeignIndex = "ETF_FOREIGN_INDEX",
  FixedIncome = "FIXED_INCOME",
  Funds = "FUNDS",
  Shares = "SHARES",
  Unit = "UNIT",
}

export enum ISecurityCountry {
  Br = "BR",
  Us = "US",
}

export type ISetAsDefaultFinancialsTemplateInput = {
  platformVertical: IPlatformVertical;
};

export type ISharesHistory = {
  __typename: "SharesHistory";
  balanceSheetDate: Maybe<Scalars["Date"]["output"]>;
  companyTso: Maybe<Scalars["Float"]["output"]>;
  floatDate: Maybe<Scalars["Date"]["output"]>;
  floatShare: Maybe<Scalars["Float"]["output"]>;
  floatShareToTotalSharesOutstanding: Maybe<Scalars["Float"]["output"]>;
  insiderHolding: Maybe<Scalars["Float"]["output"]>;
  quotedTso: Maybe<Scalars["Float"]["output"]>;
  reasonOfShareChange: Maybe<Scalars["String"]["output"]>;
  shareClassLevelTreasuryShareOutstanding: Maybe<Scalars["Float"]["output"]>;
  shareClassLevelTreasuryShareOutstandingFromCover: Maybe<Scalars["String"]["output"]>;
  shareClassLevelTreasuryShareOutstandingFromInterim: Maybe<Scalars["String"]["output"]>;
  shareClassLevelTreasuryShareOutstandingFromNews: Maybe<Scalars["String"]["output"]>;
  shareClassLevelTreasuryShareOutstandingFromOther: Maybe<Scalars["String"]["output"]>;
  shareLevelTso: Maybe<Scalars["Float"]["output"]>;
  shareLevelTsoSource: Maybe<Scalars["String"]["output"]>;
  sharesOutstandingWithBalanceSheetEndingDate: Maybe<Scalars["Float"]["output"]>;
  tsoDate: Scalars["Date"]["output"];
  unquotedTso: Maybe<Scalars["Float"]["output"]>;
};

export type ISharesHistoryItem = {
  __typename: "SharesHistoryItem";
  asOfDate: Scalars["Date"]["output"];
  quarterlyRepurchaseOfCapitalStock: Scalars["Float"]["output"];
  totalSharesOutstanding: Scalars["Float"]["output"];
  yearlyRepurchaseOfCapitalStock: Scalars["Float"]["output"];
};

export type ISharesholdersHistoryItem = {
  __typename: "SharesholdersHistoryItem";
  asOfDate: Scalars["Date"]["output"];
  totalOfCorporateShareholders: Scalars["Int"]["output"];
  totalOfIndividualShareholders: Scalars["Int"]["output"];
  totalOfInstitutionalShareholders: Scalars["Int"]["output"];
  totalOfShareholders: Scalars["Int"]["output"];
};

export enum ISignalType {
  ReadIssuerComments = "READ_ISSUER_COMMENTS",
}

export type ISitemapIssuerListing = {
  __typename: "SitemapIssuerListing";
  nodes: Array<ISitemapIssuerListingNode>;
  pageInfo: IPageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ISitemapIssuerListingNode = {
  __typename: "SitemapIssuerListingNode";
  issuerId: Scalars["ObjectID"]["output"];
  issuerType: IIssuerType;
  name: Scalars["String"]["output"];
  resourcePath: Scalars["String"]["output"];
  tickerSymbol: Scalars["String"]["output"];
  url: Scalars["URL"]["output"];
};

export type ISpeculativeContent =
  | IHistoricalFfoVersusQuote
  | IHistoricalNetIncomeVersusQuote
  | IHistoricalReturn
  | IHistoricalShareholders
  | IHistoricalTradingSessions;

export type ISpinOffCorporateAction = {
  __typename: "SpinOffCorporateAction";
  childCompanyExchangeId: Maybe<Scalars["String"]["output"]>;
  childCompanyName: Maybe<Scalars["String"]["output"]>;
  childCompanySymbol: Maybe<Scalars["String"]["output"]>;
  excludingDate: Scalars["Date"]["output"];
  numberOfShares: Maybe<Scalars["Float"]["output"]>;
  payDate: Maybe<Scalars["Date"]["output"]>;
  recordDate: Maybe<Scalars["Date"]["output"]>;
  spinFrom: Scalars["Float"]["output"];
  spinTo: Scalars["Float"]["output"];
  tickerSymbol: Scalars["TickerSymbol"]["output"];
};

export type ISpinOffEvent = IAgendaEvent & {
  __typename: "SpinOffEvent";
  _id: Scalars["ObjectID"]["output"];
  childCompanyExchangeId: Scalars["String"]["output"];
  childCompanyName: Scalars["String"]["output"];
  childCompanySymbol: Scalars["String"]["output"];
  eventDate: Scalars["Date"]["output"];
  eventType: IAgendaEventType;
  excludingDate: Scalars["Date"]["output"];
  issuer: Maybe<IIssuer>;
  numberOfShares: Scalars["Float"]["output"];
  spinFrom: Scalars["Float"]["output"];
  spinTo: Scalars["Float"]["output"];
};

export type IStandardEvent = IAgendaEvent & {
  __typename: "StandardEvent";
  _id: Scalars["ObjectID"]["output"];
  eventDate: Scalars["Date"]["output"];
  eventFiscalYear: Scalars["Int"]["output"];
  eventType: IAgendaEventType;
  issuer: Maybe<IIssuer>;
};

export type IStarOrder = {
  direction: IOrderDirection;
  field: IStarOrderField;
};

export enum IStarOrderField {
  StarredAt = "STARRED_AT",
}

export type IStarrable = {
  objectId: Scalars["ID"]["output"];
  stargazerCount: Scalars["Int"]["output"];
  viewerHasStarred: Scalars["Boolean"]["output"];
};

export type IStarredFinancialsTemplateConnection = {
  __typename: "StarredFinancialsTemplateConnection";
  edges: Array<IStarredFinancialsTemplateEdge>;
  nodes: Array<IFinancialsTemplate>;
  pageInfo: IPageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type IStarredFinancialsTemplateEdge = {
  __typename: "StarredFinancialsTemplateEdge";
  node: IFinancialsTemplate;
  starredAt: Scalars["DateTime"]["output"];
};

export type IStockDividendsCorporateAction = {
  __typename: "StockDividendsCorporateAction";
  excludingDate: Scalars["Date"]["output"];
  sharePaid: Maybe<Scalars["Float"]["output"]>;
  tickerSymbol: Scalars["TickerSymbol"]["output"];
};

export type IStockSplitCorporateAction = {
  __typename: "StockSplitCorporateAction";
  declarationDate: Maybe<Scalars["Date"]["output"]>;
  excludingDate: Scalars["Date"]["output"];
  splitFrom: Scalars["Float"]["output"];
  splitTo: Scalars["Float"]["output"];
  tickerSymbol: Scalars["TickerSymbol"]["output"];
};

export type IStockSplitEvent = IAgendaEvent & {
  __typename: "StockSplitEvent";
  _id: Scalars["ObjectID"]["output"];
  eventDate: Scalars["Date"]["output"];
  eventType: IAgendaEventType;
  excludingDate: Scalars["Date"]["output"];
  issuer: Maybe<IIssuer>;
  splitFrom: Scalars["Float"]["output"];
  splitTo: Scalars["Float"]["output"];
};

export enum IStripeChargeStatus {
  Failed = "FAILED",
  Pending = "PENDING",
  Succeeded = "SUCCEEDED",
}

export type IStripeCheckoutSession = {
  __typename: "StripeCheckoutSession";
  id: Scalars["ID"]["output"];
};

export type IStripeCoupon = {
  duration: Scalars["String"]["output"];
  durationInMonths: Maybe<Scalars["Int"]["output"]>;
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  redeemBy: Maybe<Scalars["DateTime"]["output"]>;
  valid: Scalars["Boolean"]["output"];
};

export type IStripeFixedAmountCoupon = IStripeCoupon & {
  __typename: "StripeFixedAmountCoupon";
  amountOff: Scalars["Float"]["output"];
  duration: Scalars["String"]["output"];
  durationInMonths: Maybe<Scalars["Int"]["output"]>;
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  redeemBy: Maybe<Scalars["DateTime"]["output"]>;
  valid: Scalars["Boolean"]["output"];
};

export type IStripeInvoice = {
  __typename: "StripeInvoice";
  amountDue: Scalars["Float"]["output"];
  amountPaid: Scalars["Float"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  endingBalance: Maybe<Scalars["Float"]["output"]>;
  hostedInvoiceUrl: Maybe<Scalars["URL"]["output"]>;
  id: Scalars["ID"]["output"];
  invoicePdf: Maybe<Scalars["URL"]["output"]>;
  nextPaymentAttempt: Maybe<Scalars["DateTime"]["output"]>;
  number: Scalars["ID"]["output"];
  paymentAttempts: Scalars["Float"]["output"];
  startingBalance: Scalars["Float"]["output"];
  status: IStripeInvoiceStatus;
  total: Scalars["Float"]["output"];
};

export enum IStripeInvoiceStatus {
  Draft = "DRAFT",
  Open = "OPEN",
  Paid = "PAID",
  Uncollectible = "UNCOLLECTIBLE",
  Void = "VOID",
}

export type IStripeLineItem = {
  __typename: "StripeLineItem";
  id: Scalars["ID"]["output"];
};

export type IStripeObject = IStripeCheckoutSession | IStripeInvoice | IStripePaymentIntent | IStripeSubscription;

export type IStripePaymentIntent = {
  __typename: "StripePaymentIntent";
  id: Scalars["ID"]["output"];
};

export type IStripePercentCoupon = IStripeCoupon & {
  __typename: "StripePercentCoupon";
  duration: Scalars["String"]["output"];
  durationInMonths: Maybe<Scalars["Int"]["output"]>;
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  percentOff: Scalars["Float"]["output"];
  redeemBy: Maybe<Scalars["DateTime"]["output"]>;
  valid: Scalars["Boolean"]["output"];
};

export type IStripePrice = {
  __typename: "StripePrice";
  amount: Maybe<Scalars["Float"]["output"]>;
  currency: Scalars["Currency"]["output"];
  formattedAmount: Scalars["String"]["output"];
  formattedMonthlyAmount: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  isActive: Scalars["Boolean"]["output"];
  monthlyAmount: Maybe<Scalars["Float"]["output"]>;
  product: IStripeProduct;
  recurringInterval: Maybe<IStripeRecurringPriceInterval>;
};

export type IStripeProduct = {
  __typename: "StripeProduct";
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
};

export enum IStripeRecurringPriceInterval {
  Day = "DAY",
  Month = "MONTH",
  Week = "WEEK",
  Year = "YEAR",
}

export type IStripeSubscription = {
  __typename: "StripeSubscription";
  cancelAt: Maybe<Scalars["DateTime"]["output"]>;
  canceledAt: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  isRefundable: Maybe<Scalars["Boolean"]["output"]>;
  isTrialing: Scalars["Boolean"]["output"];
  latestInvoice: Maybe<IStripeInvoice>;
  periodEnd: Scalars["DateTime"]["output"];
  periodStart: Scalars["DateTime"]["output"];
  price: Maybe<IStripePrice>;
  product: Maybe<IStripeProduct>;
  status: Scalars["String"]["output"];
  trialEnd: Maybe<Scalars["DateTime"]["output"]>;
  trialStart: Maybe<Scalars["DateTime"]["output"]>;
};

export enum IStripeSubscriptionStatus {
  Active = "ACTIVE",
  Canceled = "CANCELED",
  Incomplete = "INCOMPLETE",
  IncompleteExpired = "INCOMPLETE_EXPIRED",
  PastDue = "PAST_DUE",
  Trialing = "TRIALING",
  Unpaid = "UNPAID",
}

export type IStripeUpcomingInvoice = {
  __typename: "StripeUpcomingInvoice";
  amountDue: Scalars["Float"]["output"];
  endingBalance: Maybe<Scalars["Float"]["output"]>;
  nextPaymentAttempt: Maybe<Scalars["DateTime"]["output"]>;
  number: Scalars["ID"]["output"];
  startingBalance: Scalars["Float"]["output"];
  total: Scalars["Float"]["output"];
};

export type ISupportMessageInput = {
  body: Scalars["String"]["input"];
  subject: Scalars["String"]["input"];
};

export type IToggleCompletedOnLessonInput = {
  isCompleted: Scalars["Boolean"]["input"];
  lessonId: Scalars["ID"]["input"];
};

export type ITotalCompensationByYear = {
  fiscalYear: Scalars["String"]["output"];
  percentageOfRevenue: Scalars["Float"]["output"];
  revenue: Scalars["Float"]["output"];
  totalBoardOfDirectorsCompensation: Scalars["Float"]["output"];
  totalCompensation: Scalars["Float"]["output"];
  totalExecutiveCompensation: Scalars["Float"]["output"];
};

export enum ITotalDisplayOptionSetting {
  Financial = "FINANCIAL",
  Percentage = "PERCENTAGE",
  Shares = "SHARES",
}

export enum ITradingSessionPeriod {
  P1Y = "P1Y",
  P30D = "P30D",
  P60D = "P60D",
  P90D = "P90D",
  P180D = "P180D",
}

export type ITrailingReturnReferencePrice = {
  __typename: "TrailingReturnReferencePrice";
  close: Scalars["Float"]["output"];
  tradeDate: Scalars["Date"]["output"];
};

export type ITrailingReturnReferencePrices = {
  __typename: "TrailingReturnReferencePrices";
  allTime: Maybe<ITrailingReturnReferencePrice>;
  fiveYears: Maybe<ITrailingReturnReferencePrice>;
  monthToDate: Maybe<ITrailingReturnReferencePrice>;
  oneDay: Maybe<ITrailingReturnReferencePrice>;
  oneMonth: Maybe<ITrailingReturnReferencePrice>;
  oneWeek: Maybe<ITrailingReturnReferencePrice>;
  oneYear: Maybe<ITrailingReturnReferencePrice>;
  quarterToDate: Maybe<ITrailingReturnReferencePrice>;
  sevenYears: Maybe<ITrailingReturnReferencePrice>;
  sixMonths: Maybe<ITrailingReturnReferencePrice>;
  tenYears: Maybe<ITrailingReturnReferencePrice>;
  threeMonths: Maybe<ITrailingReturnReferencePrice>;
  threeYears: Maybe<ITrailingReturnReferencePrice>;
  yearToDate: Maybe<ITrailingReturnReferencePrice>;
};

export type ITrailingReturns = {
  __typename: "TrailingReturns";
  allTime: Maybe<Scalars["Float"]["output"]>;
  allTimeAnnualized: Maybe<Scalars["Float"]["output"]>;
  asOfDate: Scalars["Date"]["output"];
  close: Scalars["Float"]["output"];
  daysTrading: Scalars["Int"]["output"];
  exchangeId: Scalars["String"]["output"];
  fiveYears: Maybe<Scalars["Float"]["output"]>;
  fiveYearsAnnualized: Maybe<Scalars["Float"]["output"]>;
  maxDate: Maybe<Scalars["Date"]["output"]>;
  minDate: Maybe<Scalars["Date"]["output"]>;
  monthToDate: Maybe<Scalars["Float"]["output"]>;
  numberOfTrades: Scalars["Int"]["output"];
  oneDay: Maybe<Scalars["Float"]["output"]>;
  oneMonth: Maybe<Scalars["Float"]["output"]>;
  oneWeek: Maybe<Scalars["Float"]["output"]>;
  oneYear: Maybe<Scalars["Float"]["output"]>;
  prices: Maybe<ITrailingReturnReferencePrices>;
  quarterToDate: Maybe<Scalars["Float"]["output"]>;
  sevenYears: Maybe<Scalars["Float"]["output"]>;
  sixMonths: Maybe<Scalars["Float"]["output"]>;
  tenYears: Maybe<Scalars["Float"]["output"]>;
  tenYearsAnnualized: Maybe<Scalars["Float"]["output"]>;
  threeMonths: Maybe<Scalars["Float"]["output"]>;
  threeYears: Maybe<Scalars["Float"]["output"]>;
  tickerSymbol: Scalars["String"]["output"];
  yearToDate: Maybe<Scalars["Float"]["output"]>;
  yearsTrading: Scalars["Int"]["output"];
};

export type ITrailingReturnsValue = {
  __typename: "TrailingReturnsValue";
  tickerSymbol: Scalars["String"]["output"];
  trailingReturns: Maybe<ITrailingReturns>;
};

export type ITranslatedTextContent = {
  __typename: "TranslatedTextContent";
  _id: Scalars["ObjectID"]["output"];
  language: Scalars["ISO6391LanguageCode"]["output"];
  objectId: Scalars["ID"]["output"];
  textContent: Scalars["String"]["output"];
};

export type IUtmParametersInput = {
  utmCampaign: InputMaybe<Scalars["String"]["input"]>;
  utmContent: InputMaybe<Scalars["String"]["input"]>;
  utmMedium: InputMaybe<Scalars["String"]["input"]>;
  utmSource: InputMaybe<Scalars["String"]["input"]>;
  utmTerm: InputMaybe<Scalars["String"]["input"]>;
};

export type IUnfurl = {
  __typename: "Unfurl";
  _id: Scalars["ObjectID"]["output"];
  author: Maybe<Scalars["String"]["output"]>;
  canonicalUrl: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["Date"]["output"];
  description: Maybe<Scalars["String"]["output"]>;
  favicon: Maybe<Scalars["String"]["output"]>;
  keywords: Maybe<Array<Scalars["String"]["output"]>>;
  oEmbed: Maybe<IUnfurlOEmbed>;
  objectId: Scalars["String"]["output"];
  openGraph: Maybe<IUnfurlOpenGraph>;
  themeColor: Maybe<Scalars["String"]["output"]>;
  title: Maybe<Scalars["String"]["output"]>;
  twitterCard: Maybe<IUnfurlTwitterCard>;
};

export type IUnfurlOEmbed = IUnfurlOEmbedLink | IUnfurlOEmbedPhoto | IUnfurlOEmbedRich | IUnfurlOEmbedVideo;

export type IUnfurlOEmbedLink = {
  __typename: "UnfurlOEmbedLink";
  type: Maybe<Scalars["String"]["output"]>;
};

export type IUnfurlOEmbedPhoto = {
  __typename: "UnfurlOEmbedPhoto";
  height: Maybe<Scalars["Int"]["output"]>;
  type: Maybe<Scalars["String"]["output"]>;
  url: Maybe<Scalars["String"]["output"]>;
  width: Maybe<Scalars["Int"]["output"]>;
};

export type IUnfurlOEmbedRich = {
  __typename: "UnfurlOEmbedRich";
  height: Maybe<Scalars["Int"]["output"]>;
  html: Maybe<Scalars["String"]["output"]>;
  type: Maybe<Scalars["String"]["output"]>;
  width: Maybe<Scalars["Int"]["output"]>;
};

export type IUnfurlOEmbedVideo = {
  __typename: "UnfurlOEmbedVideo";
  height: Maybe<Scalars["Int"]["output"]>;
  html: Maybe<Scalars["String"]["output"]>;
  type: Maybe<Scalars["String"]["output"]>;
  width: Maybe<Scalars["Int"]["output"]>;
};

export type IUnfurlOpenGraph = {
  __typename: "UnfurlOpenGraph";
  article: Maybe<IUnfurlOpenGraphArticle>;
  audio: Maybe<Array<IUnfurlOpenGraphAudio>>;
  description: Maybe<Scalars["String"]["output"]>;
  determiner: Maybe<Scalars["String"]["output"]>;
  images: Maybe<Array<IUnfurlOpenGraphImage>>;
  locale: Maybe<Scalars["String"]["output"]>;
  localeAlt: Maybe<Scalars["String"]["output"]>;
  siteName: Maybe<Scalars["String"]["output"]>;
  title: Maybe<Scalars["String"]["output"]>;
  type: Maybe<Scalars["String"]["output"]>;
  url: Maybe<Scalars["String"]["output"]>;
  videos: Maybe<Array<IUnfurlOpenGraphVideo>>;
};

export type IUnfurlOpenGraphArticle = {
  __typename: "UnfurlOpenGraphArticle";
  author: Maybe<Scalars["String"]["output"]>;
  expirationTime: Maybe<Scalars["String"]["output"]>;
  modifiedTime: Maybe<Scalars["String"]["output"]>;
  publishedTime: Maybe<Scalars["String"]["output"]>;
  section: Maybe<Scalars["String"]["output"]>;
  tags: Maybe<Array<Scalars["String"]["output"]>>;
};

export type IUnfurlOpenGraphAudio = {
  __typename: "UnfurlOpenGraphAudio";
  secureUrl: Maybe<Scalars["String"]["output"]>;
  type: Maybe<Scalars["String"]["output"]>;
  url: Maybe<Scalars["String"]["output"]>;
};

export type IUnfurlOpenGraphImage = {
  __typename: "UnfurlOpenGraphImage";
  alt: Maybe<Scalars["String"]["output"]>;
  height: Maybe<Scalars["Int"]["output"]>;
  secureUrl: Maybe<Scalars["String"]["output"]>;
  type: Maybe<Scalars["String"]["output"]>;
  url: Maybe<Scalars["String"]["output"]>;
  width: Maybe<Scalars["Int"]["output"]>;
};

export type IUnfurlOpenGraphVideo = {
  __typename: "UnfurlOpenGraphVideo";
  height: Maybe<Scalars["Int"]["output"]>;
  stream: Maybe<Scalars["String"]["output"]>;
  tags: Maybe<Array<Scalars["String"]["output"]>>;
  url: Maybe<Scalars["String"]["output"]>;
  width: Maybe<Scalars["Int"]["output"]>;
};

export type IUnfurlTwitterApp = {
  __typename: "UnfurlTwitterApp";
  id: Maybe<Scalars["String"]["output"]>;
  name: Maybe<Scalars["String"]["output"]>;
  url: Maybe<Scalars["String"]["output"]>;
};

export type IUnfurlTwitterApps = {
  __typename: "UnfurlTwitterApps";
  googlePlay: Maybe<IUnfurlTwitterApp>;
  iPad: Maybe<IUnfurlTwitterApp>;
  iPhone: Maybe<IUnfurlTwitterApp>;
};

export type IUnfurlTwitterCard = {
  __typename: "UnfurlTwitterCard";
  apps: Maybe<IUnfurlTwitterApps>;
  card: Maybe<Scalars["String"]["output"]>;
  creator: Maybe<Scalars["String"]["output"]>;
  creatorId: Maybe<Scalars["String"]["output"]>;
  description: Maybe<Scalars["String"]["output"]>;
  images: Maybe<Array<IUnfurlTwitterImage>>;
  players: Maybe<Array<IUnfurlTwitterPlayer>>;
  site: Maybe<Scalars["String"]["output"]>;
  title: Maybe<Scalars["String"]["output"]>;
};

export type IUnfurlTwitterImage = {
  __typename: "UnfurlTwitterImage";
  alt: Maybe<Scalars["String"]["output"]>;
  url: Maybe<Scalars["String"]["output"]>;
};

export type IUnfurlTwitterPlayer = {
  __typename: "UnfurlTwitterPlayer";
  height: Maybe<Scalars["Int"]["output"]>;
  stream: Maybe<Scalars["String"]["output"]>;
  url: Maybe<Scalars["String"]["output"]>;
  width: Maybe<Scalars["Int"]["output"]>;
};

export type IUniformResourceLocatable = {
  resourcePath: Scalars["String"]["output"];
  url: Scalars["URL"]["output"];
};

export type IUpdateAssetPortfolioPositionInput = {
  goal: Scalars["Float"]["input"];
  numberOfShares: Scalars["Float"]["input"];
  positionId: Scalars["ObjectID"]["input"];
};

export type IUser = {
  __typename: "User";
  _id: Scalars["ObjectID"]["output"];
  billingPortalUrl: Maybe<Scalars["URL"]["output"]>;
  blackSubscriptionPeriod: Maybe<IBlackSubscriptionPeriod>;
  caixaPretaPurchaseReceipt: Maybe<ICaixaPretaPurchaseReceipt>;
  country: Scalars["String"]["output"];
  email: Scalars["String"]["output"];
  featureFlags: Array<IEvaluatedFeatureFlagVariant>;
  firstName: Scalars["String"]["output"];
  fullName: Scalars["String"]["output"];
  hasPasswordSet: Scalars["Boolean"]["output"];
  intercom: Maybe<IUserIntercomSettings>;
  isBlack: Scalars["Boolean"]["output"];
  isConnectedWithGoogle: Scalars["Boolean"]["output"];
  isPremium: Scalars["Boolean"]["output"];
  isSiteAdmin: Scalars["Boolean"]["output"];
  isTrialing: Scalars["Boolean"]["output"];
  lastName: Scalars["String"]["output"];
  pictureUrl: Maybe<Scalars["URL"]["output"]>;
  settings: IUserSettings;
  settingsV2: IUserSettingsV2;
  signedUpAt: Scalars["DateTime"]["output"];
  starredTemplates: IStarredFinancialsTemplateConnection;
  subscription: Maybe<IStripeSubscription>;
  viewerCanPurchaseBlack: Scalars["Boolean"]["output"];
  viewerCanStartPremiumTrial: Scalars["Boolean"]["output"];
};

export type IUserStarredTemplatesArgs = {
  cursor: InputMaybe<Scalars["Cursor"]["input"]>;
  filters: InputMaybe<IFinancialsTemplatesQueryFilters>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy: InputMaybe<IStarOrder>;
};

export type IUserAdvertisementSettings = {
  __typename: "UserAdvertisementSettings";
  hasClosedEduardoFiisCourse: Scalars["Boolean"]["output"];
  hasClosedEduardoFirstLiveAboutFiis: Scalars["Boolean"]["output"];
  hasClosedEduardoStocksCourse: Scalars["Boolean"]["output"];
  hasClosedMyProfitPartnership: Scalars["Boolean"]["output"];
  hasClosedNomadPartnership: Scalars["Boolean"]["output"];
  hasClosedRemessaOnlineDiscount: Scalars["Boolean"]["output"];
  hasClosedValuation: Scalars["Boolean"]["output"];
  hasClosedYearlyPlan: Scalars["Boolean"]["output"];
};

export type IUserAssetPortfolioSettings = {
  __typename: "UserAssetPortfolioSettings";
  allowFractions: Scalars["Boolean"]["output"];
  balancingStrategy: IBalancingStrategySetting;
  goalByCountry: Maybe<IGoalByCountrySetting>;
  goalBySecurityClassification: Maybe<IGoalBySecurityClassificationSetting>;
  shouldHideFinancialValues: Scalars["Boolean"]["output"];
  totalDisplay: ITotalDisplayOptionSetting;
};

export type IUserAssetPortfolioSettingsInput = {
  allowFractions: Scalars["Boolean"]["input"];
  balancingStrategy: InputMaybe<IBalancingStrategySetting>;
  goalByCountry: InputMaybe<IGoalByCountrySettingInput>;
  goalBySecurityClassification: InputMaybe<IGoalBySecurityClassificationSettingInput>;
  shouldHideFinancialValues: Scalars["Boolean"]["input"];
  totalDisplay: InputMaybe<ITotalDisplayOptionSetting>;
};

export type IUserInteractionInput = {
  kind: Scalars["String"]["input"];
  payload: Scalars["JSON"]["input"];
};

export type IUserIntercomSettings = {
  __typename: "UserIntercomSettings";
  appId: Scalars["ID"]["output"];
  verificationHash: Scalars["String"]["output"];
};

export type IUserNotes = {
  __typename: "UserNotes";
  _id: Scalars["ObjectID"]["output"];
  bodyAsJson: Scalars["JSON"]["output"];
  bodyAsPlainText: Scalars["String"]["output"];
  issuerId: Scalars["ObjectID"]["output"];
  objectId: Scalars["String"]["output"];
};

export type IUserRegistrationFormInput = {
  email: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  lastName: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
};

export type IUserSettings = {
  __typename: "UserSettings";
  advertisements: IUserAdvertisementSettings;
  assetPortfolio: IUserAssetPortfolioSettings;
  bookmarkedIndicators: Array<IValuationIndicator>;
  stream: IUserStreamSettings;
};

export type IUserSettingsV2 = {
  __typename: "UserSettingsV2";
  enabledIssuerPageBoxes: Maybe<IUserSettingsV2EnabledIssuerPageBoxes>;
  financialsTable: Maybe<IUserSettingsV2FinancialsTable>;
  hasClosedMarch24PerpetualBlackPopUp: Maybe<Scalars["Boolean"]["output"]>;
  id: Scalars["ID"]["output"];
  language: Maybe<Scalars["String"]["output"]>;
  objectId: Scalars["ID"]["output"];
  ownerId: Scalars["ObjectID"]["output"];
  theme: Maybe<IUserSettingsV2Theme>;
};

export type IUserSettingsV2DefaultTemplate = {
  __typename: "UserSettingsV2DefaultTemplate";
  objectId: Scalars["String"]["output"];
  templateCodes: Array<Scalars["String"]["output"]>;
  vertical: Scalars["String"]["output"];
};

export type IUserSettingsV2EnabledIssuerPageBoxes = {
  __typename: "UserSettingsV2EnabledIssuerPageBoxes";
  acoes: Array<Scalars["String"]["output"]>;
  fiis: Array<Scalars["String"]["output"]>;
  stocks: Array<Scalars["String"]["output"]>;
};

export type IUserSettingsV2FinancialsTable = {
  __typename: "UserSettingsV2FinancialsTable";
  defaultTemplates: Maybe<Array<IUserSettingsV2DefaultTemplate>>;
  fullBleed: Maybe<Scalars["Boolean"]["output"]>;
  indicatorDataSource: Maybe<Scalars["String"]["output"]>;
  sortDirection: Scalars["String"]["output"];
  statementType: Maybe<Scalars["String"]["output"]>;
  textAlignment: Scalars["String"]["output"];
};

export enum IUserSettingsV2Theme {
  Dark = "DARK",
  Light = "LIGHT",
}

export type IUserStreamSettings = {
  __typename: "UserStreamSettings";
  appId: Scalars["String"]["output"];
  appKey: Scalars["String"]["output"];
  region: Scalars["String"]["output"];
  userToken: Scalars["String"]["output"];
};

export enum IValuationIndicator {
  AdjustedDilutedNetAssetValue = "ADJUSTED_DILUTED_NET_ASSET_VALUE",
  AdjustedDilutedNetAssetValuePerShare = "ADJUSTED_DILUTED_NET_ASSET_VALUE_PER_SHARE",
  AdjustedFundsFromOperationsPerShare = "ADJUSTED_FUNDS_FROM_OPERATIONS_PER_SHARE",
  BookValuePerShare = "BOOK_VALUE_PER_SHARE",
  BuyBackYield = "BUY_BACK_YIELD",
  CapexToDepreciationAndAmortization = "CAPEX_TO_DEPRECIATION_AND_AMORTIZATION",
  CapexToOperatingCashFlow = "CAPEX_TO_OPERATING_CASH_FLOW",
  CapexToRevenue = "CAPEX_TO_REVENUE",
  CapitalLeaseObligationsToTotalDebt = "CAPITAL_LEASE_OBLIGATIONS_TO_TOTAL_DEBT",
  Cfo_5YearGrowth = "CFO_5_YEAR_GROWTH",
  CurrentQuarterlyTsoToPreviousQuarterlyTso = "CURRENT_QUARTERLY_TSO_TO_PREVIOUS_QUARTERLY_TSO",
  CurrentRatio = "CURRENT_RATIO",
  DepreciationAndAmortizationToEbitda = "DEPRECIATION_AND_AMORTIZATION_TO_EBITDA",
  DepreciationAndAmortizationToNormalizedEbitda = "DEPRECIATION_AND_AMORTIZATION_TO_NORMALIZED_EBITDA",
  DilutedEps = "DILUTED_EPS",
  DilutedEps_5YearGrowth = "DILUTED_EPS_5_YEAR_GROWTH",
  DividendPerShare = "DIVIDEND_PER_SHARE",
  DividendPerShareToFundsFromOperationsPerShare = "DIVIDEND_PER_SHARE_TO_FUNDS_FROM_OPERATIONS_PER_SHARE",
  DividendYield = "DIVIDEND_YIELD",
  DividendYield_5Year = "DIVIDEND_YIELD_5_YEAR",
  EbitdaMargin = "EBITDA_MARGIN",
  EbitMargin = "EBIT_MARGIN",
  EnterpriseValue = "ENTERPRISE_VALUE",
  EnterpriseValueToEbit = "ENTERPRISE_VALUE_TO_EBIT",
  EnterpriseValueToEbitda = "ENTERPRISE_VALUE_TO_EBITDA",
  EnterpriseValueToNormalizedEbitda = "ENTERPRISE_VALUE_TO_NORMALIZED_EBITDA",
  EnterpriseValueToRevenue = "ENTERPRISE_VALUE_TO_REVENUE",
  ForwardPeRatio = "FORWARD_PE_RATIO",
  FundsFromOperationsPerShare = "FUNDS_FROM_OPERATIONS_PER_SHARE",
  GoodwillAndOtherIntangibleAssetsToTotalAssets = "GOODWILL_AND_OTHER_INTANGIBLE_ASSETS_TO_TOTAL_ASSETS",
  GoodwillToTotalAssets = "GOODWILL_TO_TOTAL_ASSETS",
  GrossMargin = "GROSS_MARGIN",
  InterestCoverage = "INTEREST_COVERAGE",
  LiquidityCoverageRatio = "LIQUIDITY_COVERAGE_RATIO",
  MarketCapitalization = "MARKET_CAPITALIZATION",
  NetDebtWithoutLeaseToEbitda = "NET_DEBT_WITHOUT_LEASE_TO_EBITDA",
  NetDebtWithoutLeaseToFundsFromOperations = "NET_DEBT_WITHOUT_LEASE_TO_FUNDS_FROM_OPERATIONS",
  NetDebtWithoutLeaseToNormalizedEbitda = "NET_DEBT_WITHOUT_LEASE_TO_NORMALIZED_EBITDA",
  NetDebtWithoutLeaseToOperatingCashFlow = "NET_DEBT_WITHOUT_LEASE_TO_OPERATING_CASH_FLOW",
  NetDebtWithLeaseToEbitda = "NET_DEBT_WITH_LEASE_TO_EBITDA",
  NetDebtWithLeaseToNormalizedEbitda = "NET_DEBT_WITH_LEASE_TO_NORMALIZED_EBITDA",
  NetIncome_5YearGrowth = "NET_INCOME_5_YEAR_GROWTH",
  NetIncomeNciToNetIncome = "NET_INCOME_NCI_TO_NET_INCOME",
  NetIncomeToNetIncomeIncludingNci = "NET_INCOME_TO_NET_INCOME_INCLUDING_NCI",
  NetInterestMargin = "NET_INTEREST_MARGIN",
  NetInterestSpread = "NET_INTEREST_SPREAD",
  NetMargin = "NET_MARGIN",
  NonPerformingAssetsLoans = "NON_PERFORMING_ASSETS_LOANS",
  NormalizedDilutedEps = "NORMALIZED_DILUTED_EPS",
  NormalizedDilutedEps_5YearGrowth = "NORMALIZED_DILUTED_EPS_5_YEAR_GROWTH",
  NormalizedEbitdaMargin = "NORMALIZED_EBITDA_MARGIN",
  NormalizedNetMargin = "NORMALIZED_NET_MARGIN",
  NormalizedPegRatio = "NORMALIZED_PEG_RATIO",
  NormalizedPriceToEps = "NORMALIZED_PRICE_TO_EPS",
  NormalizedRoic = "NORMALIZED_ROIC",
  OperatingIncome_5YearGrowth = "OPERATING_INCOME_5_YEAR_GROWTH",
  PayoutRatio = "PAYOUT_RATIO",
  PegRatio = "PEG_RATIO",
  PriceToAdjustedFundsFromOperationsPerShare = "PRICE_TO_ADJUSTED_FUNDS_FROM_OPERATIONS_PER_SHARE",
  PriceToBook = "PRICE_TO_BOOK",
  PriceToCashFlow = "PRICE_TO_CASH_FLOW",
  PriceToEbitda = "PRICE_TO_EBITDA",
  PriceToEps = "PRICE_TO_EPS",
  PriceToFreeCashFlow = "PRICE_TO_FREE_CASH_FLOW",
  PriceToFundsFromOperationsPerShare = "PRICE_TO_FUNDS_FROM_OPERATIONS_PER_SHARE",
  PriceToSales = "PRICE_TO_SALES",
  PropertyPlantAndEquipmentToTotalAssets = "PROPERTY_PLANT_AND_EQUIPMENT_TO_TOTAL_ASSETS",
  QuickRatio = "QUICK_RATIO",
  ResearchAndDevelopmentToRevenue = "RESEARCH_AND_DEVELOPMENT_TO_REVENUE",
  RetainedEarnings = "RETAINED_EARNINGS",
  Revenue_5YearGrowth = "REVENUE_5_YEAR_GROWTH",
  RiskWeightedAsset = "RISK_WEIGHTED_ASSET",
  Roa = "ROA",
  Roe = "ROE",
  Roic = "ROIC",
  StockBasedCompensationToEnterpriseValue = "STOCK_BASED_COMPENSATION_TO_ENTERPRISE_VALUE",
  StockBasedCompensationToFundsFromOperation = "STOCK_BASED_COMPENSATION_TO_FUNDS_FROM_OPERATION",
  StockBasedCompensationToOperatingCashFlow = "STOCK_BASED_COMPENSATION_TO_OPERATING_CASH_FLOW",
  TotalCapital = "TOTAL_CAPITAL",
  TotalCapitalRatio = "TOTAL_CAPITAL_RATIO",
  TotalDebtToEquity = "TOTAL_DEBT_TO_EQUITY",
  TotalDebtToNetAssetValue = "TOTAL_DEBT_TO_NET_ASSET_VALUE",
  TotalYield = "TOTAL_YIELD",
  ZeroValue = "ZERO_VALUE",
}

export enum IValuationIndicatorCategory {
  Earnings = "EARNINGS",
  Growth = "GROWTH",
  Leverage = "LEVERAGE",
  MarketCapitalization = "MARKET_CAPITALIZATION",
  Profitability = "PROFITABILITY",
  Valuation = "VALUATION",
}

export type IValuationIndicatorValue = {
  __typename: "ValuationIndicatorValue";
  asOfDate: Scalars["Date"]["output"];
  category: IValuationIndicatorCategory;
  formattedValue: Scalars["String"]["output"];
  indicator: IValuationIndicator;
  value: Scalars["Float"]["output"];
  verbiage: IValuationIndicatorVerbiage;
  viewerCanSeeHistory: Scalars["Boolean"]["output"];
};

export type IValuationIndicatorValueVerbiageArgs = {
  language: IValuationIndicatorVerbiageLanguage;
};

export type IValuationIndicatorValueHistory = {
  __typename: "ValuationIndicatorValueHistory";
  nodes: Array<IValuationIndicatorValue>;
  tickerSymbol: Scalars["String"]["output"];
};

export type IValuationIndicatorVerbiage = {
  __typename: "ValuationIndicatorVerbiage";
  abbreviation: Scalars["String"]["output"];
  description: Scalars["String"]["output"];
  formula: Scalars["String"]["output"];
};

export enum IValuationIndicatorVerbiageLanguage {
  EnUs = "EN_US",
  PtBr = "PT_BR",
}

export enum IValuationPeriod {
  MonthEnd_1Ytd = "MONTH_END_1YTD",
  YearEnd_10Fiscalyear = "YEAR_END_10FISCALYEAR",
}

export type IVideoChapter = {
  __typename: "VideoChapter";
  formattedStartTime: Scalars["String"]["output"];
  startTime: Scalars["Int"]["output"];
  title: Scalars["String"]["output"];
};

export type IVideoPlaybackStateDetails = {
  __typename: "VideoPlaybackStateDetails";
  lastPlayedAt: Maybe<Scalars["DateTime"]["output"]>;
  playedSoFar: Scalars["Int"]["output"];
  state: Maybe<IPlaybackPlayerState>;
  watchCount: Scalars["Int"]["output"];
};

export type IViewerRating = {
  __typename: "ViewerRating";
  _id: Scalars["ObjectID"]["output"];
  ratedAt: Scalars["DateTime"]["output"];
  value: Scalars["Int"]["output"];
};

export type IYearlyBrazilianBankFinancialStatementRow = IBrazilianBankFinancialStatementRow & {
  __typename: "YearlyBrazilianBankFinancialStatementRow";
  bisRatio: Scalars["BigInt"]["output"];
  cashFlowForDividends: Scalars["BigInt"]["output"];
  cashFlowForDividendsToNetIncomeRatio: Scalars["Float"]["output"];
  endOfPeriod: Scalars["Date"]["output"];
  financialIntermediationRevenue: Scalars["BigInt"]["output"];
  fiscalYear: Scalars["String"]["output"];
  humanReadableQuarter: Scalars["String"]["output"];
  incomeTaxes: Scalars["BigInt"]["output"];
  isTrailingTwelveMonths: Scalars["Boolean"]["output"];
  netEquity: Scalars["BigInt"]["output"];
  netIncome: Scalars["BigInt"]["output"];
  netIncomeFromDiscontinuedOperations: Scalars["BigInt"]["output"];
  netMargin: Scalars["Float"]["output"];
  payout: Scalars["BigInt"]["output"];
  payoutToNetIncomeRatio: Scalars["BigInt"]["output"];
  provisionForCreditLosses: Scalars["BigInt"]["output"];
  provisionForCreditLossesToNetIncomeRatio: Scalars["BigInt"]["output"];
  returnOnEquity: Scalars["Float"]["output"];
  startOfPeriod: Scalars["Date"]["output"];
  totalAssets: Scalars["BigInt"]["output"];
};

export type IYearlyBrazilianCompanyFinancialStatementRow = IBrazilianCompanyFinancialStatementRow & {
  __typename: "YearlyBrazilianCompanyFinancialStatementRow";
  capex: Scalars["BigInt"]["output"];
  capexToCashFlowFromOperationsRatio: Scalars["Float"]["output"];
  cash: Scalars["BigInt"]["output"];
  cashFlowForDividends: Scalars["BigInt"]["output"];
  cashFlowForDividendsToNetIncomeRatio: Scalars["Float"]["output"];
  cashFlowFromFinancing: Scalars["BigInt"]["output"];
  cashFlowFromOperations: Scalars["BigInt"]["output"];
  debt: Scalars["BigInt"]["output"];
  debtToNetEquityRatio: Scalars["Float"]["output"];
  depreciationAndAmortization: Scalars["BigInt"]["output"];
  ebit: Scalars["BigInt"]["output"];
  ebitToNetRevenueRatio: Scalars["Float"]["output"];
  ebitda: Scalars["BigInt"]["output"];
  endOfPeriod: Scalars["Date"]["output"];
  financialResult: Scalars["BigInt"]["output"];
  fiscalYear: Scalars["String"]["output"];
  freeCashFlow: Scalars["BigInt"]["output"];
  humanReadableQuarter: Scalars["String"]["output"];
  incomeTaxes: Scalars["BigInt"]["output"];
  incomeTaxesToTaxableProfitRatio: Scalars["BigInt"]["output"];
  isTrailingTwelveMonths: Scalars["Boolean"]["output"];
  netDebt: Scalars["BigInt"]["output"];
  netDebtToEbitdaRatio: Scalars["Float"]["output"];
  netEquity: Scalars["BigInt"]["output"];
  netIncome: Scalars["BigInt"]["output"];
  netIncomeFromDiscontinuedOperations: Scalars["BigInt"]["output"];
  netMargin: Scalars["Float"]["output"];
  netRevenue: Scalars["BigInt"]["output"];
  payout: Scalars["BigInt"]["output"];
  payoutToNetIncomeRatio: Scalars["BigInt"]["output"];
  returnOnEquity: Scalars["Float"]["output"];
  startOfPeriod: Scalars["Date"]["output"];
};

export type IYearlyBrazilianCompanyFinancials =
  | IYearlyBrazilianBankFinancialStatementRow
  | IYearlyBrazilianCompanyFinancialStatementRow
  | IYearlyBrazilianInsurerFinancialStatementRow;

export type IYearlyBrazilianInsurerFinancialStatementRow = IBrazilianInsurerFinancialStatementRow & {
  __typename: "YearlyBrazilianInsurerFinancialStatementRow";
  capex: Scalars["BigInt"]["output"];
  capexToCashFlowFromOperationsRatio: Scalars["Float"]["output"];
  cash: Scalars["BigInt"]["output"];
  cashFlowForDividends: Scalars["BigInt"]["output"];
  cashFlowForDividendsToNetIncomeRatio: Scalars["Float"]["output"];
  cashFlowFromFinancing: Scalars["BigInt"]["output"];
  cashFlowFromOperations: Scalars["BigInt"]["output"];
  debt: Scalars["BigInt"]["output"];
  debtToNetEquityRatio: Scalars["Float"]["output"];
  depreciationAndAmortization: Scalars["BigInt"]["output"];
  ebit: Scalars["BigInt"]["output"];
  ebitToNetRevenueRatio: Scalars["Float"]["output"];
  ebitda: Scalars["BigInt"]["output"];
  endOfPeriod: Scalars["Date"]["output"];
  financialResult: Scalars["BigInt"]["output"];
  fiscalYear: Scalars["String"]["output"];
  freeCashFlow: Scalars["BigInt"]["output"];
  humanReadableQuarter: Scalars["String"]["output"];
  incomeTaxes: Scalars["BigInt"]["output"];
  incomeTaxesToTaxableProfitRatio: Scalars["BigInt"]["output"];
  isTrailingTwelveMonths: Scalars["Boolean"]["output"];
  netDebt: Scalars["BigInt"]["output"];
  netDebtToEbitdaRatio: Scalars["Float"]["output"];
  netEquity: Scalars["BigInt"]["output"];
  netIncome: Scalars["BigInt"]["output"];
  netIncomeFromDiscontinuedOperations: Scalars["BigInt"]["output"];
  netMargin: Scalars["Float"]["output"];
  netRevenue: Scalars["BigInt"]["output"];
  payout: Scalars["BigInt"]["output"];
  payoutToNetIncomeRatio: Scalars["BigInt"]["output"];
  returnOnEquity: Scalars["Float"]["output"];
  startOfPeriod: Scalars["Date"]["output"];
};

export type IYouTubeLiveStream = {
  __typename: "YouTubeLiveStream";
  channelId: Scalars["ID"]["output"];
  channelTitle: Scalars["String"]["output"];
  description: Scalars["String"]["output"];
  duration: IYouTubeVideoDuration;
  durationInSeconds: Scalars["Int"]["output"];
  publishedAt: Scalars["DateTime"]["output"];
  thumbnail: IYouTubeVideoThumbnail;
  title: Scalars["String"]["output"];
  url: Scalars["String"]["output"];
  videoId: Scalars["ID"]["output"];
};

export type IYouTubePlaylistItem = {
  __typename: "YouTubePlaylistItem";
  id: Scalars["ID"]["output"];
  position: Scalars["Int"]["output"];
  url: Scalars["String"]["output"];
  video: IYouTubeVideoV2;
};

export type IYouTubeVideo = {
  __typename: "YouTubeVideo";
  author: IYouTubeVideoAuthor;
  id: Scalars["ID"]["output"];
  position: Scalars["Int"]["output"];
  thumbnails: IYouTubeVideoThumbnails;
  title: Scalars["String"]["output"];
  url: Scalars["URL"]["output"];
};

export type IYouTubeVideoAuthor = {
  __typename: "YouTubeVideoAuthor";
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  url: Scalars["URL"]["output"];
};

export type IYouTubeVideoChapter = {
  __typename: "YouTubeVideoChapter";
  durationInSeconds: Scalars["Int"]["output"];
  end: Scalars["Int"]["output"];
  slug: Scalars["String"]["output"];
  start: Scalars["Int"]["output"];
  title: Scalars["String"]["output"];
  url: Scalars["String"]["output"];
};

export type IYouTubeVideoDuration = {
  __typename: "YouTubeVideoDuration";
  days: Scalars["Int"]["output"];
  hours: Scalars["Int"]["output"];
  minutes: Scalars["Int"]["output"];
  months: Scalars["Int"]["output"];
  seconds: Scalars["Int"]["output"];
  weeks: Scalars["Int"]["output"];
  years: Scalars["Int"]["output"];
};

export type IYouTubeVideoStatistics = {
  __typename: "YouTubeVideoStatistics";
  favoriteCount: Scalars["Int"]["output"];
  likeCount: Scalars["Int"]["output"];
  viewCount: Scalars["Int"]["output"];
};

export type IYouTubeVideoThumbnail = {
  __typename: "YouTubeVideoThumbnail";
  height: Scalars["Int"]["output"];
  url: Scalars["URL"]["output"];
  width: Scalars["Int"]["output"];
};

export type IYouTubeVideoThumbnailDetails = {
  __typename: "YouTubeVideoThumbnailDetails";
  default: IYouTubeVideoThumbnail;
  high: IYouTubeVideoThumbnail;
  maxres: IYouTubeVideoThumbnail;
  medium: IYouTubeVideoThumbnail;
  standard: IYouTubeVideoThumbnail;
};

export type IYouTubeVideoThumbnails = {
  __typename: "YouTubeVideoThumbnails";
  /** Default 120x90 video thumbnail URL. Guaranteed to exist on all videos. */
  default: Scalars["URL"]["output"];
  /** Default 480x360 video thumbnail URL. Guaranteed to exist on all videos. */
  hqdefault: Scalars["URL"]["output"];
  /** Default 1920x1080 video thumbnail URL. Availability not guaranteed for some videos. */
  maxresdefault: Scalars["URL"]["output"];
  /** Default 320x180 video thumbnail URL. Guaranteed to exist on all videos. */
  mqdefault: Scalars["URL"]["output"];
  /** Default 640x480 video thumbnail URL. Availability not guaranteed for some videos. */
  sddefault: Scalars["URL"]["output"];
};

export type IYouTubeVideoV2 = {
  __typename: "YouTubeVideoV2";
  chapters: Maybe<Array<IYouTubeVideoChapter>>;
  description: Scalars["String"]["output"];
  duration: IYouTubeVideoDuration;
  durationInSeconds: Scalars["Int"]["output"];
  id: Scalars["ID"]["output"];
  publishedAt: Scalars["DateTime"]["output"];
  slug: Scalars["String"]["output"];
  statistics: Maybe<IYouTubeVideoStatistics>;
  thumbnails: IYouTubeVideoThumbnailDetails;
  title: Scalars["String"]["output"];
};
