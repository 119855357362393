import type { CustomEventMap } from "lib/types/customEvents";

export default function dispatchCustomEvent<K extends keyof CustomEventMap>(
  name: K,
  detail?: CustomEventMap[K]["detail"],
): void {
  if (typeof document !== "undefined") {
    document.dispatchEvent(
      new CustomEvent(name, {
        bubbles: true,
        detail,
      }),
    );
  }
}
